import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'; // Import useHistory for navigation
import { displayRazorpayRecruiterGCoins } from '@/utils/razorpay';
import { showToastMessage } from '@/components/ToastComponent';
import { logFirebaseEvent } from '@/services/firebase';

import styles from './styles.module.scss';

const PricingCard = ({ activeTab, index, setActiveTab, data, landingPageInfo }) => {
    const userInfo = useSelector((state) => state.user);
    const history = useHistory(); // Initialize useHistory for navigation

    const processPayment = async (gCoins) => {
        const selectedPlanPrice = gCoins;

        await displayRazorpayRecruiterGCoins({
            name: userInfo.name,
            email: userInfo.email_id,
            phone_number: userInfo.mobile_number,
            amount: parseInt(selectedPlanPrice, 10),
            currency: "INR",
            paymentSuccess: () => {
                showToastMessage("Payment was successful.", "info");
                logFirebaseEvent(`W_PaymentSuccess_ActivateAutoRecruiterPg`, {
                    name: userInfo.name,
                    email: userInfo.email_id,
                    phone_number: userInfo.mobile_number,
                    userId: userInfo.user_id,
                });

               
                    history.push("/hirer/create-interview/2");
                
            },
            paymentFail: () => {
                showToastMessage("Payment failed.", "error");
            }
        }).catch((err) => {
            if (err?.response?.data?.message) {
                showToastMessage("error", err?.response?.data?.message);
            }
        });
    };

    return (
        <div
            className={`${styles.PricingCard} ${activeTab && styles.activeTab}`}
            onClick={() => {
                processPayment(data.price);
                setActiveTab(index);
            }}
        >
            <div className={styles.header}>{data.title}</div>
            <div className={styles.PriceContainer}>
                <div>${data.price}</div>
                <p>{data.subDesc}</p>
            </div>
            <data.img color={activeTab ? "white" : "#B499DE"} />
            <div className={styles.divider} />
            <div className={styles.listItems}>
                <ul>
                    {data.arr.map((a, i) => (
                        <li key={i}>{a}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PricingCard;
