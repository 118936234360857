import React from "react";
import starSvg from "../assets/star.svg";
import "../styles/RotatingStar.css";

/**
 * RotatingStar component
 * @param {string | number} size   The width/height in px (e.g., 24 or "40px")
 * @param {string} color          The background/fill color (e.g. "#f00", "blue")
 * @param {string} speed          The rotation duration (e.g. "1s", "2s")
 */
const RotatingStar = ({ size = 24, color = "#000", speed = "2s" }) => {
  return (
    <div
      className="rotating-star"
      style={{
        width: size,
        height: size,

        /* The star is used as a mask image. Then backgroundColor determines star color. */
        backgroundColor: color,
        maskImage: `url(${starSvg})`,
        maskSize: "contain",
        maskPosition: "center",
        maskRepeat: "no-repeat",

        /* For WebKit-based browsers */
        WebkitMaskImage: `url(${starSvg})`,
        WebkitMaskSize: "contain",
        WebkitMaskPosition: "center",
        WebkitMaskRepeat: "no-repeat",

        /* Animation speed */
        animationDuration: speed,
      }}
    />
  );
};

export default RotatingStar;