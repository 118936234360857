/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import ReactModal from "react-modal";
import { NavLink, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import PropTypes from "prop-types";
import { IoMdArrowDropdown } from "react-icons/io";
import premium from "@/assets/png/premium-badge.png";
import qrcode from "@/assets/png/qrcode.png";
import userImg from "@/assets/svg/userAvatar1.svg";
import profileIcon from "@/assets/svg/profile.svg";
import manageJobsIcon from "@/assets/svg/manage_jobs_icon.svg";
import manageOffersIcon from "@/assets/svg/userOffers.svg";
import logoutIcon from "@/assets/svg/logout.svg";
import menu from "@/assets/svg/menu.svg";
import banner from "@/assets/png/banner.png";
import playstore from "@/assets/svg/gplay.svg";
import NotLoggedInModal from "../NotLoggedInModal";
import DemoForm from "@/pages/AutoRecruiterLandingPage/components/Form/Form";
import apple from "@/assets/svg/app-store.svg";
import { logout as logoutAction } from "@/redux/actions/userActions";
import LoginModal from "@/components/LoginModal/LoginModal";
import { sendAppLinkApi } from "@/services/utilitiesService";
import PhoneNumberInput from "@/components/PhoneNumberInput/PhoneNumberInput";
import styles from "./Navbar.module.scss";


function NavbarEl({
  bannerIsOpen,
  hideEverything,
  additionalStyles,
  hideLogin,
  hideBookDemo,
  loginBtnColor,
  showLookingForJob
}) {
  const [dropdownStateOpen, setDropdownStateOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const [openNavMenu, setOpenNavMenu] = useState(false);
  const [numData, setNumData] = useState({ countryCode: "+91", number: "" });
  const dispatch = useDispatch();
  const history = useHistory();
  const navMenu = useRef(null);
  const navMenu2 = useRef(null);
  const navbar = useRef(null);
  const loggedInUser = useSelector((state) => state.user);
  const notificationData = useSelector((state) => state.notificationData);
  const handleClose = () => {
    setNumData({ countryCode: "+91", number: "" });
    setLoading(false);
    setDoneLoading(false);
    setShow(false);
  };
  const openDropdown = () => {
    setDropdownStateOpen(true);
  };
  const closeDropdown = () => {
    setDropdownStateOpen(false);
  };
  const toggleDropdown = () => {
    setDropdownStateOpen(!dropdownStateOpen);
  };
  // const showMenu = () => {
  //   setOpenNavMenu(true);
  // };
  const toggleMenu = () => {
    setOpenNavMenu(!openNavMenu);
  };
  const hideMenu = () => {
    setOpenNavMenu(false);
  };
  const handleShow = () => setShow(true);
  const logout = () => {
    hideMenu();
    dispatch(logoutAction());
    window.localStorage.removeItem("userFeedbackReminder");
    window.localStorage.removeItem("token");
    window.sessionStorage.clear();
    history.push("/");
  };
  const handleChange = (value, data) => {
    // console.log(JSON.stringify(event))
    const countryCode = "+".concat(data.dialCode);
    const number = value.substring(data.dialCode.length);
    setNumData({ number, countryCode });
  };
  const sendAppLink = async () => {
    if (loading) return;
    setLoading(true);
    const res = await sendAppLinkApi(numData.number, numData.countryCode);
    console.log(res.data);
    setLoading(false);
    setDoneLoading(true);
  };

  const [expanded, setExpanded] = useState(false);

  const checkClickOutside = (e) => {
    if (navbar.current && !navbar.current.contains(e.target)) {
      closeDropdown();
    }
    // console.log(
    //   "!navMenu.current.contains(e.target)",
    //   !navMenu.current.contains(e.target),
    //   openNavMenu
    // );
    if (
      navMenu.current &&
      !navMenu.current.contains(e.target) &&
      navMenu2.current &&
      !navMenu2.current.contains(e.target)
    )
      hideMenu();
  };
  useEffect(() => {
    document.addEventListener("click", checkClickOutside);
    return () => {
      document.removeEventListener("click", checkClickOutside);
    };
  }, []);
  let userKeyword;
  if (loggedInUser.user_id) {
    userKeyword = `${
      loggedInUser.name
        ? loggedInUser.name.replaceAll(" ", "-").toLowerCase()
        : ""
    }-${loggedInUser.user_id}`;
  }
  return (
    <div
      className={`${styles.navbarContainer} ${
        bannerIsOpen ? styles.navbarContainerBanner : ""
      }`}
      ref={navbar}
    >
      <div
        className={styles.navbarLg}
        style={additionalStyles ? { ...additionalStyles } : null}
      >
        <NavLink to="/" className={styles.navbarBrand}>
          <NavBarLogoWithText/>
        </NavLink>
        {!loggedInUser.token ? (
          <>
            {!hideEverything ? (
              <div
                className={`${styles.navbarLinks} ${styles.navbarLinksNoLogin}`}
              >
                {/* <NavLink to="/hire" className={styles.navLink}>
                  Hire Talent
                </NavLink>
                <NavLink to="/jobs" className={styles.navLink}>
                  Find Work
                </NavLink> */}
              </div>
            ) : (
              <div
                className={`${styles.navbarLinks} ${styles.navbarLinksNoLogin}`}
              />
            )}
            <div className={styles.navbarButtons}>
            {showLookingForJob && (
              <div
                className={styles.navbarJobButton}
                onClick={() => (window.location.href = '/job-seeker')} // Navigate to /hirer
              >
                <span>Looking For a Job?</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#22A76F"
                    strokeWidth="2"
                    d="M14 4h6v6M10 14l10-10M20 20H4V4"
                  />
                </svg>
              </div>
            )}
              {!additionalStyles?.hideGetApp && hideBookDemo == "true" &&(
                <div className={styles.navbarGetAppButton} onClick={handleShow}>
                  Book Demo
                </div>
              )}
              {!hideLogin && (
                <div
                  className={styles.navbarLoginButton}
                  onClick={() => {
                    setShowLoginModal(true);
                  }}
                  style={{
                    backgroundColor: loginBtnColor , // fallback color if not passed
                    color: '#FFFFFF'                             // ensure text is visible
                  }}
                >
                  Login/Signup
                </div>
              )}
              
              
            </div>
          </>
        ) : (
          <>
            <div className={styles.navbarLinks}>
              <NavLink
                to="/feedback"
                activeClassName={styles.activeNavLink}
                className={styles.navLink}
                // onClick={closeDropdown}
              >
                <NavIcon type="feedback" />
                Feedback
              </NavLink>
              <NavLink
                to="/hire"
                activeClassName={styles.activeNavLink}
                className={styles.navLink}
              >
                <NavIcon type="hire" />
                Hire
              </NavLink>{" "}
              <NavLink
                to="/jobs"
                activeClassName={styles.activeNavLink}
                className={styles.navLink}
                // onClick={closeDropdown}
              >
                <NavIcon type="jobs" />
                Jobs
              </NavLink>{" "}
              <NavLink
                to="/premium"
                activeClassName={styles.activeNavLink}
                className={styles.navLink}
                // onClick={closeDropdown}
              >
                <img className={styles.icons_image} src={premium} alt="" />
                {loggedInUser.is_premium ? "Premium" : "Try Premium"}
              </NavLink>
              <NavLink
                to="/chat"
                activeClassName={styles.activeNavLink}
                className={styles.navLink}
                // onClick={closeDropdown}
              >
                <NavIcon
                  type="message"
                  showCounter={notificationData.messageCount > 0}
                  counterVal={notificationData.messageCount}
                />
                Message
              </NavLink>
              <NavLink
                to="/notifications"
                activeClassName={styles.activeNavLink}
                className={styles.navLink}
                // onClick={closeDropdown}
              >
                <NavIcon
                  type="notification"
                  showCounter={notificationData.notificationCount > 0}
                  counterVal={notificationData.notificationCount}
                />
                Notifications
              </NavLink>
            </div>{" "}
            <div className={styles.navbarButtons}>
              <div
                className={styles.navProfileButton}
                ref={navMenu}
                onClick={toggleMenu}
              >
                <div className={styles.profileIcon}>
                  <img
                    src={
                      loggedInUser.image_id === "null" || !loggedInUser.image_id
                        ? userImg
                        : loggedInUser.image_id
                    }
                    alt=""
                  />
                </div>
                <IoMdArrowDropdown />
                <div
                  className={`${styles.navbarMenuContainer} ${
                    openNavMenu ? styles.navbarMenuContainerOpen : ""
                  }`}
                >
                  {" "}
                  <div className={styles.navbarMenu}>
                    <div className={styles.navbarMenuNotch} />

                    <Link
                      to={`/${userKeyword}`}
                      onClick={hideMenu}
                      className={styles.navbarMenuItem}
                    >
                      <div className={styles.navbarMenuItemIcon}>
                        <img
                          src={profileIcon}
                          alt=""
                          className={styles.dropImage}
                        />{" "}
                      </div>
                      <div className={styles.navbarMenuItemIcon}>
                        My Profile
                      </div>
                    </Link>
                    <Link
                      onClick={hideMenu}
                      to="/my_job_posts"
                      className={styles.navbarMenuItem}
                    >
                      <div className={styles.navbarMenuItemIcon}>
                        <img
                          src={manageJobsIcon}
                          alt=""
                          className={styles.dropImage}
                        />{" "}
                      </div>
                      <div className={styles.navbarMenuItemIcon}>
                        Manage Jobs
                      </div>
                    </Link>
                    {/* <Link
                      onClick={hideMenu}
                      to="/my_job_offers"
                      className={styles.navbarMenuItem}
                    >
                      <div className={styles.navbarMenuItemIcon}>
                        <img
                          src={manageOffersIcon}
                          alt=""
                          className={styles.dropImage}
                        />{" "}
                      </div>
                      <div className={styles.navbarMenuItemIcon}>
                        Manage Offers
                      </div>
                    </Link> */}
                    <div className={styles.navbarMenuItem} onClick={logout}>
                      <div className={styles.navbarMenuItemIcon}>
                        <img
                          src={logoutIcon}
                          alt=""
                          className={styles.dropImage}
                        />{" "}
                      </div>
                      <div className={styles.navbarMenuItemIcon}>Logout</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className={`${styles.navbarSmContainer} ${
          dropdownStateOpen ? styles.navbarSmContainerOpen : ""
        }`}
      >
        <div className={styles.navbarSm}>
          {loggedInUser.token&&<div className={styles.navbarDropDownBtn} onClick={toggleDropdown}>
            <HiOutlineMenuAlt3 />
          </div>}
          <NavLink to="/" className={styles.navbarBrand}>
          <NavBarSmLogoWithText/>
          </NavLink>
          <div className={styles.navbarSmButtons}>
            {loggedInUser.token ? (
              <div
                className={styles.navProfileButton}
                ref={navMenu2}
                onClick={toggleMenu}
              >
                <div className={styles.profileIcon}>
                  <img
                    src={
                      loggedInUser.image_id === "null" || !loggedInUser.image_id
                        ? userImg
                        : loggedInUser.image_id
                    }
                    alt=""
                  />
                </div>
                <IoMdArrowDropdown />
                <div
                  className={`${styles.navbarMenuContainer} ${
                    openNavMenu ? styles.navbarMenuContainerOpen : ""
                  }`}
                >
                  {" "}
                  <div className={styles.navbarMenu}>
                    <div className={styles.navbarMenuNotch} />

                    <Link
                      to={`/${userKeyword}`}
                      onClick={hideMenu}
                      className={styles.navbarMenuItem}
                    >
                      <div className={styles.navbarMenuItemIcon}>
                        <img
                          src={profileIcon}
                          alt=""
                          className={styles.dropImage}
                        />{" "}
                      </div>
                      <div className={styles.navbarMenuItemIcon}>
                        My Profile
                      </div>
                    </Link>
                    <Link
                      onClick={hideMenu}
                      to="/my_job_posts"
                      className={styles.navbarMenuItem}
                    >
                      <div className={styles.navbarMenuItemIcon}>
                        <img
                          src={manageJobsIcon}
                          alt=""
                          className={styles.dropImage}
                        />{" "}
                      </div>
                      <div className={styles.navbarMenuItemIcon}>
                        Manage Jobs
                      </div>
                    </Link>
                    {/* <Link
                      onClick={hideMenu}
                      to="/my_job_offers"
                      className={styles.navbarMenuItem}
                    >
                      <div className={styles.navbarMenuItemIcon}>
                        <img
                          src={manageOffersIcon}
                          alt=""
                          className={styles.dropImage}
                        />{" "}
                      </div>
                      <div className={styles.navbarMenuItemIcon}>
                        Manage Offers
                      </div>
                    </Link> */}
                    <div className={styles.navbarMenuItem} onClick={logout}>
                      <div className={styles.navbarMenuItemIcon}>
                        <img
                          src={logoutIcon}
                          alt=""
                          className={styles.dropImage}
                        />{" "}
                      </div>
                      <div className={styles.navbarMenuItemIcon}>Logout</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={styles.navbarLoginButton}
                onClick={() => {
                  setShowLoginModal(true);
                }}
              >
                Login/SignUp
              </div>
            )}{" "}
          </div>
        </div>
        <div
          className={`${styles.navbarDropdown} ${
            dropdownStateOpen ? styles.navbarDropdownOpen : ""
          }`}
        >
          <div className={styles.navDropdownItems}>
            {loggedInUser.token ? (
              <div className={styles.navbarLinks}>
                <NavLink
                  to="/feedback"
                  activeClassName={styles.activeNavLink}
                  className={styles.navLink}
                  // onClick={closeDropdown}
                >
                  <NavIcon type="feedback" />
                  Feedback
                </NavLink>
                <NavLink
                  to="/hire"
                  activeClassName={styles.activeNavLink}
                  className={styles.navLink}
                >
                  <NavIcon type="hire" />
                  Hire
                </NavLink>{" "}
                <NavLink
                  to="/jobs"
                  activeClassName={styles.activeNavLink}
                  className={styles.navLink}
                  // onClick={closeDropdown}
                >
                  <NavIcon type="jobs" />
                  Jobs
                </NavLink>{" "}
                <NavLink
                  to="/premium"
                  activeClassName={styles.activeNavLink}
                  className={styles.navLink}
                  // onClick={closeDropdown}
                >
                  <img className={styles.icons_image} src={premium} alt="" />
                  {loggedInUser.is_premium ? "Premium" : "Try Premium"}
                </NavLink>
                <NavLink
                  to="/chat"
                  activeClassName={styles.activeNavLink}
                  className={styles.navLink}
                  // onClick={closeDropdown}
                >
                  <NavIcon type="message" />
                  Message
                </NavLink>
                <NavLink
                  to="/notifications"
                  activeClassName={styles.activeNavLink}
                  className={styles.navLink}
                  // onClick={closeDropdown}
                >
                  <NavIcon type="notification" />
                  Notifications
                </NavLink>
              </div>
            ) : (
              <div className={styles.navbarLinks}>
                {/* <NavLink to="/hire" className={styles.navLink}>
                  Hire Talent
                </NavLink>
                <NavLink to="/jobs" className={styles.navLink}>
                  Find Work
                </NavLink>{" "} */}
                <div className={styles.navbarGetAppButton} onClick={handleShow}>
                  Get App
                </div>
              </div>
            )}{" "}
          </div>
        </div>
      </div>
      <NotLoggedInModal
        isOpen={showLoginModal}
        hideModal={() => {
          setShowLoginModal(false);
        }}
      />{" "}
      
      <ReactModal
        className={styles.modalDialogue}
        overlayClassName="postJob__completeBanner-overlay-2"
        isOpen={show}
        onRequestClose={handleClose}
      >
        <div onClick={handleClose}>
          <CloseIcon />
        </div>
        <div className={styles.title}>Ready to hire 10X faster?</div>
        <div className={styles.subtitle}>We’d love to connect</div>
        <DemoForm handleClose={handleClose} />
      </ReactModal>
    </div>

    // <div>
    //   <Navbar
    //     className={`${styles.navbar} ${
    //       bannerIsOpen ? styles.navbar_banner : ""
    //     }`}
    //     collapseOnSelect
    //     expand="lg"
    //     bg="light"
    //     fixed="top"
    //     variant="light"
    //     ref={navbar}
    //     expanded={expanded}
    //   >
    //     <NavLink to="/">
    //       <Navbar.Brand>
    //         <span className={styles.goodspace}>goodspace</span>
    //       </Navbar.Brand>
    //     </NavLink>
    //     <Navbar.Toggle
    //       className={`${styles.navbar_toggler}`}
    //       aria-controls="responsive-navbar-nav"
    //       onClick={() => {
    //         setExpanded(!expanded);
    //       }}
    //     >
    //       <img src={menu} alt="" />
    //     </Navbar.Toggle>

    //     <Navbar.Collapse id="responsive-navbar-nav">
    //       {!loggedInUser.token && (
    //         // <div className={styles.icons_right_block}>
    //         <NavLink to="/hire">
    //           <Navbar.Brand>
    //             <span className={styles.hire_talent}>Hire Talent</span>
    //           </Navbar.Brand>
    //         </NavLink>
    //         // </div>
    //       )}
    //       <Nav className={styles.options}>
    //         {loggedInUser.token && (
    //           <div
    //             className={styles.icons_right_block}
    //             style={{ paddingTop: "10px" }}
    //           >
    //             <div className={styles.icons_right}>
    //               <NavLink
    //                 to="/feedback"
    //                 activeClassName={styles.activeNavLink}
    //                 className="nav-link"
    //                 onClick={closeDropdown}
    //               >
    //                 <NavIcon type="feedback" />
    //                 Feedback
    //               </NavLink>
    //             </div>
    //             <div className={styles.icons_right}>
    //               <NavLink
    //                 to="/hire"
    //                 activeClassName={styles.activeNavLink}
    //                 className="nav-link"
    //                 onClick={closeDropdown}
    //               >
    //                 <NavIcon type="hire" />
    //                 Hire
    //               </NavLink>
    //             </div>
    //             <div className={styles.icons_right}>
    //               <NavLink
    //                 to="/premium"
    //                 activeClassName={styles.activeNavLink}
    //                 className="nav-link"
    //                 onClick={closeDropdown}
    //               >
    //                 <img className={styles.icons_image} src={premium} alt="" />
    //                 {loggedInUser.is_premium ? "Premium" : "Try Premium"}
    //               </NavLink>
    //             </div>
    //             <div className={styles.icons_right}>
    //               <NavLink
    //                 to="/chat"
    //                 activeClassName={styles.activeNavLink}
    //                 className="nav-link"
    //                 onClick={closeDropdown}
    //               >
    //                 <NavIcon type="message" />
    //                 Message
    //               </NavLink>
    //             </div>
    //             {loggedInUser.token && (
    //               <div
    //                 className={styles.navProfileButton}
    //                 ref={navMenu}
    //                 onClick={toggleMenu}
    //               >
    //                 <div className={styles.profileIcon}>
    //                   <img
    //                     src={
    //                       loggedInUser.image_id === "null" ||
    //                       !loggedInUser.image_id
    //                         ? userImg
    //                         : loggedInUser.image_id
    //                     }
    //                     alt=""
    //                   />
    //                 </div>
    //                 <IoMdArrowDropdown />
    //                 <div
    //                   className={`${styles.navbarMenuContainer} ${
    //                     openNavMenu ? styles.navbarMenuContainerOpen : ""
    //                   }`}
    //                 >
    //                   {" "}
    //                   <div className={styles.navbarMenu}>
    //                     <div className={styles.navbarMenuNotch} />
    //                     <Link
    //                       to={`/${userKeyword}`}
    //                       onClick={hideMenu}
    //                       className={styles.navbarMenuItem}
    //                     >
    //                       <div className={styles.navbarMenuItemIcon}>
    //                         <img
    //                           src={profileIcon}
    //                           alt=""
    //                           className={styles.dropImage}
    //                         />{" "}
    //                       </div>
    //                       <div className={styles.navbarMenuItemIcon}>
    //                         My Profile
    //                       </div>
    //                     </Link>
    //                     <Link
    //                       onClick={hideMenu}
    //                       to="my_job_posts"
    //                       className={styles.navbarMenuItem}
    //                     >
    //                       <div className={styles.navbarMenuItemIcon}>
    //                         <img
    //                           src={manageJobsIcon}
    //                           alt=""
    //                           className={styles.dropImage}
    //                         />{" "}
    //                       </div>
    //                       <div className={styles.navbarMenuItemIcon}>
    //                         Manage Jobs
    //                       </div>
    //                     </Link>
    //                     <div className={styles.navbarMenuItem} onClick={logout}>
    //                       <div className={styles.navbarMenuItemIcon}>
    //                         <img
    //                           src={logoutIcon}
    //                           alt=""
    //                           className={styles.dropImage}
    //                         />{" "}
    //                       </div>
    //                       <div className={styles.navbarMenuItemIcon}>
    //                         Logout
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             )}
    //           </div>
    //         )}
    //       </Nav>

    //       {!loggedInUser.token && (
    //         <div
    //           className={styles.loginBtn}
    //           onClick={() => {
    //             setShowLoginModal(true);
    //           }}
    //         >
    //           Login
    //         </div>
    //       )}
    //       {!loggedInUser.token && (
    //         <button className={styles.getApp} onClick={handleShow}>
    //           Get App
    //         </button>
    //       )}
    //     </Navbar.Collapse>
    //   </Navbar>

    // </div>
  );
}

export default NavbarEl;

const NavIcon = ({ type, showCounter, counterVal }) => (
  <div
    className={`${styles.navIconContainer} ${
      type === "message" ? styles.navIconContainerBig : ""
    }`}
  >
    {showCounter && (
      <div className={styles.navIconCounterContainer}>{counterVal}</div>
    )}
    {type === "hire" && <HireIcon />}
    {type === "jobs" && <JobIcon />}
    {type === "feedback" && <FeedbackIcon />}
    {type === "message" && <MessageIcon />}
    {type === "notification" && <NotificationIcon />}
  </div>
);
NavIcon.propTypes = {
  type: PropTypes.string.isRequired,
  showCounter: PropTypes.bool,
  counterVal: PropTypes.number
};
NavIcon.defaultProps = {
  showCounter: false,
  counterVal: 0
};

const NavBarLogoWithText=()=>(
  <svg width="183" height="40" viewBox="0 0 183 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.353988 31.2926C0.283191 31.7173 0.212393 32.3545 0.566381 33.3456C1.69914 36.3896 5.30982 38.1594 6.0886 38.5134C9.0621 39.9292 11.7524 40 12.956 40C13.5224 40 15.7879 40 18.6198 39.0797C19.6818 38.7257 21.2393 38.2302 22.8676 37.0267C24.4252 35.8941 25.0624 34.8322 25.2748 34.549C25.2748 34.549 25.2748 34.549 25.2748 34.4782C25.4872 34.1243 26.1951 32.9916 25.9827 31.5758C25.9827 31.3634 25.6996 30.0183 24.5668 29.1688C24.5668 29.1688 22.6553 27.8946 20.5313 29.0272C20.4605 29.0272 20.3897 29.098 20.3897 29.098C20.2481 29.2396 20.1065 29.3104 20.1065 29.3104C19.8941 29.5228 19.1154 30.2307 19.0446 30.3015C19.0446 30.3015 18.549 30.7263 17.2746 31.5758C17.133 31.6466 16.9914 31.7881 16.9914 31.7881L16.9206 31.8589C16.5667 32.0713 15.8587 32.4253 15.0091 32.6376C14.3719 32.7792 13.0268 33.204 11.3276 32.7792C9.91167 32.4961 8.92051 31.7881 8.49572 31.505C7.85854 31.0802 7.78774 30.8678 6.37179 29.7352C5.30982 28.8857 5.09743 28.7441 4.74344 28.6733C3.46908 28.2485 2.26553 28.8857 2.12393 29.0272C1.98233 28.9565 0.637179 29.7352 0.353988 31.2926Z" fill="#297BCA" />
    <path d="M25.2748 12.8857C25.2748 9.48765 25.3456 6.01884 25.3456 2.62083C25.2748 2.33766 25.1332 1.9837 24.7792 1.55895C24.5668 1.20499 24.0004 0.497068 23.0092 0.143108C22.7968 0.0723165 22.0181 -0.140059 21.1685 0.143108C20.0357 0.497068 19.5402 1.48816 19.3986 1.70053C19.1862 2.05449 19.1154 2.40845 19.1154 2.62083C19.1154 2.76241 19.1154 2.904 19.1154 3.04558C17.2038 1.91291 15.0091 1.20499 12.602 1.20499C5.66381 1.27578 0 6.93914 0 13.9475C0 20.956 5.66381 26.5485 12.602 26.5485C19.5402 26.5485 25.204 20.8852 25.204 13.9475C25.2748 13.5936 25.204 13.2396 25.2748 12.8857ZM12.602 19.6109C9.41609 19.6109 6.93817 17.0624 6.93817 13.8768C6.93817 10.6911 9.48689 8.1426 12.6728 8.1426C15.8587 8.1426 18.4074 10.6911 18.4074 13.8768C18.4074 17.0624 15.7879 19.6109 12.602 19.6109Z" fill="#297BCA" />
    <path d="M44.4512 11.2965C45.4989 11.2965 46.4198 11.5077 47.214 11.9301C48.0082 12.3356 48.6335 12.8678 49.0897 13.5268V11.5246H52.6636V25.667C52.6636 26.968 52.4017 28.1254 51.8779 29.1392C51.354 30.1699 50.5683 30.9809 49.5206 31.5723C48.4729 32.1806 47.2056 32.4847 45.7186 32.4847C43.7246 32.4847 42.0855 32.0201 40.8013 31.0908C39.5339 30.1615 38.8158 28.8942 38.6468 27.289H42.17C42.3559 27.9311 42.753 28.438 43.3613 28.8097C43.9865 29.1983 44.7385 29.3927 45.6172 29.3927C46.648 29.3927 47.4844 29.0801 48.1265 28.4549C48.7686 27.8466 49.0897 26.9173 49.0897 25.667V23.4873C48.6335 24.1463 47.9998 24.6954 47.1887 25.1347C46.3945 25.574 45.482 25.7937 44.4512 25.7937C43.2684 25.7937 42.1869 25.4896 41.2068 24.8813C40.2268 24.273 39.4495 23.4197 38.8749 22.3214C38.3173 21.2063 38.0385 19.9306 38.0385 18.4944C38.0385 17.0751 38.3173 15.8163 38.8749 14.718C39.4495 13.6197 40.2183 12.7749 41.1815 12.1835C42.1616 11.5921 43.2515 11.2965 44.4512 11.2965ZM49.0897 18.5451C49.0897 17.6833 48.9207 16.9483 48.5828 16.3401C48.2448 15.7149 47.7886 15.2418 47.214 14.9208C46.6395 14.5828 46.0227 14.4139 45.3637 14.4139C44.7047 14.4139 44.0964 14.5744 43.5387 14.8954C42.9811 15.2165 42.5249 15.6896 42.17 16.3147C41.8321 16.923 41.6631 17.6496 41.6631 18.4944C41.6631 19.3392 41.8321 20.0827 42.17 20.7247C42.5249 21.3499 42.9811 21.8314 43.5387 22.1694C44.1133 22.5073 44.7216 22.6763 45.3637 22.6763C46.0227 22.6763 46.6395 22.5158 47.214 22.1947C47.7886 21.8568 48.2448 21.3837 48.5828 20.7754C48.9207 20.1502 49.0897 19.4068 49.0897 18.5451Z" fill="#297BCA" />
    <path d="M62.3647 25.7937C61.0129 25.7937 59.7962 25.498 58.7148 24.9066C57.6333 24.2983 56.78 23.4451 56.1547 22.3468C55.5464 21.2485 55.2423 19.9813 55.2423 18.5451C55.2423 17.1089 55.5549 15.8416 56.1801 14.7434C56.8222 13.6451 57.6925 12.8002 58.7908 12.2089C59.8892 11.6006 61.1143 11.2965 62.4661 11.2965C63.8179 11.2965 65.043 11.6006 66.1414 12.2089C67.2398 12.8002 68.1016 13.6451 68.7268 14.7434C69.3689 15.8416 69.69 17.1089 69.69 18.5451C69.69 19.9813 69.3605 21.2485 68.7014 22.3468C68.0593 23.4451 67.1806 24.2983 66.0654 24.9066C64.967 25.498 63.7335 25.7937 62.3647 25.7937ZM62.3647 22.7016C63.0068 22.7016 63.6067 22.5496 64.1644 22.2454C64.7389 21.9244 65.1951 21.4513 65.5331 20.8261C65.871 20.2009 66.04 19.4406 66.04 18.5451C66.04 17.2102 65.6852 16.188 64.9755 15.4783C64.2826 14.7518 63.4293 14.3885 62.4154 14.3885C61.4015 14.3885 60.5482 14.7518 59.8554 15.4783C59.1795 16.188 58.8415 17.2102 58.8415 18.5451C58.8415 19.8799 59.171 20.9106 59.83 21.6371C60.506 22.3468 61.3509 22.7016 62.3647 22.7016Z" fill="#297BCA" />
    <path d="M78.5283 25.7937C77.1765 25.7937 75.9598 25.498 74.8784 24.9066C73.7969 24.2983 72.9435 23.4451 72.3183 22.3468C71.71 21.2485 71.4058 19.9813 71.4058 18.5451C71.4058 17.1089 71.7184 15.8416 72.3437 14.7434C72.9858 13.6451 73.856 12.8002 74.9544 12.2089C76.0528 11.6006 77.2778 11.2965 78.6297 11.2965C79.9815 11.2965 81.2066 11.6006 82.305 12.2089C83.4033 12.8002 84.2651 13.6451 84.8904 14.7434C85.5325 15.8416 85.8535 17.1089 85.8535 18.5451C85.8535 19.9813 85.524 21.2485 84.865 22.3468C84.2229 23.4451 83.3442 24.2983 82.2289 24.9066C81.1306 25.498 79.897 25.7937 78.5283 25.7937ZM78.5283 22.7016C79.1704 22.7016 79.7703 22.5496 80.3279 22.2454C80.9025 21.9244 81.3587 21.4513 81.6967 20.8261C82.0346 20.2009 82.2036 19.4406 82.2036 18.5451C82.2036 17.2102 81.8487 16.188 81.139 15.4783C80.4462 14.7518 79.5929 14.3885 78.579 14.3885C77.5651 14.3885 76.7118 14.7518 76.019 15.4783C75.343 16.188 75.0051 17.2102 75.0051 18.5451C75.0051 19.8799 75.3346 20.9106 75.9936 21.6371C76.6695 22.3468 77.5144 22.7016 78.5283 22.7016Z" fill="#297BCA" />
    <path d="M87.5441 18.4944C87.5441 17.0751 87.8229 15.8163 88.3805 14.718C88.955 13.6197 89.7323 12.7749 90.7124 12.1835C91.6925 11.5921 92.7824 11.2965 93.9822 11.2965C94.8946 11.2965 95.7649 11.4992 96.5929 11.9047C97.4209 12.2933 98.0799 12.8171 98.5699 13.4761V6.81042H102.169V25.5656H98.5699V23.4873C98.1306 24.1801 97.5138 24.7377 96.7196 25.1601C95.9254 25.5825 95.0045 25.7937 93.9568 25.7937C92.774 25.7937 91.6925 25.4896 90.7124 24.8813C89.7323 24.273 88.955 23.4197 88.3805 22.3214C87.8229 21.2063 87.5441 19.9306 87.5441 18.4944ZM98.5953 18.5451C98.5953 17.6833 98.4263 16.9483 98.0883 16.3401C97.7504 15.7149 97.2941 15.2418 96.7196 14.9208C96.1451 14.5828 95.5283 14.4139 94.8693 14.4139C94.2103 14.4139 93.602 14.5744 93.0443 14.8954C92.4867 15.2165 92.0304 15.6896 91.6756 16.3147C91.3376 16.923 91.1687 17.6496 91.1687 18.4944C91.1687 19.3392 91.3376 20.0827 91.6756 20.7247C92.0304 21.3499 92.4867 21.8314 93.0443 22.1694C93.6188 22.5073 94.2272 22.6763 94.8693 22.6763C95.5283 22.6763 96.1451 22.5158 96.7196 22.1947C97.2941 21.8568 97.7504 21.3837 98.0883 20.7754C98.4263 20.1502 98.5953 19.4068 98.5953 18.5451Z" fill="#297BCA" />
    <path d="M111.009 25.7937C109.859 25.7937 108.829 25.5909 107.916 25.1854C107.004 24.763 106.277 24.197 105.736 23.4873C105.213 22.7777 104.925 21.992 104.875 21.1302H108.448C108.516 21.6709 108.778 22.1187 109.234 22.4735C109.707 22.8283 110.29 23.0058 110.983 23.0058C111.659 23.0058 112.183 22.8706 112.555 22.6002C112.943 22.3299 113.138 21.9835 113.138 21.5611C113.138 21.1049 112.901 20.767 112.428 20.5473C111.972 20.3108 111.237 20.0573 110.223 19.787C109.175 19.5335 108.313 19.2716 107.637 19.0013C106.978 18.7309 106.404 18.317 105.914 17.7594C105.441 17.2018 105.204 16.4499 105.204 15.5037C105.204 14.7265 105.424 14.0168 105.863 13.3747C106.319 12.7327 106.961 12.2258 107.789 11.854C108.634 11.4823 109.623 11.2965 110.755 11.2965C112.428 11.2965 113.763 11.7189 114.76 12.5637C115.757 13.3916 116.306 14.5152 116.407 15.9346H113.011C112.96 15.377 112.724 14.9377 112.301 14.6166C111.896 14.2787 111.346 14.1097 110.654 14.1097C110.012 14.1097 109.513 14.228 109.158 14.4646C108.82 14.7011 108.651 15.0306 108.651 15.453C108.651 15.9261 108.888 16.2894 109.361 16.5428C109.834 16.7794 110.569 17.0244 111.566 17.2778C112.58 17.5313 113.416 17.7932 114.075 18.0635C114.734 18.3339 115.301 18.7563 115.774 19.3308C116.264 19.8883 116.517 20.6318 116.534 21.5611C116.534 22.3721 116.306 23.0987 115.85 23.7408C115.41 24.3828 114.768 24.8897 113.923 25.2615C113.095 25.6163 112.124 25.7937 111.009 25.7937Z" fill="#297BCA" />
    <path d="M122.996 13.5521C123.452 12.9101 124.077 12.3778 124.871 11.9554C125.682 11.5161 126.603 11.2965 127.634 11.2965C128.834 11.2965 129.915 11.5921 130.878 12.1835C131.859 12.7749 132.627 13.6197 133.185 14.718C133.76 15.7994 134.047 17.0582 134.047 18.4944C134.047 19.9306 133.76 21.2063 133.185 22.3214C132.627 23.4197 131.859 24.273 130.878 24.8813C129.915 25.4896 128.834 25.7937 127.634 25.7937C126.603 25.7937 125.691 25.5825 124.897 25.1601C124.119 24.7377 123.486 24.2054 122.996 23.5633V32.2566H119.447V11.5246H122.996V13.5521ZM130.422 18.4944C130.422 17.6496 130.245 16.923 129.89 16.3147C129.552 15.6896 129.096 15.2165 128.521 14.8954C127.964 14.5744 127.355 14.4139 126.696 14.4139C126.054 14.4139 125.446 14.5828 124.871 14.9208C124.314 15.2418 123.857 15.7149 123.503 16.3401C123.165 16.9652 122.996 17.7002 122.996 18.5451C122.996 19.3899 123.165 20.1249 123.503 20.7501C123.857 21.3752 124.314 21.8568 124.871 22.1947C125.446 22.5158 126.054 22.6763 126.696 22.6763C127.355 22.6763 127.964 22.5073 128.521 22.1694C129.096 21.8314 129.552 21.3499 129.89 20.7247C130.245 20.0996 130.422 19.3561 130.422 18.4944Z" fill="#297BCA" />
    <path d="M135.713 18.4944C135.713 17.0751 135.992 15.8163 136.549 14.718C137.124 13.6197 137.893 12.7749 138.856 12.1835C139.836 11.5921 140.926 11.2965 142.126 11.2965C143.173 11.2965 144.086 11.5077 144.863 11.9301C145.657 12.3525 146.291 12.8847 146.764 13.5268V11.5246H150.338V25.5656H146.764V23.5127C146.308 24.1716 145.674 24.7208 144.863 25.1601C144.069 25.5825 143.148 25.7937 142.1 25.7937C140.918 25.7937 139.836 25.4896 138.856 24.8813C137.893 24.273 137.124 23.4197 136.549 22.3214C135.992 21.2063 135.713 19.9306 135.713 18.4944ZM146.764 18.5451C146.764 17.6833 146.595 16.9483 146.257 16.3401C145.919 15.7149 145.463 15.2418 144.889 14.9208C144.314 14.5828 143.697 14.4139 143.038 14.4139C142.379 14.4139 141.771 14.5744 141.213 14.8954C140.656 15.2165 140.199 15.6896 139.845 16.3147C139.507 16.923 139.338 17.6496 139.338 18.4944C139.338 19.3392 139.507 20.0827 139.845 20.7247C140.199 21.3499 140.656 21.8314 141.213 22.1694C141.788 22.5073 142.396 22.6763 143.038 22.6763C143.697 22.6763 144.314 22.5158 144.889 22.1947C145.463 21.8568 145.919 21.3837 146.257 20.7754C146.595 20.1502 146.764 19.4068 146.764 18.5451Z" fill="#297BCA" />
    <path d="M152.891 18.5451C152.891 17.092 153.187 15.8247 153.779 14.7434C154.37 13.6451 155.19 12.8002 156.237 12.2089C157.285 11.6006 158.485 11.2965 159.836 11.2965C161.577 11.2965 163.013 11.7358 164.145 12.6144C165.294 13.4761 166.063 14.6927 166.452 16.264H162.625C162.422 15.6558 162.075 15.1827 161.585 14.8447C161.112 14.4899 160.521 14.3125 159.811 14.3125C158.797 14.3125 157.995 14.6842 157.403 15.4277C156.812 16.1542 156.516 17.1933 156.516 18.5451C156.516 19.8799 156.812 20.919 157.403 21.6625C157.995 22.389 158.797 22.7523 159.811 22.7523C161.247 22.7523 162.185 22.1102 162.625 20.8261H166.452C166.063 22.3468 165.294 23.5549 164.145 24.4504C162.996 25.3459 161.56 25.7937 159.836 25.7937C158.485 25.7937 157.285 25.498 156.237 24.9066C155.19 24.2983 154.37 23.4535 153.779 22.3721C153.187 21.2739 152.891 19.9982 152.891 18.5451Z" fill="#297BCA" />
    <path d="M182.105 18.2409C182.105 18.7478 182.071 19.204 182.004 19.6096H171.738C171.823 20.6233 172.178 21.4175 172.803 21.992C173.428 22.5665 174.197 22.8537 175.11 22.8537C176.428 22.8537 177.365 22.2877 177.923 21.1556H181.75C181.345 22.5073 180.568 23.6225 179.418 24.5011C178.269 25.3628 176.858 25.7937 175.186 25.7937C173.834 25.7937 172.617 25.498 171.536 24.9066C170.471 24.2983 169.635 23.4451 169.026 22.3468C168.435 21.2485 168.139 19.9813 168.139 18.5451C168.139 17.092 168.435 15.8163 169.026 14.718C169.618 13.6197 170.446 12.7749 171.51 12.1835C172.575 11.5921 173.8 11.2965 175.186 11.2965C176.52 11.2965 177.712 11.5837 178.759 12.1582C179.824 12.7327 180.644 13.5521 181.218 14.6166C181.81 15.6642 182.105 16.8723 182.105 18.2409ZM178.43 17.2271C178.413 16.3147 178.084 15.5882 177.441 15.0475C176.799 14.4899 176.014 14.2111 175.084 14.2111C174.205 14.2111 173.462 14.4815 172.854 15.0221C172.262 15.5459 171.899 16.2809 171.764 17.2271H178.43Z" fill="#297BCA" />
  </svg>


)

const NavBarSmLogoWithText=()=>(
  <svg width="117" height="26" viewBox="0 0 117 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.07957 19.9969C1.03443 20.2678 0.989292 20.674 1.21497 21.3059C1.93716 23.2468 4.23913 24.3752 4.73563 24.6009C6.63137 25.5036 8.34656 25.5487 9.11389 25.5487C9.47498 25.5487 10.9194 25.5487 12.7248 24.962C13.4019 24.7363 14.3949 24.4203 15.433 23.653C16.426 22.9308 16.8323 22.2538 16.9677 22.0732C16.9677 22.0732 16.9677 22.0732 16.9677 22.0281C17.1031 21.8024 17.5544 21.0802 17.419 20.1775C17.419 20.0421 17.2385 19.1845 16.5163 18.6428C16.5163 18.6428 15.2976 17.8304 13.9435 18.5526C13.8984 18.5526 13.8532 18.5977 13.8532 18.5977C13.763 18.688 13.6727 18.7331 13.6727 18.7331C13.5373 18.8685 13.0408 19.3199 12.9956 19.365C12.9956 19.365 12.6797 19.6358 11.8672 20.1775C11.7769 20.2226 11.6867 20.3129 11.6867 20.3129L11.6415 20.358C11.4159 20.4934 10.9645 20.7191 10.4228 20.8545C10.0166 20.9448 9.15902 21.2156 8.07575 20.9448C7.17301 20.7643 6.5411 20.3129 6.27028 20.1323C5.86405 19.8615 5.81891 19.7261 4.91618 19.0039C4.23913 18.4623 4.10372 18.372 3.87804 18.3269C3.06558 18.0561 2.29825 18.4623 2.20798 18.5526C2.11771 18.5074 1.26011 19.0039 1.07957 19.9969Z" fill="#52ABFF"/>
  <path d="M16.9677 8.26076C16.9677 6.0942 17.0128 3.88251 17.0128 1.71595C16.9677 1.5354 16.8774 1.30972 16.6517 1.0389C16.5163 0.813217 16.1552 0.36185 15.5233 0.136167C15.3879 0.0910305 14.8914 -0.0443794 14.3497 0.136167C13.6276 0.36185 13.3116 0.993763 13.2213 1.12917C13.0859 1.35486 13.0408 1.58054 13.0408 1.71595C13.0408 1.80622 13.0408 1.8965 13.0408 1.98677C11.8221 1.26458 10.4229 0.813217 8.8882 0.813217C4.46481 0.858354 0.853882 4.46928 0.853882 8.93781C0.853882 13.4063 4.46481 16.9721 8.8882 16.9721C13.3116 16.9721 16.9225 13.3612 16.9225 8.93781C16.9677 8.71213 16.9225 8.48645 16.9677 8.26076ZM8.8882 12.5487C6.85706 12.5487 5.27727 10.9238 5.27727 8.89268C5.27727 6.86153 6.90219 5.23661 8.93334 5.23661C10.9645 5.23661 12.5894 6.86153 12.5894 8.89268C12.5894 10.9238 10.9194 12.5487 8.8882 12.5487Z" fill="#52ABFF"/>
  <path d="M29.1935 7.24749C29.8614 7.24749 30.4486 7.38215 30.9549 7.65148C31.4613 7.91004 31.8599 8.24939 32.1507 8.66955V7.39292H34.4293V16.4101C34.4293 17.2396 34.2623 17.9776 33.9283 18.6239C33.5943 19.2811 33.0934 19.7982 32.4255 20.1753C31.7575 20.5631 30.9495 20.757 30.0015 20.757C28.7303 20.757 27.6853 20.4608 26.8665 19.8682C26.0585 19.2757 25.6007 18.4677 25.4929 17.4443H27.7391C27.8576 17.8537 28.1108 18.1769 28.4986 18.4139C28.8972 18.6617 29.3767 18.7855 29.9369 18.7855C30.594 18.7855 31.1273 18.5862 31.5367 18.1876C31.9461 17.7998 32.1507 17.2073 32.1507 16.4101V15.0203C31.8599 15.4405 31.4559 15.7906 30.9388 16.0707C30.4324 16.3508 29.8507 16.4909 29.1935 16.4909C28.4394 16.4909 27.7499 16.2969 27.1251 15.9091C26.5002 15.5213 26.0047 14.9772 25.6384 14.277C25.2829 13.5659 25.1051 12.7526 25.1051 11.8369C25.1051 10.9319 25.2829 10.1293 25.6384 9.42905C26.0047 8.7288 26.4948 8.19014 27.1089 7.81308C27.7337 7.43602 28.4286 7.24749 29.1935 7.24749ZM32.1507 11.8692C32.1507 11.3197 32.043 10.8511 31.8275 10.4633C31.6121 10.0647 31.3212 9.76302 30.9549 9.55833C30.5886 9.34287 30.1954 9.23514 29.7753 9.23514C29.3551 9.23514 28.9673 9.33748 28.6118 9.54217C28.2562 9.74686 27.9654 10.0485 27.7391 10.4471C27.5237 10.835 27.4159 11.2982 27.4159 11.8369C27.4159 12.3755 27.5237 12.8495 27.7391 13.2589C27.9654 13.6575 28.2562 13.9646 28.6118 14.18C28.978 14.3955 29.3659 14.5032 29.7753 14.5032C30.1954 14.5032 30.5886 14.4009 30.9549 14.1962C31.3212 13.9807 31.6121 13.6791 31.8275 13.2912C32.043 12.8926 32.1507 12.4186 32.1507 11.8692Z" fill="#52ABFF"/>
  <path d="M40.6142 16.4909C39.7523 16.4909 38.9766 16.3023 38.2872 15.9253C37.5977 15.5374 37.0536 14.9934 36.655 14.2931C36.2672 13.5929 36.0733 12.7849 36.0733 11.8692C36.0733 10.9535 36.2726 10.1455 36.6712 9.44521C37.0806 8.74496 37.6354 8.2063 38.3356 7.82924C39.0359 7.4414 39.8169 7.24749 40.6788 7.24749C41.5406 7.24749 42.3217 7.4414 43.022 7.82924C43.7222 8.2063 44.2716 8.74496 44.6702 9.44521C45.0796 10.1455 45.2843 10.9535 45.2843 11.8692C45.2843 12.7849 45.0742 13.5929 44.6541 14.2931C44.2447 14.9934 43.6845 15.5374 42.9735 15.9253C42.2732 16.3023 41.4868 16.4909 40.6142 16.4909ZM40.6142 14.5194C41.0235 14.5194 41.406 14.4224 41.7615 14.2285C42.1278 14.0238 42.4187 13.7222 42.6341 13.3236C42.8496 12.9249 42.9573 12.4402 42.9573 11.8692C42.9573 11.0181 42.7311 10.3663 42.2786 9.91385C41.8369 9.4506 41.2929 9.21898 40.6465 9.21898C40.0001 9.21898 39.456 9.4506 39.0143 9.91385C38.5834 10.3663 38.368 11.0181 38.368 11.8692C38.368 12.7203 38.578 13.3774 38.9982 13.8407C39.4291 14.2931 39.9678 14.5194 40.6142 14.5194Z" fill="#52ABFF"/>
  <path d="M50.9191 16.4909C50.0573 16.4909 49.2816 16.3023 48.5921 15.9253C47.9027 15.5374 47.3586 14.9934 46.96 14.2931C46.5722 13.5929 46.3783 12.7849 46.3783 11.8692C46.3783 10.9535 46.5776 10.1455 46.9762 9.44521C47.3855 8.74496 47.9404 8.2063 48.6406 7.82924C49.3409 7.4414 50.1219 7.24749 50.9838 7.24749C51.8456 7.24749 52.6267 7.4414 53.3269 7.82924C54.0272 8.2063 54.5766 8.74496 54.9752 9.44521C55.3846 10.1455 55.5893 10.9535 55.5893 11.8692C55.5893 12.7849 55.3792 13.5929 54.9591 14.2931C54.5497 14.9934 53.9895 15.5374 53.2785 15.9253C52.5782 16.3023 51.7918 16.4909 50.9191 16.4909ZM50.9191 14.5194C51.3285 14.5194 51.711 14.4224 52.0665 14.2285C52.4328 14.0238 52.7237 13.7222 52.9391 13.3236C53.1546 12.9249 53.2623 12.4402 53.2623 11.8692C53.2623 11.0181 53.0361 10.3663 52.5836 9.91385C52.1419 9.4506 51.5979 9.21898 50.9515 9.21898C50.3051 9.21898 49.761 9.4506 49.3193 9.91385C48.8884 10.3663 48.6729 11.0181 48.6729 11.8692C48.6729 12.7203 48.883 13.3774 49.3032 13.8407C49.7341 14.2931 50.2728 14.5194 50.9191 14.5194Z" fill="#52ABFF"/>
  <path d="M56.6671 11.8369C56.6671 10.9319 56.8449 10.1293 57.2004 9.42905C57.5667 8.7288 58.0622 8.19014 58.6871 7.81308C59.3119 7.43602 60.0068 7.24749 60.7717 7.24749C61.3534 7.24749 61.9082 7.37676 62.4361 7.63532C62.964 7.8831 63.3842 8.21707 63.6966 8.63723V4.38721H65.9913V16.3454H63.6966V15.0203C63.4165 15.462 63.0233 15.8175 62.5169 16.0869C62.0106 16.3562 61.4234 16.4909 60.7555 16.4909C60.0014 16.4909 59.3119 16.2969 58.6871 15.9091C58.0622 15.5213 57.5667 14.9772 57.2004 14.277C56.8449 13.5659 56.6671 12.7526 56.6671 11.8369ZM63.7127 11.8692C63.7127 11.3197 63.605 10.8511 63.3895 10.4633C63.1741 10.0647 62.8832 9.76302 62.5169 9.55833C62.1506 9.34287 61.7574 9.23514 61.3373 9.23514C60.9171 9.23514 60.5293 9.33748 60.1738 9.54217C59.8182 9.74686 59.5274 10.0485 59.3011 10.4471C59.0857 10.835 58.9779 11.2982 58.9779 11.8369C58.9779 12.3755 59.0857 12.8495 59.3011 13.2589C59.5274 13.6575 59.8182 13.9646 60.1738 14.18C60.54 14.3955 60.9279 14.5032 61.3373 14.5032C61.7574 14.5032 62.1506 14.4009 62.5169 14.1962C62.8832 13.9807 63.1741 13.6791 63.3895 13.2912C63.605 12.8926 63.7127 12.4186 63.7127 11.8692Z" fill="#52ABFF"/>
  <path d="M71.6267 16.4909C70.8941 16.4909 70.237 16.3616 69.6552 16.103C69.0735 15.8337 68.6102 15.4728 68.2655 15.0203C67.9315 14.5679 67.7484 14.0669 67.7161 13.5175H69.9946C70.0377 13.8622 70.2047 14.1477 70.4955 14.3739C70.7972 14.6002 71.1689 14.7133 71.6106 14.7133C72.0415 14.7133 72.3755 14.6271 72.6125 14.4547C72.8602 14.2824 72.9841 14.0615 72.9841 13.7922C72.9841 13.5013 72.8333 13.2858 72.5317 13.1458C72.2408 12.995 71.7722 12.8334 71.1258 12.661C70.4578 12.4994 69.9084 12.3324 69.4775 12.16C69.0573 11.9877 68.691 11.7237 68.3786 11.3682C68.077 11.0127 67.9261 10.5333 67.9261 9.93C67.9261 9.43444 68.0662 8.98197 68.3463 8.57259C68.6372 8.16321 69.0465 7.84001 69.5744 7.603C70.1131 7.36599 70.7433 7.24749 71.4651 7.24749C72.5317 7.24749 73.3827 7.51682 74.0184 8.05547C74.654 8.58336 75.0041 9.29978 75.0687 10.2047H72.9033C72.871 9.84921 72.7202 9.5691 72.4509 9.36441C72.1923 9.14895 71.8422 9.04122 71.4005 9.04122C70.9911 9.04122 70.6733 9.11663 70.4471 9.26746C70.2316 9.41828 70.1239 9.62836 70.1239 9.89769C70.1239 10.1993 70.2747 10.431 70.5763 10.5926C70.878 10.7434 71.3466 10.8996 71.9822 11.0612C72.6286 11.2228 73.1619 11.3898 73.5821 11.5621C74.0022 11.7345 74.3631 12.0038 74.6648 12.3701C74.9772 12.7256 75.1388 13.1997 75.1495 13.7922C75.1495 14.3093 75.0041 14.7725 74.7132 15.1819C74.4331 15.5913 74.0238 15.9145 73.4851 16.1515C72.9572 16.3777 72.3378 16.4909 71.6267 16.4909Z" fill="#52ABFF"/>
  <path d="M79.269 8.6857C79.5599 8.27632 79.9585 7.93697 80.4648 7.66764C80.982 7.38754 81.5691 7.24749 82.2262 7.24749C82.9911 7.24749 83.6806 7.43602 84.2947 7.81308C84.9195 8.19014 85.4097 8.7288 85.7652 9.42905C86.1315 10.1185 86.3147 10.9211 86.3147 11.8369C86.3147 12.7526 86.1315 13.5659 85.7652 14.277C85.4097 14.9772 84.9195 15.5213 84.2947 15.9091C83.6806 16.2969 82.9911 16.4909 82.2262 16.4909C81.5691 16.4909 80.9873 16.3562 80.481 16.0869C79.9854 15.8175 79.5814 15.4782 79.269 15.0688V20.6116H77.0066V7.39292H79.269V8.6857ZM84.0038 11.8369C84.0038 11.2982 83.8907 10.835 83.6645 10.4471C83.449 10.0485 83.1581 9.74686 82.7918 9.54217C82.4363 9.33748 82.0485 9.23514 81.6283 9.23514C81.219 9.23514 80.8311 9.34287 80.4648 9.55833C80.1093 9.76302 79.8184 10.0647 79.5922 10.4633C79.3767 10.8619 79.269 11.3305 79.269 11.8692C79.269 12.4078 79.3767 12.8765 79.5922 13.2751C79.8184 13.6737 80.1093 13.9807 80.4648 14.1962C80.8311 14.4009 81.219 14.5032 81.6283 14.5032C82.0485 14.5032 82.4363 14.3955 82.7918 14.18C83.1581 13.9646 83.449 13.6575 83.6645 13.2589C83.8907 12.8603 84.0038 12.3863 84.0038 11.8369Z" fill="#52ABFF"/>
  <path d="M87.3769 11.8369C87.3769 10.9319 87.5547 10.1293 87.9102 9.42905C88.2765 8.7288 88.7667 8.19014 89.3807 7.81308C90.0056 7.43602 90.7004 7.24749 91.4653 7.24749C92.1333 7.24749 92.715 7.38215 93.2106 7.65148C93.7169 7.92081 94.1209 8.26017 94.4226 8.66955V7.39292H96.7011V16.3454H94.4226V15.0365C94.1317 15.4566 93.7277 15.8068 93.2106 16.0869C92.7042 16.3562 92.1171 16.4909 91.4492 16.4909C90.6951 16.4909 90.0056 16.2969 89.3807 15.9091C88.7667 15.5213 88.2765 14.9772 87.9102 14.277C87.5547 13.5659 87.3769 12.7526 87.3769 11.8369ZM94.4226 11.8692C94.4226 11.3197 94.3148 10.8511 94.0994 10.4633C93.8839 10.0647 93.593 9.76302 93.2267 9.55833C92.8605 9.34287 92.4672 9.23514 92.0471 9.23514C91.6269 9.23514 91.2391 9.33748 90.8836 9.54217C90.5281 9.74686 90.2372 10.0485 90.0109 10.4471C89.7955 10.835 89.6878 11.2982 89.6878 11.8369C89.6878 12.3755 89.7955 12.8495 90.0109 13.2589C90.2372 13.6575 90.5281 13.9646 90.8836 14.18C91.2499 14.3955 91.6377 14.5032 92.0471 14.5032C92.4672 14.5032 92.8605 14.4009 93.2267 14.1962C93.593 13.9807 93.8839 13.6791 94.0994 13.2912C94.3148 12.8926 94.4226 12.4186 94.4226 11.8692Z" fill="#52ABFF"/>
  <path d="M98.3289 11.8692C98.3289 10.9427 98.5175 10.1347 98.8945 9.44521C99.2716 8.74496 99.7941 8.2063 100.462 7.82924C101.13 7.4414 101.895 7.24749 102.757 7.24749C103.866 7.24749 104.782 7.52759 105.504 8.08779C106.236 8.63723 106.727 9.41289 106.974 10.4148H104.534C104.405 10.027 104.184 9.72531 103.872 9.50985C103.57 9.28362 103.193 9.1705 102.741 9.1705C102.094 9.1705 101.582 9.40751 101.205 9.88153C100.828 10.3448 100.64 11.0073 100.64 11.8692C100.64 12.7203 100.828 13.3828 101.205 13.8568C101.582 14.3201 102.094 14.5517 102.741 14.5517C103.656 14.5517 104.254 14.1423 104.534 13.3236H106.974C106.727 14.2931 106.236 15.0634 105.504 15.6344C104.771 16.2054 103.856 16.4909 102.757 16.4909C101.895 16.4909 101.13 16.3023 100.462 15.9253C99.7941 15.5374 99.2716 14.9988 98.8945 14.3093C98.5175 13.609 98.3289 12.7957 98.3289 11.8692Z" fill="#52ABFF"/>
  <path d="M116.954 11.6753C116.954 11.9985 116.932 12.2893 116.889 12.5479H110.345C110.399 13.1943 110.625 13.7006 111.023 14.0669C111.422 14.4332 111.912 14.6163 112.494 14.6163C113.334 14.6163 113.932 14.2554 114.288 13.5336H116.728C116.469 14.3955 115.974 15.1065 115.241 15.6667C114.509 16.2161 113.609 16.4909 112.542 16.4909C111.681 16.4909 110.905 16.3023 110.215 15.9253C109.537 15.5374 109.003 14.9934 108.616 14.2931C108.239 13.5929 108.05 12.7849 108.05 11.8692C108.05 10.9427 108.239 10.1293 108.616 9.42905C108.993 8.7288 109.521 8.19014 110.199 7.81308C110.878 7.43602 111.659 7.24749 112.542 7.24749C113.394 7.24749 114.153 7.43063 114.821 7.79692C115.5 8.16321 116.022 8.6857 116.388 9.36441C116.766 10.0323 116.954 10.8026 116.954 11.6753ZM114.611 11.0289C114.6 10.4471 114.39 9.98387 113.981 9.63913C113.571 9.28362 113.07 9.10586 112.478 9.10586C111.918 9.10586 111.444 9.27823 111.056 9.62297C110.679 9.95694 110.447 10.4256 110.361 11.0289H114.611Z" fill="#52ABFF"/>
</svg>
  )

const JobIcon = () => (
  <svg viewBox="0 0 123.5 126.7">
    <path
      stroke="#000000"
      fill="none"
      strokeWidth={9}
      strokeMiterlimit={10}
      d="M5.5,27.2c0.3,31.3,0.5,62.6,0.8,94c37.2,0,74.4,0,111.6,0c0-31.3,0-62.6,0-94C80.4,27.2,43,27.2,5.5,27.2z"
    />
    <path
      stroke="#000000"
      fill="none"
      strokeWidth={9}
      strokeMiterlimit={10}
      d="M42.5,26.7c0.1-5.1,0.2-10.2,0.3-15.3c0.8-1.1,7.3-5.6,8.4-5.9c7.1,0,14.1,0,21.2,0c0.9,0,8,4.8,8.4,5.6
	c0,5,0,10,0,15.1C68,26.4,55.3,26.5,42.5,26.7z"
    />
    <path
      stroke="#000000"
      fill="none"
      strokeWidth={9}
      strokeMiterlimit={10}
      d="M3.4,63.2c6.8,4.5,12,8.4,16.4,12.5c29.8,0.5,58.8,0.8,82.2,0c6.1-4,12.3-9.8,18.4-13.8"
    />
    <path
      stroke="#000000"
      fill="none"
      strokeWidth={9}
      strokeMiterlimit={10}
      d="M47.3,56.7c9.6,0,19.2,0,28.8,0"
    />
  </svg>
);

const CloseIcon = () => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.75 7.25L7.25 21.75" stroke="#9DA3A8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.25 7.25L21.75 21.75" stroke="#9DA3A8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

)

const HireIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-2 0 23 22"
    className={styles.hire_svg}
  >
    <g
      id="Group_8984"
      data-name="Group 8984"
      transform="translate(-195.274 -104.861)"
    >
      <path
        id="Path_13319"
        data-name="Path 13319"
        d="M212.92,119.1c0,1.111.022,2.223-.006,3.333a2.786,2.786,0,0,1-2.641,2.942c-1.524.205-2.726-1.043-3.055-2.779-.243-1.278.765-3.04-.625-3.743-1.044-.53-2.585,0-3.888-.122-.983-.092-1.21.258-1.169,1.128a18.448,18.448,0,0,1-.025,2.706,2.857,2.857,0,0,1-3.012,2.812,2.784,2.784,0,0,1-2.688-2.907c-.066-2.36-.038-4.722-.013-7.084a2.723,2.723,0,0,1,2.359-2.967,2.576,2.576,0,0,1,3.24,2.185c.361,2.079,1.877,1.729,3.177,1.761,1.217.031,2.5.114,2.785-1.708a2.551,2.551,0,0,1,3.184-2.244,2.681,2.681,0,0,1,2.376,2.937C212.932,116.6,212.921,117.847,212.92,119.1Z"
        transform="translate(0 0.969)"
        fill="none"
        stroke="#000000"
        strokeWidth="1.5"
      />
      <path
        id="Path_13320"
        data-name="Path 13320"
        d="M201.511,108.318a3.07,3.07,0,0,1-2.858,2.761,2.856,2.856,0,1,1,2.858-2.761Z"
        transform="translate(0 0)"
        fill="none"
        stroke="#000000"
        strokeWidth="1.5"
      />
      <path
        id="Path_13321"
        data-name="Path 13321"
        d="M210.056,111.078a2.984,2.984,0,0,1-2.741-3.052,2.827,2.827,0,1,1,2.741,3.052Z"
        transform="translate(-0.051 0)"
        fill="none"
        stroke="#000000"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);
const FeedbackIcon = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="37.7px"
    height="37.4px"
    viewBox="0 0 37.7 37.4"
    className={styles.feed_svg}
  >
    <line x1="10.1" y1="12.3" x2="10.1" y2="12.1" fill="none" />
    <path
      className={styles.st1}
      d="M26.4,14.2H10.9c-0.8,0-1.5-0.7-1.5-1.5v-0.3c0-0.8,0.7-1.5,1.5-1.5h15.4c0.8,0,1.5,0.7,1.5,1.5v0.3
	C27.9,13.6,27.2,14.2,26.4,14.2z"
    />
    <path
      className={styles.st1}
      d="M17,27.1h-6.3c-0.6,0-1.1-0.5-1.1-1.1v-0.6c0-0.6,0.5-1.1,1.1-1.1H17c0.6,0,1.1,0.5,1.1,1.1v0.6
	C18.2,26.6,17.6,27.1,17,27.1z"
    />
    <g>
      <path
        className={styles.st1}
        d="M35.5,0.2H2.2c-1.1,0-2,0.7-2,1.5v33.9c0,0.8,0.9,1.5,2,1.5h21.5c0.7,0,1.4-0.2,2-0.6c0.1-0.1,0.2-0.2,0.4-0.3
		l10.6-10.7c0.5-0.5,0.8-1.2,0.8-1.8v-22C37.4,0.9,36.6,0.2,35.5,0.2z M32.8,27l-7.1,7.2v-8.9h8.8L32.8,27z M35.5,23.8h-9.8
		c-1.1,0-2,0.7-2,1.5v10.4H2.2V1.7h33.3C35.5,1.7,35.5,23.8,35.5,23.8z"
      />
    </g>
  </svg>
);

const MessageIcon = () => (
  <svg viewBox="0 0 96.8 105.5">
    <path
      className={styles.st30}
      d="M48.4,2.5C23.1,2.5,2.5,23,2.5,48.2c0,14,6.4,26.6,16.4,35c0,5.9,0,11.8,0,17.6c4.7-3.2,9.3-6.4,14-9.6
    c4.9,1.7,10.1,2.7,15.5,2.7c25.4,0,45.9-20.5,45.9-45.7C94.3,23,73.8,2.5,48.4,2.5z"
    />
    <g className={styles.st31}>
      <path
        d="M33.2,65.2c-0.1,0.5-0.2,1.3,0.3,2.5c1.4,3.7,5.7,5.8,6.6,6.2c3.6,1.7,6.8,1.8,8.3,1.8c0.7,0,3.4,0,6.8-1.1
      c1.3-0.4,3.2-1,5.1-2.5c1.9-1.4,2.6-2.6,2.9-3c0,0,0,0,0-0.1c0.3-0.4,1.1-1.8,0.9-3.5c0-0.3-0.3-1.9-1.7-2.9c0,0-2.3-1.5-4.9-0.2
      c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0.2-0.3,0.3-0.3,0.3c-0.3,0.3-1.2,1.1-1.3,1.2c0,0-0.6,0.5-2.1,1.5c-0.2,0.1-0.3,0.3-0.3,0.3
      l-0.1,0.1c-0.4,0.3-1.3,0.7-2.3,0.9c-0.8,0.2-2.4,0.7-4.4,0.2c-1.7-0.3-2.9-1.2-3.4-1.5c-0.8-0.5-0.9-0.8-2.6-2.1
      c-1.3-1-1.5-1.2-2-1.3c-1.5-0.5-3,0.3-3.2,0.4C35.2,62.4,33.5,63.4,33.2,65.2z"
      />
      <path
        d="M63.2,43.1c0-4.1,0.1-8.3,0.1-12.3c-0.1-0.3-0.3-0.8-0.7-1.3c-0.3-0.4-0.9-1.3-2.1-1.7c-0.3-0.1-1.2-0.3-2.2,0
      c-1.4,0.4-2,1.6-2.1,1.9c-0.3,0.4-0.3,0.9-0.3,1.1c0,0.2,0,0.3,0,0.5c-2.3-1.4-4.9-2.2-7.8-2.2c-8.3,0.1-15.2,6.9-15.2,15.3
      s6.8,15.2,15.2,15.2s15.2-6.8,15.2-15.2C63.2,43.9,63.1,43.5,63.2,43.1C63.1,43.1,63.2,43.1,63.2,43.1z M47.9,51.2
      c-3.8,0-6.8-3.1-6.8-6.9s3.1-6.9,6.9-6.9s6.9,3.1,6.9,6.9S51.8,51.2,47.9,51.2z"
      />
    </g>
  </svg>

  // <svg viewBox="0 0 50.4 51.8" className={styles.msg_svg}>
  //   <g>
  //     <g className={styles.svgMsg}>
  //       <circle cx="25.4" cy="21.2" r="9" className={styles.st33} />
  //       <path
  //         d="M32.1,22.3L32.1,22.3c-1.3,0-2.4-1.1-2.4-2.4v-6.3c0-1.3,1.1-2.4,2.4-2.4h0c1.3,0,2.4,1.1,2.4,2.4v6.3
  //     C34.5,21.2,33.4,22.3,32.1,22.3z"
  //       />
  //       <path className={styles.st00} d="M18.6,33c0,7,13,6.6,13,0" />
  //       <path
  //         className={styles.st11}
  //         d="M19.1,34.2H18c-0.5,0-1-0.4-1-1l0,0c0-0.5,0.4-1,1-1h1.1c0.5,0,1,0.4,1,1l0,0C20.1,33.8,19.7,34.2,19.1,34.2z
  //     "
  //       />
  //       <path
  //         className={styles.st11}
  //         d="M32.1,34.2H31c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h1.1c0.5,0,1,0.4,1,1v0C33.1,33.7,32.7,34.2,32.1,34.2z"
  //       />
  //     </g>
  //     <path
  //       className={styles.st22}
  //       d="M25.2,1C11.6,1,1.2,12.8,1,24.4C0.8,34.7,8.7,41.5,9.5,42.2c0,0,0,0,0,0l-0.2,7.8l7.1-3.9
  //   c2.4,0.9,5.4,1.6,8.7,1.6c13.4,0,24.2-10.5,24.2-23.4C49.4,11.5,38.6,1,25.2,1z"
  //     />
  //   </g>
  // </svg>
);

const NotificationIcon = () => (
  <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.0598 9.96177C8.45273 6.4254 11.4419 3.75 15 3.75s6.5473 2.6754 6.9402 6.21177l.3147 2.83273c.052.4678.078.7017.1172.9313.1407.8253.4101 1.6235.7984 2.3653.108.2064.229.4081.4712.8117l.7909 1.3182c.8058 1.3429 1.2086 2.0144.9214 2.5217-.2872.5073-1.0703.5073-2.6364.5073H7.28238c-1.56611 0-2.34916 0-2.63639-.5073-.28723-.5073.11565-1.1788.92141-2.5217l.79092-1.3182c.24214-.4036.36321-.6053.47122-.8117.38827-.7418.65768-1.54.7984-2.3653.03915-.2296.06514-.4635.11711-.9313l.31475-2.83273Z"
      stroke="#000"
      strokeWidth="2"
    />
    <path
      d="M11.3778 22.0809c.2136 1.196.6844 2.2529 1.3393 3.0067.655.7538 1.4574 1.1624 2.2829 1.1624.8255 0 1.6279-.4086 2.2829-1.1624.6549-.7538 1.1257-1.8107 1.3393-3.0067"
      stroke="#000"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
NavbarEl.propTypes = {
  bannerIsOpen: PropTypes.bool.isRequired
};