import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { addUserDefinedValueToMasterDBApi } from "@/services/utilitiesService"
import { showToastMessage } from "@/components/ToastComponent"
import CustomSelectWrapper from "@/components/CustomSelectV2/CustomSelect"
import VideoPreview from "@/components/InterviewComponents/VideoPreview/VideoPreview"
import { useSelector } from "react-redux"
import { interviewAxiosInstance, mainAxiosInstance } from "@/services/networkAdapters/axiosInstance"
import { logFirebaseEvent } from "@/services/firebase"
import AiSuggestedComp from "@/components/AISuggestedComp/AISuggestedComp"
import styles from "./styles/AiInterviewDemoPage.module.scss"
import Layout from "./components/Layout"

export default function InterviewSetupPage() {
  const history = useHistory()
  const [designationData, setDesignationData] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [selectedSkills, setSelectedSkills] = useState([])
  const [errors, setErrors] = useState({})
  const [localCameraStream, setLocalCameraStream] = useState(null);
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const videoRef = useRef(null);
  const [aiRecommendedSkills, setAiRecommendedSkills] = useState([]);
  const [fetchRecommendations, setFetchRecommendations] = useState(false);
  const [aiRecommendationsFetched, setAiRecommendationsFetched] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [offerId, setOfferId] = useState(null);
  const [loading, setLoading] = useState(false);

  const userInfo = useSelector((state) => state.user);

  const fetchAiRecommendedSkills = async () => {
    if (designationData.id && !aiRecommendationsFetched) {
        const response = await mainAxiosInstance(`config/suggested_skills/designation/${designationData.id}`);
        setAiRecommendedSkills(response.data.data);
        setFetchRecommendations(true);
    }
  };

  const handleAddSkill = (skill) => {
    if (selectedSkill.length < 10) {
        setSelectedSkill((prev) => {
            let flag = false;
            for (let i = 0; i < prev.length; i += 1) {
                if (skill.id === prev[i].id) {
                    flag = true;
                    break;
                }
            }
            if (!flag) return [skill, ...prev];
            return prev;
        });

        setAiRecommendedSkills((prev) => prev.filter((skillData) => skillData.id !== skill.id));
    }
  };

  const handleDropdownChange = ([skill]) => {
      console.priorityLog(skill);
      handleAddSkill(skill);
  };

  const handleRemoveSkill = (skill) => {
      setSelectedSkill(prev => prev.filter((data) => data.id !== skill.id));
  }

  useEffect(() => {
      const fetchSkillsWithStateUpdate = async () => {
          try {    
              // Wait for the state to update
              await new Promise(resolve => {
                  setTimeout(resolve, 0)
              });
  
              // Call the async function after state updates
              await fetchAiRecommendedSkills();
          } catch (err) {
              showToastMessage("error", "Some Error Occurred!!!");
          }
      };
  
      if(designationData) {
        fetchSkillsWithStateUpdate();
      }
  }, [designationData]);
      
  const generateInterviewLink = (offerIdVal) => {
    const dataCreate = {
      offerId: offerIdVal,
      noOfCandidates: 1,
      noOfHoursAvailable: 1,
      interviewTypes: [1, 3, 4],
      roundNumber: 1,
    } 

    interviewAxiosInstance
      .post("link/create", dataCreate)
      .then((res) => {
        if(res?.data?.data?.interviewLink) {
          window.location.href = res?.data?.data?.interviewLink;
        } else {
          history.push("/dashboard");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          showToastMessage("error", err.response.data.message);
        }
      });
  }

  const handleSubmit = () => {
    mainAxiosInstance
        .post(`/offer/free-flow/create-interview`, {
          designationId: designationData.id,
          title: designationData.value,
          skill: selectedSkill.map((skill) => skill.id),
          screeningQuestion: [],
          offerTypeId: 5
        })
        .then((res) => {
            // window.gtag('event', 'conversion', { // W_Hirer_Interview_Offer_Created (Google Ads Direct)
            //   send_to: 'AW-16450108138/zBtACIKpwIIaEOr1gqQ9',
            //   userId: userInfo.user_id,
            //   email: userInfo.email_id,
            //   name: userInfo.name,
            //   mobile: userInfo.mobile_number,
            //   interviewTitle: designationData.value,
            //   offerId: res.data.offerId
            // });

            logFirebaseEvent(`W_Ai_Interview_Demo_Interview`, {
              userId: userInfo.user_id,
              email: userInfo.email_id,
              name: userInfo.name,
              mobile: userInfo.mobile_number,
              interviewTitle: designationData.value,
              offerId: res.data.offerId
            });

            setOfferId(() => res.data.offerId);
            generateInterviewLink(res.data.offerId);
            setLoading(false);
        })
        .catch((err) => {
            setLoading(false);
            showToastMessage("error", err?.response?.data?.message || "Some Error Occured. Please Try Again!!!");
            setTimeout(() => {
              history.push("/dashboard");
            }, 1000);
        });
  }

  const startCameraStream = async () => {
    const cameraStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false
    });
    setLocalCameraStream(cameraStream);
  }

  const stopCameraStream = () => {
    if (localCameraStream) {
      localCameraStream.getTracks().forEach((track) => track.stop());
      setLocalCameraStream(null);
    }
  }

  useEffect(() => {
    if(localCameraStream) {
      setCameraEnabled(true);
    } else {
      setCameraEnabled(false);
    }
  }, [localCameraStream]);

  useEffect(() => {
    startCameraStream();

    return () => {
      stopCameraStream();
    }
  }, []);

  return (
    <Layout>
      <div className={styles.container}>
        <div className={`${styles.content} ${styles.flexColumn}`}>
          <div className={styles.headers}>
            <h1 className={styles.title}>
              Time To Launch
              <span role="img" aria-label="airplane">✈️</span>
            </h1>
            <h2 className={styles.subtitle2}>Head start your AI Interview in 2 steps!</h2>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.leftSection}>
              {/* <DeviceCheck onStatusChange={setDeviceReady} /> */}
              <VideoPreview
                isSessionStart={false}
                userName={userInfo.name}
                isCameraEnabled={cameraEnabled}
                localStream={localCameraStream}
                userIsSpeaking={false}
                videoRef={videoRef}
              />
            </div>
            <div className={styles.rightSection}>
              {/* <CustomFormSelectWrapper
                title="What’s the role you're hiring for?"
                required
                type="designation"
                theme={errors.designationData ? "errorOnboardingV2" : "OnboardingV2"}
                async
                allowNewOptions
                onChange={(e) => {
                  if (e.__isNew__) {
                    addUserDefinedValueToMasterDBApi({ value: e.label, master: "designation" })
                      .then((res) => {
                        if (res.status === 200) {
                          const d = { value: res.data.data.id, label: res.data.data.value }
                          showToastMessage("info", "Job title added successfully.")
                          setDesignationData(d)
                        }
                      })
                      .catch((err) => {
                        showToastMessage("error", err.response.data.message)
                      })
                  } else {
                    setDesignationData(e)
                  }
                  setErrors((prev) => ({ ...prev, designationData: null }))
                }}
                value={designationData}
                placeholder="Enter the role name like React Developer, UX/UI Designer"
                errorMessage={errors.designationData?.placeHolderMessage}
              />
              <div className={styles.skillSection}>
                <h3 className={styles.skillTitle}>Select up to 10 skills for Evaluation!</h3>
                <CustomSelectWrapper
                  theme={errors.skillData ? "errorOnboardingV2" : "OnboardingV2"}
                  type="skill"
                  allowNewOptions
                  onChange={(e) => {
                    handleSkillSelect(e)
                    setErrors((prev) => ({ ...prev, skillData: null }))
                  }}
                  placeholder="Eg JAVA"
                  errorMessage={errors.skillData?.placeHolderMessage}
                />
                <div className={styles.selectedSkills}>
                  {selectedSkills.map((skill, index) => (
                    <span key={index} className={styles.skillChip}>
                      {skill}
                      <button
                        className={styles.deleteSkillButton}
                        onClick={() => handleSkillDelete(skill)}
                      >
                        &times;
                      </button>
                    </span>
                  ))}
                </div>
                <button
                  type="submit"
                  className={styles.submitButton}
                  onClick={handleProceed}
                >
                  Proceed
                  <span className={styles.arrow}>→</span>
                </button>
              </div>
              {aiRecommendedSkills.length !== 0 && (
                <div className={styles.AiSuggestedSkills}>
                  <h3><img src={AiInterviewBuilderSVG} alt='' />AI Suggested Skills</h3>
                  <div className={styles.AiRecommendedSkillGrp}>
                    {aiRecommendedSkills.map((skill) => (
                      <div key={skill.id} onClick={() => handleAddSkill(skill)}>
                        <div><AddIcon /></div>
                        {skill.value.length > 20 ? `${skill.value.slice(0, 20)}...` : skill.value}
                      </div>
                    ))}
                  </div>
                </div>
              )} */}
              <div className={styles.majorContentDiv}>
                <div className={styles.DesignationContainer}>
                    <h3>What role are you interviewing for?</h3>
                    <InputDropdown
                        placeHolder="Enter role name (E.g. React developer, Frontend developer, Sales...)"
                        type="designation"
                        value={designationData}
                        onChange={(res) => {
                            setFetchRecommendations(false); // Ensure this state is set first
                            setAiRecommendedSkills([]);
                            setDesignationData({ ...res[0], label: res[0].value })
                        }}
                    />
                </div>
                <div className={styles.displayingSelectedSkills}>
                    <h3>Select up to 10 skills for evaluation! <span>{selectedSkill.length}/10</span></h3>
                    { selectedSkill.length > 0 && (
                      <div className={`${styles.SkillDisplayArea} ${aiRecommendedSkills.length === 0 && styles.notSuggested}`}>
                        {selectedSkill.map((skill) => (
                          <div key={skill.id} onClick={() => handleRemoveSkill(skill)}>
                            {skill.value}
                          </div>
                        ))}
                      </div>
                    )}
                    <InputDropdown
                        placeHolder="Search skills"
                        type="skill"
                        onChange={handleDropdownChange}
                        disabled={selectedSkill.length >= 10}
                    />
                </div>
              </div>
              {aiRecommendedSkills.length !== 0 && (
                <AiSuggestedComp title="AI Suggested Skills" handleAdd={handleAddSkill} itemArr={aiRecommendedSkills} variant="AiInterview" />
              )}
              <button
                type="button"
                className={`${styles.submitButton} ${(!designationData && selectedSkill.length === 0) && styles.disabled}`}
                disabled={!designationData && selectedSkill.length === 0}
                onClick={handleSubmit}
              >
                Proceed
                <span className={styles.arrow}>→</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}


const InputDropdown = ({
    placeHolder,
    type,
    onChange,
    isError = false,
    searchType,
    disabled,
    value
  }) => {
    const handleInputChange = (option, type, e) => {
        if (e && e.__isNew__) {
            addUserDefinedValueToMasterDBApi({
              value: e.label,
              master: type,
            })
              .then((res) => {
                if (res.status === 200) {
                  const d = {
                    id: res.data.data.id,
                    value: res.data.data.value,
                  };
                  console.log(d);
                  showToastMessage(
                    "info",
                    `${type} added successfully.`
                  );
                  onChange([d], type);
                }
              })
              .catch((err) => {
                showToastMessage(
                  "error",
                  err.response.data.message
                );
              });
        } else {
            onChange(option, type);
        }
    };

    return (
        <div className={styles.inputBoxContainer} style={{ marginBottom: '10px' }}>
            <CustomSelectWrapper
              theme="ai_interview"
              type={type}
              className={styles.selectBox}
              placeholder={placeHolder}
              value={value}
              async
              readOnly={disabled}
              searchType={searchType || null}
              allowNewOptions
              onChange={(e) =>
                handleInputChange(
                  [
                    {
                      id: e.value,
                      value: e.label,
                    }
                  ],
                  type,
                  e
                )
              }
            />
        </div>
    );
};