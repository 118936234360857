/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaEnvelope,
  FaPhone
} from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import "./Footer.scss";
import { useSelector, useDispatch } from "react-redux";
import dwngoogle from "@/assets/svg/downplaystore.svg";
import dwnios from "@/assets/svg/downappstore.svg";
import styles from "./Footer.module.scss";
import { ReactComponent as FullLogo } from "../../assets/svg/fulllogo.svg";

const resourceLinks = [
  {
    title: "Company",
    type: "RESOURCES",

    links: [
      { href: "/about_us", label: "About Us" },
      {
        href: "/ourteam",
        label: "Our Team"
      },
      { href: "/careers", label: "Careers" },
      { href: "/contact-us", label: "Contact Us" },
      {
        href: "/blogs",
        label: "Blogs"
      },
      {
        href: "/press-room",
        label: "Press Room"
      },
      {
        href: "/blood-donors",
        label: "Find Blood Donors"
      }
    ]
  },
  {
    title: "Legal",
    type: "LEGAL",
    links: [
      { href: "/terms-of-use", label: "Terms of Use" },
      { href: "/privacy-policy", label: "Privacy Policy" },
      { href: "/cookie-policy", label: "Cookie Policy" },
      { href: "/refund-policy", label: "Refund Policy" },
      // { href: "/faq", label: "FAQ" }
    ]
  }
];
const jobColumns = [
  {
    title: "Jobs by Location",
    type: "JOBS",
    links: [
      { href: "/jobs-in-delhi", label: "Jobs in Delhi" },
      { href: "/jobs-in-mumbai", label: "Jobs in Mumbai" },
      { href: "/jobs-in-gurugram", label: "Jobs in Gurugram" },
      { href: "/jobs-in-noida", label: "Jobs in Noida" },
      { href: "/jobs-in-pune", label: "Jobs in Pune" },
      { href: "/jobs-in-kolkata", label: "Jobs in Kolkata" },
      { href: "/jobs-in-chandigarh", label: "Jobs in Chandigarh" },
      { href: "/jobs-in-hyderabad", label: "Jobs in Hyderabad" }
    ]
  },
  // {
  //   title: "Jobs by Skills",
  //   type: "JOBS",
  //   links: [
  //     { href: "/sales-jobs", label: "Sales Jobs" },
  //     { href: "/marketing-jobs", label: "Marketing Jobs" },
  //     { href: "/java-jobs", label: "Java Jobs" },
  //     {
  //       href: "/business-development-jobs",
  //       label: "Business Development Jobs"
  //     },
  //     { href: "/digital-marketing-jobs", label: "Digital Marketing Jobs" },
  //     { href: "/android-jobs", label: "Android Jobs" },
  //     { href: "/management-jobs", label: "Management Jobs" },
  //     { href: "/javascript-jobs", label: "Javascript Jobs" }
  //   ]
  // },
  {
    title: "Jobs by Designation",
    type: "JOBS",
    links: [
      {
        href: "/jobs-for-business-development-executive",
        label: "Business Development Executive Jobs"
      },

      { href: "/jobs-for-sales-executive", label: "Sales Executive Jobs" },

      {
        href: "/jobs-for-assistant-supervisor",
        label: "Assistant Supervisor Jobs"
      },

      {
        href: "/jobs-for-business-development-associate",
        label: "Business Development Associate Jobs"
      },

      { href: "/jobs-for-hr-recruiter", label: "HR Recruiter Jobs" },

      {
        href: "/jobs-for-back-office-executive",
        label: "Back Office Executive Jobs"
      },

      {
        href: "/jobs-for-field-sales-executive",
        label: "Field Sales Executive Jobs"
      },

      {
        href: "/jobs-for-data-entry-operator",
        label: "Data Entry Operator Jobs"
      }
    ]
  }
];
// const productColumns = [
//   {
//     title: "Growth Companion",
//     type: "CANDIDATES",
//     variant: 1,
//     links: [
//       { href: "/premium/ai-career-counsellor", label: "Kimaya-Career Counsellor" },
//       { href: "premium/ai-trainer", label: "Andrew-Skill Trainer" },
//       { href: "/premium/interview-coaching", label: "Ajay-Mock Interview" },
//       { href: "/premium/skill-gap-analysis", label: "Sakshi-Skill gap Analysis" },
//       { href: "/premium/life-coach", label: "Anita-Life Coach" },
//       { href: "/premium/fitness-counsellor", label: "Arnold-Fitness Coach" }
//     ]
//   },
//   {
//     title: "Other Products",
//     type: "CANDIDATES",
//     variant: 1,
//     links: [
//       { href: "/premium/jump-the-queue", label: "Profile Boost" }
//     ]
//   },
//   {
//     title: "Goodspace Bundles",
//     type: "CANDIDATES",
//     variant: 1,
//     links: [
//       { href: "/premium/career-starter", label: "Career Bundles" },
//       { href: "/premium/career-growth", label: "Career Growth" },
//       { href: "/premium/ultimate-career-kit", label: "Ultimate Career Kit" },
//     ]
//   }
// ];
const hireColumns = [
  {
    title: "Hire by Location",
    type: "CANDIDATES",
    variant: 1,
    links: [
      { href: "/hire-in-delhi", label: "Hiring in Delhi" },
      { href: "/hire-in-mumbai", label: "Hiring in Mumbai" },
      { href: "/hire-in-gurugram", label: "Hiring in Gurugram" },
      { href: "/hire-in-noida", label: "Hiring in Noida" },
      { href: "/hire-in-pune", label: "Hiring in Pune" },
      { href: "/hire-in-kolkata", label: "Hiring in Kolkata" },
      { href: "/hire-in-chandigarh", label: "Hiring in Chandigarh" },
      { href: "/hire-in-hyderabad", label: "Hiring in Hyderabad" }
    ]
  },

  {
    title: "Hire by Designation",
    type: "CANDIDATES",
    variant: 1,
    links: [
      { href: "/hire-for-sales-skills", label: "Hire Sales Executive" },
      { href: "/hire-for-marketing-skills", label: "Hire Marketing Executive" },
      { href: "/hire-for-javascript-skills", label: "Hire Java Developer" },
      {
        href: "/hire-for-business-development-skills",
        label: "Hire Business Analyst"
      },
      {
        href: "/hire-for-digital-marketing-skills",
        label: "Hire Digital Marketing Executive"
      },
      { href: "/hire-for-android-skills", label: "Hire Android Developers" },
      // { href: "/hire-for-management-skills", label: "Management" },
      { href: "/hire-for-javascript-skills", label: "Hire Javascript Developer" }
    ]
  },
  // {
  //   title: "Candidates by Designation",
  //   type: "CANDIDATES",
  //   variant: 1,
  //   links: [
  //     { href: "/hire-for-telecaller", label: "Telecaller" },

  //     {
  //       href: "/hire-for-business-development-executive",
  //       label: "Business Development Executive"
  //     },

  //     { href: "/hire-for-sales-executive", label: "Sales Executive" },

  //     {
  //       href: "/hire-for-assistant-supervisor",
  //       label: "Assistant Supervisor"
  //     },

  //     {
  //       href: "/hire-for-business-development-associate",
  //       label: "Business Development Associate"
  //     },

  //     { href: "/hire-for-hr-recruiter", label: "HR Recruiter" },

  //     {
  //       href: "/hire-for-back-office-executive",
  //       label: "Back Office Executive"
  //     },

  //     {
  //       href: "/hire-for-field-sales-executive",
  //       label: "Field Sales Executive"
  //     },

  //     {
  //       href: "/hire-for-data-entry-operator",
  //       label: "Data Entry Operator"
  //     }
  //   ]
  // }
];

export default function Footer() {
  const { userMode } = useSelector((state) => state.user);
  const [showJobList, setShowJobList] = useState(false);
  const [showProdList, setProdList] = useState(false);
  const [showCandidateList, setShowCandidateList] = useState(false);
  const [forcedRerenderCount, setForcedRerenderCount] = useState(0);
  const history = useHistory();
  const showLinksAccordingToPath = (pathname) => {
    if (pathname === "/") {
      setShowCandidateList(false);
      setShowJobList(true);
      setProdList(true);
    } else if (pathname.includes("/jobs")) {
      setShowJobList(true);
      setShowCandidateList(false);
      setProdList(true);
    } else if (pathname.includes("hire") || pathname.includes("hirer") || pathname.includes("ai-interview")||pathname.includes("pricing") || pathname.includes("ourteam") || pathname.includes("manage-jobs") || pathname.includes("blogs") || pathname.includes("about_us")) {
      setShowJobList(false);
      setShowCandidateList(true);
      setProdList(false);
    } else if (pathname.includes("job-seeker") || pathname.includes("careers")) {
      setShowJobList(true);
      setShowCandidateList(false);
      setProdList(true);
    } else {
      setShowJobList(true);
      setShowCandidateList(false);
      setProdList(true);
    }
  };
  history.listen((location) => {
    showLinksAccordingToPath(location.pathname);
    setForcedRerenderCount((a) => a + 1);
  });
  console.log(history.location.pathname.includes("/onboarding-chat"), " ", history.location.pathname)
  useEffect(() => {
    showLinksAccordingToPath(history.location.pathname);
  }
    , []);

  const path = history.location.pathname;
  const isInterviewRoute = (path) => {
    const pattern = /^\/interview\/[^/]+\/[^/]+$/;
    return pattern.test(path);
  }
  const matchesPattern = /^\/([a-zA-Z]+-)*\d+$/.test(history.location.pathname);

  console.log(userMode, path);

  // this is for hirer footer depending upon route
  // const excludedPaths = ['/hirer', '/pricing', '/ourteam', '/blogs', '/about_us', '/hirer/dashboard','/manage-jobs/'];
  // const shouldExcludeTitle = excludedPaths.includes(path);

  // const hireColumnsFiltered = hireColumns.filter(item =>
  //   !(shouldExcludeTitle && item.title === "Candidates by Designation")
  // );


  return (


    (history.location.pathname === "/onboarding-chat" ||
      history.location.pathname.includes("/investor") ||
      history.location.pathname.includes("/job-apply") ||
      history.location.pathname.includes("/onboarding-chat") ||
      history.location.pathname.includes("/create-job-post") ||
      history.location.pathname.includes("/update-job-post") ||
      history.location.pathname.includes("/dashboard") ||
      history.location.pathname.includes("/manage-jobs") ||
      history.location.pathname.includes("/career-counselor") ||
      history.location.pathname.includes("/fitness") ||
      history.location.pathname.includes("/mock-interviewer") ||
      history.location.pathname.includes("/pandit-ji") ||
      history.location.pathname.includes("/skill-gap-analysis") ||
      history.location.pathname.includes("/trainer") ||
      history.location.pathname.includes("/life-coach") ||
      history.location.pathname.includes("/activate-auto-recruiter") ||
      history.location.pathname.includes("/recruiter/recruiter-details") ||
      history.location.pathname.includes("/auto-recruiter-demo") ||
      history.location.pathname.includes("/ai-recruiter-demo") ||
      history.location.pathname.includes("/interview") ||
      history.location.pathname.includes("/onboarding") ||
      history.location.pathname.includes("/socialPage") ||
      history.location.pathname.includes("/interview-v2") ||
      history.location.pathname.includes("/create-interview") ||
      history.location.pathname.includes("/manage-interviews") ||
      history.location.pathname.includes("/interview-dashboard") ||
      history.location.pathname.includes("/candidate-report") ||
      matchesPattern ||
      isInterviewRoute(path)
    ) ? <></> :
      (
         <div 
        className={`
          ${styles.container}
          ${history.location.pathname.includes("/ai-interview") && styles.footerCol}
          ${history.location.pathname.includes("/hire360") && styles.hire360Bg}
          ${history.location.pathname.includes("/job-seeker") && styles.jobSeekerBg}
        `}
      >
          <div className={styles.linksContainer}>
              {showJobList && userMode !== "HIRING" && (
                <div
                  className={`${styles.links} ${!showCandidateList || userMode ? styles.linksNoBorder : ""
                    }`}
                >
                  <div className={styles.linksContainer2} style={{ margin: '0', columnGap: '2rem' }}>
                    {jobColumns.map((item) => (
                      <div className={styles.linkGroup}>
                        <div className={styles.linkGroupTitleright}>{item.title}</div>
                        <div className={styles.linkGroupLinks}>
                          {item.links.map((link) => (
                            <Link className={styles.linkGroupLinkright} to={link.href}>
                              {link.label}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={styles.linksContainer2} style={{ margin: '0', columnGap: '2rem' }}>
                    {resourceLinks.map((item) => (
                      <div className={styles.linkGroup}>
                        <div className={styles.linkGroupTitle}>{item.title}</div>
                        <div className={styles.linkGroupLinks}>
                          {item.links.map((link) => (
                            <Link className={styles.linkGroupLink} to={link.href}>
                              {link.label}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              )}
              {!showJobList && (

                <div
                  className={`${styles.links} ${!showJobList || userMode ? styles.linksNoBorder : ""
                    }`}
                >
                  <div className={`${styles.linksContainer2} ${styles.resColumns}`} style={{ margin: '0', columnGap: '2rem' }}>
                    {hireColumns.map((item) => (
                      <div key={item.title} className={styles.linkGroup} style={{ width: '25%' }}>
                        <div className={styles.linkGroupTitleright}>{item.title}</div>
                        <div className={styles.linkGroupLinks}>
                          {item.links.map((link) => (
                            <Link key={link.href} className={styles.linkGroupLinkright} to={link.href}>
                              {link.label}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className={`${styles.linksContainer2} ${styles.resColumns}`} style={{ margin: '0', columnGap: '2rem' }}>
                    {resourceLinks.map((item) => (
                      <div className={styles.linkGroup}>
                        <div className={styles.linkGroupTitle}>{item.title}</div>
                        <div className={styles.linkGroupLinks}>
                          {item.links.map((link) => (
                            <Link className={styles.linkGroupLink} to={link.href}>
                              {link.label}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* {showProdList && userMode !== "HIRING" && (
                <div
                  className={`${styles.links} ${!showCandidateList || userMode ? styles.linksNoBorder : ""
                    }`}
                >
                  {productColumns.map((item) => (
                    <div className={styles.linkGroup}>
                      <div className={styles.linkGroupTitleright}>{item.title}</div>
                      <div className={styles.linkGroupLinks}>
                        {item.links.map((link) => (
                          <Link className={styles.linkGroupLinkright} to={link.href}>
                            {link.label}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )} */}
            </div>
            <div className={styles.left}>
              <div className={styles.leftDesc}>
                <div className="footer2__about-heading">
                  <FullLogo />
                </div>
                <div className="footer2__about-text">
                  World’s #1st AI Recruitment Platform
                </div>

                <div className={styles.connectbox}>
                  <div className="footer2__about-links-text">Let's Connect</div>
                  <div className={styles.mailbox}>
                    <MailIcon />
                    info@goodspace.ai
                  </div>
                  {/* <div className={styles.callbox}>
                    <CallIcon />
                    +91 98109 14690
                  </div> */}
                  <div className="footer2__about-links">
                    <a
                      aria-label="instagram"
                      href="https://www.instagram.com/goodspaceai/"
                      target="_blank"
                      className="footer2__about-links-link"
                      rel="noreferrer"
                    >
                      <InstaIcon />
                    </a>

                    <a
                      aria-label="linkedin"
                      href="https://www.linkedin.com/company/goodspaceclub/"
                      target="_blank"
                      className="footer2__about-links-link"
                      rel="noreferrer"
                    >
                      <LinkedinIcon />
                    </a>
                    <a
                      aria-label="linkedin"
                      href="https://github.com/GoodSpaceAI"
                      target="_blank"
                      className="footer2__about-links-link"
                      rel="noreferrer"
                    >
                      <GithubIcon />
                    </a>
                    {/* <a
                      href="https://www.facebook.com/OurGoodSpace/"
                      target="_blank"
                      className="footer2__about-links-link"
                      rel="noreferrer"
                    >
                      <FacebookIcon />
                    </a> */}
                    {/* <a
                      href="https://x.com/goodspaceai"
                      target="_blank"
                      className="footer2__about-links-link"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                    </a> */}
                  </div>
                  <div className={styles.copyright}>
                    ©2024 GoodSpace AI ® . All rights reserved worldwide.
                  </div>

                </div>
                {/* {showJobList && (
                  <>
                    <div className={styles.dwntitle}>
                      Download the GoodSpace App!
                    </div>
                    <div className={styles.dwnimages}>
                      <a href="https://play.google.com/store/apps/details?id=com.trustze&hl=en_IN&gl=US">
                        <img src={dwngoogle} className={styles.dwn} alt="play store" />
                      </a>
                      <a href="https://apps.apple.com/in/app/goodspace/id1495314369">
                        <img src={dwnios} className={styles.dwn} alt="apple store" />
                      </a>

                    </div>
                  </>
                )
                } */}
              </div>



            </div>
          </div>

      ));
}

const GithubIcon = () => (
  <svg height="24" viewBox="0 0 1792 1792" width="24" xmlns="http://www.w3.org/2000/svg" fill="white"><path d="M1664 896q0 251-146.5 451.5t-378.5 277.5q-27 5-39.5-7t-12.5-30v-211q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-121-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-86-13.5q-44 113-7 204-79 85-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-40 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 89t.5 54q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/></svg>
)

const MailIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.00014 5.98266C0.999973 5.99253 0.999957 6.00239 1.00009 6.01225V18C1.00009 19.6523 2.3478 21 4.00009 21H20.0001C21.6524 21 23.0001 19.6523 23.0001 18V6.01236C23.0002 6.00242 23.0002 5.99247 23 5.98251C22.9906 4.33822 21.6465 3 20.0001 3H4.00009C2.35359 3 1.00953 4.3383 1.00014 5.98266ZM3.10666 5.55395C3.27204 5.22692 3.61212 5 4.00009 5H20.0001C20.3881 5 20.7281 5.22692 20.8935 5.55395L12.0001 11.7793L3.10666 5.55395ZM21.0001 7.92066V18C21.0001 18.5477 20.5478 19 20.0001 19H4.00009C3.45237 19 3.00009 18.5477 3.00009 18V7.92066L11.4266 13.8192C11.7709 14.0603 12.2292 14.0603 12.5735 13.8192L21.0001 7.92066Z" fill="white" />
    </g>
  </svg>

)
const CallIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
      <path d="M4.90696 4.77172H8.88438L10.1662 7.97675L8.10661 9.34983C7.98532 9.43075 7.88589 9.54036 7.81712 9.66893C7.74836 9.7975 7.71239 9.94106 7.71241 10.0869C7.71507 10.1701 7.71241 10.0877 7.71241 10.0877V10.1064C7.71294 10.146 7.71471 10.1856 7.71773 10.2251C7.72304 10.2977 7.7319 10.396 7.74873 10.5174C7.78328 10.7566 7.84972 11.0861 7.97905 11.4741C8.23949 12.2537 8.74973 13.2627 9.74364 14.2566C10.7376 15.2505 11.7465 15.7608 12.5252 16.0212C12.9141 16.1506 13.2427 16.2161 13.4837 16.2515C13.6197 16.2705 13.7566 16.2824 13.8938 16.287L13.9053 16.2879H13.9124C13.9124 16.2879 14.0116 16.2826 13.9133 16.2879C14.0778 16.2878 14.2389 16.2419 14.3788 16.1554C14.5187 16.0689 14.6317 15.9451 14.7052 15.798L15.2987 14.6109L19.2283 15.2665V19.0934C17.3583 19.3636 12.3073 19.6302 8.3387 15.6616C4.37014 11.6929 4.63589 6.64088 4.90696 4.77172ZM9.54876 10.5174L11.1495 9.45082C11.4876 9.22525 11.7379 8.89026 11.8584 8.50203C11.9789 8.1138 11.9622 7.69595 11.8112 7.31856L10.5294 4.11352C10.3978 3.78479 10.1708 3.503 9.87761 3.30452C9.5844 3.10605 9.23845 2.99998 8.88438 3H4.8609C4.05567 3 3.29827 3.55898 3.16628 4.43243C2.8651 6.41853 2.45673 12.2847 7.08612 16.9142C11.7155 21.5437 17.5815 21.1344 19.5676 20.8341C20.441 20.7012 21 19.9447 21 19.1394V15.2665C21.0001 14.8471 20.8513 14.4413 20.5803 14.1212C20.3092 13.8012 19.9334 13.5877 19.5198 13.5187L15.5902 12.864C15.2164 12.8016 14.8325 12.8607 14.4949 13.0326C14.1572 13.2045 13.8835 13.4801 13.714 13.819L13.4075 14.4329C13.2991 14.4062 13.1918 14.3752 13.0859 14.3399C12.5367 14.1574 11.774 13.7818 10.9962 13.004C10.2185 12.2262 9.84286 11.4635 9.66037 10.9134C9.6168 10.7833 9.57984 10.6512 9.54964 10.5174H9.54876Z" fill="white" />
    </g>
  </svg>

)
const FacebookIcon = () => (
  <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5181 14.0625L13.2124 9.53809H8.87109V6.60205C8.87109 5.36426 9.47754 4.15771 11.4219 4.15771H13.3955V0.305664C13.3955 0.305664 11.6045 0 9.89209 0C6.31689 0 3.97998 2.16699 3.97998 6.08984V9.53809H0.00585938V14.0625H3.97998V25H8.87109V14.0625H12.5181Z" fill="white" />
  </svg>

)
const InstaIcon = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5051 5.60309C8.24105 5.60309 5.60822 8.23591 5.60822 11.5C5.60822 14.7641 8.24105 17.3969 11.5051 17.3969C14.7692 17.3969 17.402 14.7641 17.402 11.5C17.402 8.23591 14.7692 5.60309 11.5051 5.60309ZM11.5051 15.3338C9.39579 15.3338 7.67137 13.6145 7.67137 11.5C7.67137 9.38553 9.39066 7.66624 11.5051 7.66624C13.6196 7.66624 15.3389 9.38553 15.3389 11.5C15.3389 13.6145 13.6145 15.3338 11.5051 15.3338ZM19.0187 5.36188C19.0187 6.12658 18.4028 6.73731 17.6433 6.73731C16.8786 6.73731 16.2678 6.12144 16.2678 5.36188C16.2678 4.60231 16.8837 3.98644 17.6433 3.98644C18.4028 3.98644 19.0187 4.60231 19.0187 5.36188ZM22.9243 6.75784C22.837 4.91537 22.4162 3.28333 21.0664 1.93869C19.7218 0.594053 18.0898 0.173212 16.2473 0.0808323C14.3484 -0.0269441 8.65675 -0.0269441 6.75784 0.0808323C4.92051 0.16808 3.28846 0.588921 1.93869 1.93356C0.588921 3.2782 0.173212 4.91024 0.0808323 6.75271C-0.0269441 8.65162 -0.0269441 14.3432 0.0808323 16.2422C0.16808 18.0846 0.588921 19.7167 1.93869 21.0613C3.28846 22.4059 4.91537 22.8268 6.75784 22.9192C8.65675 23.0269 14.3484 23.0269 16.2473 22.9192C18.0898 22.8319 19.7218 22.4111 21.0664 21.0613C22.4111 19.7167 22.8319 18.0846 22.9243 16.2422C23.0321 14.3432 23.0321 8.65676 22.9243 6.75784ZM20.4711 18.2796C20.0708 19.2856 19.2958 20.0605 18.2848 20.466C16.7708 21.0664 13.1782 20.9279 11.5051 20.9279C9.83203 20.9279 6.23435 21.0613 4.72548 20.466C3.71957 20.0657 2.94461 19.2907 2.53916 18.2796C1.93869 16.7656 2.07726 13.1731 2.07726 11.5C2.07726 9.8269 1.94382 6.22922 2.53916 4.72035C2.93947 3.71444 3.71444 2.93947 4.72548 2.53403C6.23948 1.93356 9.83203 2.07213 11.5051 2.07213C13.1782 2.07213 16.7759 1.93869 18.2848 2.53403C19.2907 2.93434 20.0657 3.7093 20.4711 4.72035C21.0716 6.23435 20.933 9.8269 20.933 11.5C20.933 13.1731 21.0716 16.7708 20.4711 18.2796Z" fill="white" />
  </svg>

)
const LinkedinIcon = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.1533 23H0.384802V7.64408H5.1533V23ZM2.76648 5.54939C1.24167 5.54939 0.00488281 4.28641 0.00488281 2.7616C0.00488282 2.02918 0.295836 1.32676 0.813737 0.808854C1.33164 0.290954 2.03406 0 2.76648 0C3.49891 0 4.20133 0.290954 4.71923 0.808854C5.23713 1.32676 5.52809 2.02918 5.52809 2.7616C5.52809 4.28641 4.29078 5.54939 2.76648 5.54939ZM23.0003 23H18.242V15.5248C18.242 13.7433 18.2061 11.4587 15.7628 11.4587C13.2836 11.4587 12.9037 13.3942 12.9037 15.3965V23H8.14029V7.64408H12.7137V9.73877H12.7804C13.4171 8.53227 14.9722 7.25902 17.2922 7.25902C22.1182 7.25902 23.0054 10.437 23.0054 14.5648V23H23.0003Z" fill="white" />
  </svg>

)
const TwitterIcon = () => (
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.6986 5.48279C24.7158 5.72341 24.7158 5.96407 24.7158 6.20469C24.7158 13.5437 19.1299 22 8.92052 22C5.77519 22 2.85333 21.089 0.395508 19.5078C0.842399 19.5594 1.27204 19.5766 1.73613 19.5766C4.3314 19.5766 6.7205 18.7 8.62832 17.2047C6.18769 17.1531 4.14237 15.5547 3.43767 13.3547C3.78145 13.4062 4.12518 13.4406 4.48615 13.4406C4.98457 13.4406 5.48304 13.3718 5.94708 13.2516C3.40334 12.7359 1.49546 10.5016 1.49546 7.80312V7.73439C2.2345 8.14689 3.09395 8.4047 4.00482 8.43904C2.5095 7.44214 1.52985 5.7406 1.52985 3.81559C1.52985 2.78436 1.8048 1.83904 2.28608 1.01404C5.01891 4.38278 9.12674 6.58275 13.7329 6.82341C13.647 6.41091 13.5954 5.98127 13.5954 5.55157C13.5954 2.49216 16.0705 0 19.147 0C20.7454 0 22.1892 0.670311 23.2033 1.75312C24.4579 1.51251 25.661 1.04843 26.7267 0.412503C26.3142 1.70159 25.4376 2.78441 24.2861 3.47186C25.4033 3.35161 26.4861 3.04216 27.4829 2.61252C26.7268 3.71247 25.7814 4.69212 24.6986 5.48279Z" fill="white" />
  </svg>

)