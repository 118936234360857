/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import FullLogo from "@/components/FullLogo/FullLogo";
import { NavLink, useLocation } from "react-router-dom";
import SupportModal from "@/components/SupportModal";
import { ProgressBar } from "@/pages/EditHirerProfilePage/EditHirerProfilePage";
import { mainAxiosInstance } from "@/services/networkAdapters/axiosInstance";
import {
  DashboardIcon,
  FeedbackIcon,
  NavLinkIconWithAlert,
  PostJobIcon,
  ProfileIcon,
  HiringIcon,
  LogoIcon,
  RightArrow,
  NotifIcon,
  LogOutIcon,
  JobIcon,
  CandiIcon,
  SupportIcon,
  AiInterviewBuilder
} from "./SideNavIcons";

export default function SideNav({
  startNavbarSlideAnimate,
  setOpenDropdown,
  setStartNavbarSlideAnimate,
  messageCount,
  changeAppMode,
  userMode,
  logout,
}) {
  const location = useLocation();

  const [hasActiveProducts, setHasActiveProducts] = useState(false);

  // support modal
  const [showSupportModal, setShowSupportModal] = useState(false);
  const handleClose = () => {
    setShowSupportModal(false);
  };

  
  const getActiveProducts = () => {
    mainAxiosInstance.get("/manage_products/getActiveProductsV2").then((res) => {
      const productData = res.data.data;
      const activeProductsExist = productData.some(product => product.remainingCount > 0);
      setHasActiveProducts(activeProductsExist);
    });
  };

  useEffect(() => {
    getActiveProducts();
  }, []);

  return (
    <div
      className={`dashboardNavbar__sideNav-container ${startNavbarSlideAnimate ? "dashboardNavbar__sideNav-container-hide" : ""
        }`}
      onTransitionEnd={() => {
        setTimeout(() => {
          setOpenDropdown(false);
          setStartNavbarSlideAnimate(false);
        }, [100]);
      }}
    >

      <div className="dashboardNavbar__sideNav-links">

        {/* <NavLink
          exact
          to={`${
            userMode === "HIRING"
              ? "/hirer"
              : userMode === "SOCIAL"
              ? "/social"
              : "/member"
          }/dashboard`}
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <DashboardIcon active={location.pathname.includes("/dashboard")} />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Dashboard
          </div>
        </NavLink> */}


        {/* <div
          className="dashboardNavbar__sideNav-links-link"
          onClick={changeAppMode}
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <SwitchModeIcon />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Switch Mode
          </div>
        </div> */}



        <NavLink
          exact
          to={userMode !== "HIRING" ? "/profile" : "/hirer/profile"}
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <ProfileIcon active={location.pathname === "/hirer/profile"} />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Profile
          </div>
        </NavLink>


        {userMode === "HIRING" && (
          <ProgressBar showProgress />
        )}

        {userMode === "HIRING" && (
          <NavLink
            exact
            to="/hirer/dashboard"
            activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
          >
            <div className="dashboardNavbar__sideNav-links-link-icon">
              <JobIcon active={location.pathname === "/hirer/dashboard"} />
            </div>
            <div className="dashboardNavbar__sideNav-links-link-text">
              Jobs
            </div>
          </NavLink>

        )}

        {userMode === "HIRING" && (
          <NavLink
            exact
            to="/hire"
            activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
          >
            <div className="dashboardNavbar__sideNav-links-link-icon">
              <CandiIcon active={location.pathname === "/hire"} />
            </div>
            <div className="dashboardNavbar__sideNav-links-link-text">
              GoodDex
            </div>
          </NavLink>
        )}
        {userMode === "HIRING" && (
          <>
            <NavLink
              exact
              to="/create-job-post"
              activeClassName="dashboardNavbar__sideNav-links-link-active"
              className="dashboardNavbar__sideNav-links-link"
            >
              <div className="dashboardNavbar__sideNav-links-link-icon">
                <PostJobIcon active={location.pathname === "/create-job-post"} />
              </div>
              <div className="dashboardNavbar__sideNav-links-link-text">
                Post a Job
              </div>
            </NavLink>
            <NavLink
              exact
              to="/hirer/interview-dashboard"
              activeClassName="dashboardNavbar__sideNav-links-link-active"
              className="dashboardNavbar__sideNav-links-link"
            >
              <div className="dashboardNavbar__sideNav-links-link-icon link-icon-center">
                <AiInterviewBuilder active={location.pathname === "/hirer/interview-dashboard"} />
              </div>
              <div className="dashboardNavbar__sideNav-links-link-text">
                AI Interviewer
              </div>
            </NavLink>
          </>
        )}

        <NavLink
          exact
          to="/chat"
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <NavLinkIconWithAlert
              active={location.pathname === "/chat"}
              notifCount={messageCount}
            />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Message
          </div>
        </NavLink>

        <NavLink
          exact
          to="/notifications"
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
          onClick={(e) => {
            e.preventDefault();
            setShowSupportModal(true);
          }}
        >
          <div className="dashboardNavbar__sideNav-links-link-icon" style={{ display: 'flex', justifyContent: 'center' }}>
            <SupportIcon />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Need Support
          </div>
        </NavLink>

        <NavLink
          exact
          to="/notifications"
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <NotifIcon
              active={location.pathname === "/notifications"}
            />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Notification
          </div>
        </NavLink>
        {(userMode !== "HIRING" && hasActiveProducts) && (
          <NavLink
            className="dashboardNavbar__sideNav-links-link"
            exact
            to="/premium/manage-products"
          >
            <div className="dashboardNavbar__sideNav-links-link-icon">
              <ProductsIcon />
            </div>
            <div className="dashboardNavbar__sideNav-links-link-text">
              Manage your products
            </div>
          </NavLink>
        )}

        {/* <NavLink
          exact
          to="/feedback"
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <FeedbackIcon active={location.pathname === "/feedback"} />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Feedback
          </div>
        </NavLink> */}

        {userMode !== "HIRING" && (
          <div
            className="dashboardNavbar__sideNav-links-link"
            onClick={() => changeAppMode("HIRING")}
          >
            <div className="dashboardNavbar__sideNav-links-link-icon">
              <HiringIcon />
            </div>
            <div className="dashboardNavbar__sideNav-links-link-text">
              Hire Talent
            </div>
          </div>
        )}

        <div
          className="dashboardNavbar__sideNav-links-link"
          onClick={logout}
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <LogOutIcon />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Log out
          </div>
        </div>
      </div>


      {/* <button className="dashboardNavbar__sideNav-logout" onClick={logout}>
        Log out <RightArrow />
      </button> */}

      <div className="dashboardNavbar__sideNav-logo">
        <LogoIcon />
      </div>

      <SupportModal showSupportModal={showSupportModal} handleClose={handleClose} prependText="DASHBOARD" />
    </div>
  );
}

const ProductsIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.25 1.12158L0.5 4.5602L8.25 7.99882L16 4.5602L8.25 1.12158Z" stroke="#2A78C2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.5 11.4398L8.25 14.8784L16 11.4398" stroke="#2A78C2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.5 7.99872L8.25 11.4373L16 7.99872" stroke="#2A78C2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)
