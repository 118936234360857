/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import Highlighter from "react-highlight-words";
import React, { useState, useEffect, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { FixedSizeList as List } from "react-window";
import ReactSelect, { createFilter, components } from "react-select";
import PropTypes from "prop-types";
import debounce from "debounce-promise";
import { fetchSpecificConfigApi } from "@/services/utilitiesService";
import "./CustomSelect.scss";
import { getPlacesPrediction } from "@/services/mapboxGeocodeService";

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const height = 35;
  const initialOffset = options.indexOf(value) * height;
  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

MenuList.propTypes = {
  options: PropTypes.array.isRequired,
  children: PropTypes.any.isRequired,
  maxHeight: PropTypes.number.isRequired,
  getValue: PropTypes.func.isRequired
};
const DropdownIndicator = (props) => {
  // eslint-disable-next-line react/prop-types
  const { theme } = props;
  return (
    <components.DropdownIndicator {...props}>
      {theme.dropIcon === "arrowDown" ? (
        <ArrowDownIcon color={theme.colors.iconColor} />
      ) : (
        <SearchIcon color={theme.colors.iconColor} />
      )}
    </components.DropdownIndicator>
  );
};
DropdownIndicator.propTypes = {
  theme: PropTypes.any.isRequired
};
const SearchIcon = ({ color }) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      d="M20 20L15.514 15.506L20 20ZM18 9.5C18 11.7543 17.1045 13.9163 15.5104 15.5104C13.9163 17.1045 11.7543 18 9.5 18C7.24566 18 5.08365 17.1045 3.48959 15.5104C1.89553 13.9163 1 11.7543 1 9.5C1 7.24566 1.89553 5.08365 3.48959 3.48959C5.08365 1.89553 7.24566 1 9.5 1C11.7543 1 13.9163 1.89553 15.5104 3.48959C17.1045 5.08365 18 7.24566 18 9.5V9.5Z"
      // eslint-disable-next-line react/prop-types
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
SearchIcon.propTypes = {
  color: PropTypes.string.isRequired
};
const ArrowDownIcon = ({ color }) => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.94409 7.62117L13.6051 2.19101C13.7302 2.07195 13.8295 1.93031 13.8973 1.77424C13.9651 1.61818 14 1.45078 14 1.28171C14 1.11265 13.9651 0.945252 13.8973 0.789187C13.8295 0.633122 13.7302 0.491475 13.6051 0.372418C13.3549 0.133886 13.0165 -4.29892e-08 12.6638 -5.84073e-08C12.3111 -7.38253e-08 11.9727 0.133886 11.7225 0.372417L6.99614 4.90609L2.26976 0.372417C2.0196 0.133886 1.68121 -5.38472e-07 1.32848 -5.5389e-07C0.975759 -5.69308e-07 0.637366 0.133886 0.387212 0.372417C0.26347 0.492086 0.16557 0.634008 0.0991277 0.790045C0.0326852 0.946082 -0.000992824 1.11316 2.28322e-05 1.28171C-0.000992838 1.45026 0.0326852 1.61734 0.0991277 1.77338C0.16557 1.92942 0.26347 2.07134 0.387212 2.19101L6.04819 7.62117C6.17231 7.74121 6.31998 7.83649 6.48268 7.90151C6.64538 7.96653 6.81989 8 6.99614 8C7.17239 8 7.3469 7.96653 7.5096 7.90151C7.6723 7.83649 7.81997 7.74121 7.94409 7.62117Z"
      fill={color}
    />
  </svg>
);
ArrowDownIcon.propTypes = {
  color: PropTypes.string.isRequired
};
const HighlightedOptionsComponent = ({ label }, { inputValue }) => (
  <Highlighter
    searchWords={[inputValue]}
    textToHighlight={label}
    highlightStyle={{
      fontWeight: 400,
      marginRight: 0,
      paddingRight: 0,
      background: "none",
      backgroundColor: "none"
    }}
    unhighlightStyle={{ fontWeight: 500, marginLeft: 0, paddingLeft: 0 }}
  // highlightClassName="customSelect-highlightedText"
  />
);
HighlightedOptionsComponent.propTypes = {
  label: PropTypes.string.isRequired
};

const themeStyles = {
  filter: {
    backgroundColor: "#f9f9f9",
    borderColor: "none",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "11px",
    placeholder: {
      color: "#b1c7ff",
      fontWeight: 400,
      fontSize: ".875rem"
    }
  },
  hireSearchBar: {
    backgroundColor: "#f9f9f9",
    borderColor: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: ".875rem",
    color: "#3c72ff",
    placeholder: {
      color: "#3c72ff",
      fontWeight: 600,
      fontSize: ".875rem"
    }
  },
  hireSearchBar2: {
    backgroundColor: "#f9f9f9",
    borderColor: "none",
    padding: "0",
    color: "#AFAFAF",
    placeholder: {
      color: "#AFAFAF",
      fontWeight: 400,
      fontSize: ".875rem"
    }
  },
  social: {
    backgroundColor: "#f9f9f9",
    borderColor: "1px solid #8FB7FF",
    borderRadius: "9px",
    placeholder: {
      color: "#387eff80",
      fontWeight: 400,
      fontSize: ".875rem"
    }
  },
  socialCustom: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 1px 2px rgba(0, 64, 255, 0.15), inset 0px 1px 6px rgba(0, 64, 255, 0.15)"
  },
  contactCard: {
    backgroundColor: "#EBF4FF",
    color: "#2D9AFF",
    placeholder: {
      color: "#2D9AFF",
      fontWeight: 400,
      fontSize: ".875rem"
    }
  },
  ONBOARDING_HIRER: {
    backgroundColor: "#f9f9f9",
    borderColor: "none",
    fontWeight: 300,
    fontSize: "14px",
    borderRadius: "12px",
    padding: "0.25rem .5rem",
    placeholder: {
      color: "#cccccc",
      fontWeight: 300,
      fontSize: "14px"
    }
  },
  EDIT_HIRER: {
    backgroundColor: "#f9f9f9",
    borderColor: "none",
    fontWeight: 300,
    fontSize: "14px",
    boxShadow: "0px 1px 7px rgba(148, 94, 255, 0.1)",
    borderRadius: "12px",
    padding: "0.25rem .5rem",
    placeholder: {
      color: "#cccccc",
      fontWeight: 300,
      fontSize: "14px"
    }
  },
  ONBOARDING_ERRORED: {
    backgroundColor: "#f9f9f9",
    borderColor: "1px solid #ee1e1e !important",
    fontWeight: 500,
    fontSize: "12px",
    boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
    placeholder: {
      color: "#ee1e1e",
      fontWeight: 500,
      fontSize: "12px"
    }
  },
  DATE_PICKER: {
    backgroundColor: "#f9f9f9",
    borderColor: "1px solid rgba(164,167,181,1)",
    borderRadius: "12rem"
  },
  edit_profile: {
    control: {
      borderRadius: "0",
      backgroundColor: "transparent",
      outline:"none",
      border: "none",
      borderBottom: "1px solid rgba(60, 60, 67, 0.50);!important",
      width: "100%",
    },
    placeholder: {
      color: "rgba(60, 60, 67, 0.70)",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
    }
  },
  ai_interview: {
    borderColor: "border: 1px solid #8255C8",
    borderRadius: "4px",
    color: "#8255C8",
    backgroundColor: "#ffffff",
    fontWeight: "700",
    fontSize: "14px",
    padding: "0.55rem 0.6rem",
    hoverBorder: "2px solid #8255C8",
    placeholder: {
      color: "rgba(34, 34, 34, 0.60)",
      fontWeight: 400,
      fontSize: "14px"
    }
  }, 

  SeekerOnboarding: {
    backgroundColor: "#ffffff",
    borderColor: "1px solid #2A78C233",
    fontSize: "14px",
    padding: "0.55rem 0.6rem",
    borderRadius: "12px",
    placeholder: {
      color: "#3C3C43B2",
      fontWeight: 400,
      fontSize: "14px"
    }
  },
  errorSeekerOnboarding: {
    backgroundColor: "#ffffff",
    borderColor: "1px solid #FF2038B2",
    fontSize: "14px",
    padding: "0.55rem 0.6rem",
    borderRadius: "12px",
    placeholder: {
      color: "#3C3C43B2",
      fontWeight: 400,
      fontSize: "14px"
    }
  },
  OnboardingV2: {
    backgroundColor: "#ffffff",
    borderColor: "1px solid #d1d4d7",
    color: "#000000",
    fontSize: "0.8rem",
    padding: "0.55rem 0.6rem",
    borderRadius: "6px",
    placeholder: {
      color: "#9DA3A8",
      fontWeight: 400,
      fontSize: "0.8rem"
    }
  },
  errorOnboardingV2: {
    backgroundColor: "#F9F9F9",
    borderColor: "1px solid #FF2038B2",
    color: "#000000",
    fontSize: "0.8rem",
    padding: "0.55rem 0.6rem",
    borderRadius: "6px",
    placeholder: {
      color: "#9DA3A8",
      fontWeight: 400,
      fontSize: "0.8rem"
    }
  },

};


function CustomSelectWindowed({
  options,
  placeholder,
  theme,
  value,
  onChange,
  allowNewOptions,
  async,
  readOnly,
  loadOptions,
  windowed,
  hideArrowIcon,
  searchable = true,
  className
}) {
  const customStyles = {
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: themeStyles[theme]?.placeholder?.color || defaultStyles.color,
      fontWeight: themeStyles[theme]?.placeholder?.fontWeight || defaultStyles.fontWeight,
      fontSize: themeStyles[theme]?.placeholder?.fontSize || defaultStyles.fontSize,
      borderRadius: themeStyles[theme]?.placeholder?.borderRadius || defaultStyles.borderRadius
    }),
    control: (base) => {
      const currentThemeStyles = themeStyles[theme] || {}; 
      return {
        ...base,
        ...currentThemeStyles,
        border: currentThemeStyles.borderColor || "1px solid #ccc", 
        boxShadow: currentThemeStyles.boxShadow || "none", 
        "&:hover": {
          border: currentThemeStyles.hoverBorder || '1px solid #3c72ff',
        },
        ...(theme === "edit_profile" ? themeStyles[theme].control : {}),
      };
    },
    singleValue: (base, state) => {
      const currentThemeStyles = themeStyles[theme] || {}; 
      return {
        ...base,
        fontWeight: currentThemeStyles.fontWeight || '400',
        fontSize: currentThemeStyles.fontSize || ".8rem",
        color: currentThemeStyles.color ? currentThemeStyles.color : (theme === "filter" || theme === "hireSearchBar")
            ? "#3c72ff"
            : (theme === "OnboardingV2"||theme === "errorOnboardingV2")
            ? "#000000"
            : theme === "contactCard"
            ? "#2D9AFF"
            : "#4D5359" // Default selected text color
      };
    },
    input: (base, state) => {
      const currentThemeStyles = themeStyles[theme] || {}; 
      return {
        ...base,
        fontWeight: currentThemeStyles.fontWeight || '400',
        fontSize: currentThemeStyles.fontSize || ".8rem",
        color: currentThemeStyles.color ? currentThemeStyles.color : (theme === "filter" || theme === "hireSearchBar")
            ? "#3c72ff"
            : (theme === "OnboardingV2"||theme === "errorOnboardingV2")
            ? "#000000"
            : theme === "contactCard"
            ? "#2D9AFF"
            : "#4D5359"
      }
    },
    option: (base) => ({
      ...base,
      overflow: "hidden",
      whiteSpace: "pre",
      textOverflow: "ellipsis",
      fontSize: theme === "OnboardingV2" || theme === "errorOnboardingV2" ? ".875rem" : "15px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 4,
    })
  };
  const selectRef = useRef(null);
  return (
    <div
      className={`customSelect-container ${`customSelect-container-${theme}`}`}
    >
      {allowNewOptions && !async ? (
        <CreatableSelect
          styles={customStyles}
          className={className}
          components={windowed ? { MenuList, DropdownIndicator } : {}}
          options={options}
          isDisabled={readOnly}
          value={value}
          isSearchable={searchable}
          onChange={onChange}
          placeholder={placeholder}
          classNamePrefix="customSelect"
          filterOption={createFilter({ ignoreAccents: false })}
          theme={(theme_) => ({
            ...theme_,
            borderRadius: theme === "SeekerOnboarding"
              ? "20px"
              : theme === "errorSeekerOnboarding"
                ? "20px"
                : "0px", 
            dropIcon: "arrowDown",
            colors: {
              ...theme_.colors,
              primary25: "#deebff",
              primary: "#6ea7ff",
              iconColor:
                theme === "filter"
                  ? "#b1c7ff"
                  : theme === "social"
                    ? "#387EFF"
                    : theme === "contactCard"
                      ? "#2D9AFF"
                      : "#C4C4C4"
            }
          })}
        />
      ) : async ? (
        allowNewOptions ? (
          <AsyncCreatableSelect
            styles={customStyles}
            components={
              theme === "hireSearchBar" || theme === "hireSearchBar2"
                ? {
                  DropdownIndicator: (props) => (
                    <components.DropdownIndicator {...props}>
                      <div />
                    </components.DropdownIndicator>
                  )
                }
                : {}
            }
            // Control: (props) => (
            //   <>
            //     <components.Control {...props}>
            //       <SearchIcon color="#b1c7ff" />
            //       <components.Input {...props} />
            //     </components.Control>
            //   </>
            // ),

            classNamePrefix={theme === "hireSearchBar" ? "customSelect" : ""}
            placeholder={placeholder}
            isDisabled={readOnly}
            isSearchable={searchable}
            value={value}
            ref={selectRef}
            onChange={(e) => e.value !== "" && onChange(e)}
            onBlur={(e) => e.preventDefault()}
            loadOptions={loadOptions}
            formatOptionLabel={
              theme === "hireSearchBar" ? HighlightedOptionsComponent : null
            }
            defaultOptions={[{ label: "Type to start searching", value: "" }]}
          />
        ) : (
          <AsyncSelect
            styles={customStyles}
            components={
              theme === "hireSearchBar" || theme === "hireSearchBar2"
                ? {
                  DropdownIndicator: (props) => (
                    <components.DropdownIndicator {...props}>
                      <div />
                    </components.DropdownIndicator>
                  )
                }
                : theme === "social"
                  ? { MenuList, DropdownIndicator }
                  : {}
            }
            classNamePrefix={
              theme === "hireSearchBar" || theme === "hireSearchBar2"
                ? "customSelect"
                : ""
            }
            placeholder={placeholder}
            isSearchable={searchable}
            theme={(theme_) => ({
              ...theme_,
              borderRadius: theme === "SeekerOnboarding"
                ? "20px" 
                : theme === "errorSeekerOnboarding"
                ? "20px" 
                : "0px", 
              dropIcon: "arrowDown",
              colors: {
                ...theme_.colors,
                primary25: "#deebff",
                primary: "#6ea7ff",
                iconColor:
                  theme === "filter"
                    ? "#b1c7ff"
                    : theme === "social"
                      ? "#387EFF"
                      : theme === "contactCard"
                        ? "#2D9AFF"
                        : "#C4C4C4"
              }
            })}
            isDisabled={readOnly}
            value={value}
            ref={selectRef}
            onChange={(e) => e.value !== "" && onChange(e)}
            onBlur={(e) => e.preventDefault()}
            loadOptions={loadOptions}
            formatOptionLabel={
              theme === "hireSearchBar" ? HighlightedOptionsComponent : null
            }
            defaultOptions={[{ label: "Type to start searching", value: "" }]}
          />
        )
      ) : (
        <ReactSelect
          styles={customStyles}
          isSearchable={searchable}
          components={
            windowed
              ? { MenuList, DropdownIndicator }
              : hideArrowIcon
                ? {
                  DropdownIndicator: (props) => (
                    <components.DropdownIndicator {...props}>
                      <div />
                    </components.DropdownIndicator>
                  )
                }
                : {}
          }
          options={options}
          isDisabled={readOnly}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          classNamePrefix="customSelect"
          filterOption={createFilter({ ignoreAccents: false })}
          theme={(theme_) => ({
            ...theme_,
            dropIcon: "arrowDown",
            borderRadius: theme === "SeekerOnboarding"
              ? "20px"
              : theme === "errorSeekerOnboarding"
                ? "20px"
                : "0px", 
            colors: {
              ...theme_.colors,
              primary25: "#deebff",
              primary: "#6ea7ff",
              iconColor:
                theme === "filter"
                  ? "#b1c7ff"
                  : theme === "social"
                    ? "#387EFF"
                    : theme === "contactCard"
                      ? "#2D9AFF"
                      : "#C4C4C4"
            }
          })}
        />
      )}
    </div>
  );
}

export default function CustomSelectWrapper({
  type,
  searchType,
  placeholder,
  theme,
  options,
  value,
  onChange,
  allowNewOptions,
  async,
  readOnly,
  windowed,
  dataServer,
  hideArrowIcon,
  searchable = true,
  className,
}) {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const getUrl = (typeArg, searchTypeArg = "config", val) => {
    switch (searchTypeArg) {
      case "config":
        return `config/searchConfig?config=${typeArg}&search=${"%"}`;
      case "config_async":
        return `config/searchConfig?config=${typeArg}&search=${val}`;
      case "contact":
        return `/contact/search?query=${`${val}%` || "%"}`;
      case "organisation":
        return `/config/organisation?search=${val}`;
      case "company":
        return `/config/organisation?search=${val}&edu=0`;
      case "school":
        return `/config/organisation?search=${val || ""}&edu=1`;
      case "degree":
        return `config/searchConfig?config=degree&search=${val || ""}`;
      case "field":
        return `config/searchConfig?config=degree_field&search=${val}`;
      case "interest":
        return `config/search_config?config=interest${val ? `&search=${val}` : ""
          }`;
      default:
        return "";
    }
  };

  const getData = (val = null) => {
    if (async) return;
    setLoading(true);
    if (options && options.length) {
      setList(options);
      setLoading(false);
    } else if (type || searchType) {
      const url = getUrl(type, searchType, val);
      fetchSpecificConfigApi(url, dataServer).then((res) => {
        if (searchType === "school") {
          setList(
            res.data.data.map((a) => ({
              ...a,
              value: a.organisation_id,
              label: a.organisation
            }))
          );
        } else
          setList(res.data.data.map((a) => ({ value: a.id, label: a.value })));
        setLoading(false);
      });
    }
  };
  const getPredictionFromTables = async (val) => {
    const url = getUrl(type, searchType || "config_async", val);
    const res = await fetchSpecificConfigApi(url);
    return res.data.data;
  };
  const fetchPredictionsFromTables = async (val) => {
    const predictions = await getPredictionFromTables(val);
    const data = predictions.map((a) => ({
      ...a,
      value: a.id || a.organisation_id,
      label: a.label || a.value || a.organisation
    }));
    return data;
  };
  const fetchPredictions = async (val) => {
    const predictions = await getPlacesPrediction(val);
    const data = predictions.map((a) => ({
      label: a.place_name,
      value: a.place_name,
      id: a.id,
      longitude: a.geometry.coordinates[0],
      latitude: a.geometry.coordinates[1]
    }));
    return data;
  };
  const loadOptions_ = async (inputValue) => {
    if (type === "location") {
      return fetchPredictions(inputValue);
    }
    if (searchType === "contact") {
      const url = getUrl(type, searchType, inputValue);
      const res = await fetchSpecificConfigApi(url, dataServer);
      return res.data.data.map((a) => ({
        value: a.contact_user_id,
        label: a.contact_name
      }));
    }
    return fetchPredictionsFromTables(inputValue);
  };
  const loadOptions = debounce(loadOptions_, 500, { leading: true });
  useEffect(() => {
    getData();
  }, [options]);
  return (
    <CustomSelectWindowed
      options={list}
      value={value}
      onChange={onChange}
      theme={theme}
      placeholder={placeholder}
      filterOption={createFilter({ ignoreAccents: false })}
      allowNewOptions={allowNewOptions}
      async={async}
      readOnly={readOnly}
      windowed={windowed}
      loadOptions={async ? loadOptions : () => { }}
      hideArrowIcon={hideArrowIcon}
      searchable={searchable}
      className={className}
    />
  );
}

CustomSelectWindowed.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  allowNewOptions: PropTypes.bool.isRequired,
  async: PropTypes.bool.isRequired,
  loadOptions: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  windowed: PropTypes.bool.isRequired
};
CustomSelectWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  searchType: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  theme: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
  allowNewOptions: PropTypes.bool,
  dataServer: PropTypes.string,
  async: PropTypes.bool,
  readOnly: PropTypes.bool,
  windowed: PropTypes.bool
};
CustomSelectWrapper.defaultProps = {
  theme: "default",
  options: [],
  value: null,
  onChange: () => { },
  allowNewOptions: false,
  async: false,
  windowed: true,
  readOnly: false,
  dataServer: "GENERAL"
};
