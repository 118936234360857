import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { motion, useMotionValue, animate } from 'framer-motion';
import styles from './styles.module.scss'; // Ensure you have corresponding styles

const Carasoul = ({ children, onActiveIndexChange }) => {
  const carouselRef = useRef(null);
  const innerCarouselRef = useRef(null);
  const x = useMotionValue(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const totalCards = children.length;

  // Determine if the device is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 750);
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Measure carousel and card widths using useLayoutEffect for synchronous measurements
  useLayoutEffect(() => {
    const measureWidths = () => {
      if (carouselRef.current && innerCarouselRef.current) {
        const carouselRect = carouselRef.current.getBoundingClientRect();
        const firstCard = innerCarouselRef.current.querySelector('.item');
        if (firstCard) {
          const cardRect = firstCard.getBoundingClientRect();
          setCardWidth(cardRect.width + 20); // 20 is the margin
          setCarouselWidth(carouselRect.width);
          console.log('Measured cardWidth:', cardRect.width + 20);
          console.log('Measured carouselWidth:', carouselRect.width);
        }
      }
    };

    measureWidths();

    // Re-measure on window resize to handle dynamic changes
    window.addEventListener('resize', measureWidths);
    return () => window.removeEventListener('resize', measureWidths);
  }, [children, isMobile]);

  // Handle drag end to snap to the nearest card
  const handleDragEnd = (event, info) => {
    if (!carouselRef.current) return;

    const currentX = x.get();
    const velocity = info.velocity.x;

    // Calculate the current index based on x position
    let newIndex = Math.round((-currentX + carouselWidth / 2 - cardWidth / 2) / cardWidth);

    // Adjust index based on drag velocity
    if (velocity < -500) {
      // Swiped left quickly, go to next card
      newIndex += 1;
    } else if (velocity > 500) {
      // Swiped right quickly, go to previous card
      newIndex -= 1;
    }

    // Clamp the index within bounds
    newIndex = Math.max(0, Math.min(newIndex, totalCards - 1));

    // Calculate the target x position
    const targetX = -(newIndex * cardWidth) + (carouselWidth / 2) - (cardWidth / 2);

    console.log('Dragging ended. New index:', newIndex, 'Target X:', targetX);

    // Animate to the target position
    animate(x, targetX, { type: 'spring', stiffness: 300, damping: 30 });

    // Notify parent about the active index
    if (onActiveIndexChange) {
      onActiveIndexChange(newIndex);
    }
  };

  // Update active index on initial render
//   useEffect(() => {
//     if (onActiveIndexChange && isMobile) {
//       const initialIndex = 0;
//       onActiveIndexChange(initialIndex);
//     }
//   }, [onActiveIndexChange, isMobile]);

  // Calculate drag constraints only when measurements are available
  const dragConstraints = isMobile
    ? {
        right: 0,
        left: -(cardWidth * totalCards - carouselWidth),
      }
    : {};

  // Prevent rendering carousel on non-mobile devices
  if (!isMobile) return children;

  return (
    <motion.div ref={carouselRef} className={styles.carousel}>
      <motion.div
        ref={innerCarouselRef}
        className={styles.innerCarousel}
        drag="x"
        dragConstraints={dragConstraints}
        style={{ x }}
        whileTap={{ cursor: 'grabbing' }}
        onDragEnd={handleDragEnd}
      >
        {children.map((child, index) => (
            <div className={`item ${styles.item}`} key={index}>
                {child}
            </div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Carasoul;
