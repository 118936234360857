import React from "react";
import Navbar from '@/dashboards/Navbar';
import { useDispatch } from "react-redux";
import { logout as logoutAction } from "@/redux/actions/userActions";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles from "../styles/AiInterviewDemoPage.module.scss";

export default function Layout({ children }) {

  const dispatch = useDispatch();
  const history = useHistory();

  const logOut = () => {
    dispatch(logoutAction());
    window.localStorage.removeItem("userFeedbackReminder");
    window.localStorage.removeItem("token");
    window.sessionStorage.clear();
    history.push("/ai-interview");
  };

  return (
    <div className={styles.pageContainer}>
      <Navbar hideEverything text="AI Interview" logoAction={logOut} linkStyle="ai-interview" />
      {children}
    </div>
  );
}

const AiInterviewLogo = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="21"
      viewBox="0 0 34 21"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54515 1.89671C9.67075 0.242458 11.9243 -0.186094 13.5785 0.939507L14.291 1.42428C14.5496 1.60025 14.7782 1.80379 14.9757 2.02831C15.1392 2.18406 15.2904 2.35742 15.4265 2.54791L23.6101 13.9977C24.7735 15.6255 24.3971 17.8883 22.7693 19.0518L22.0721 19.5501C20.4442 20.7136 18.1814 20.3371 17.0179 18.7093L12.5377 12.4408C12.1995 11.9676 11.4935 11.9758 11.1663 12.4566L7.331 18.0932C6.2054 19.7474 3.95188 20.176 2.29763 19.0504L1.58519 18.5656C-0.0690629 17.44 -0.497619 15.1865 0.627983 13.5322L8.54515 1.89671ZM29.1491 0.325256C26.925 0.325256 25.1221 2.12821 25.1221 4.35226V16.1964C25.1221 18.4205 26.925 20.2234 29.1491 20.2234C31.3731 20.2234 33.1761 18.4205 33.1761 16.1964V4.35226C33.1761 2.12821 31.3731 0.325256 29.1491 0.325256Z"
        fill="url(#paint0_linear_6654_3057)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6654_3057"
          x1="29.1491"
          y1="0.325256"
          x2="28.499"
          y2="23.5001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C1EE9" />
          <stop offset="1" stopColor="#AC54C3" />
        </linearGradient>
      </defs>
    </svg>
  );
  