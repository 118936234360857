import React from 'react';
import styles from './styles.module.scss';
import AiInterviewBuilderSVG from './assets/AIInterviewBuilder.svg';

const AiSuggestedComp = ({ title, icon, itemArr, handleAdd, variant }) => (
    <div className={`${styles.AiSuggestedSkills} ${variant === "AiInterview" && styles.AiInterviewPageStyle}`}>
        <h3><img src={icon || AiInterviewBuilderSVG} alt='' />{title}</h3>
        <div className={styles.AiRecommendedSkillGrp}>
            {itemArr.map((skill) => (
                <div key={skill.id} onClick={() => handleAdd(skill)}>
                    <div><AddIcon color={variant === "AiInterview" ? "#8255C8" : "#2369AC"} /></div>
                    {skill.value.length > 20 ? `${skill.value.slice(0, 20)}...` : skill.value}
                </div>
            ))}
        </div>
    </div>
)

export default AiSuggestedComp;


const AddIcon = ({ color = "#2369AC" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M6.50003 1.75736V10.2426M2.25739 6H10.7427" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)