import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  formatIncompletePhoneNumber,
  validatePhoneNumberLength,
  parseIncompletePhoneNumber
} from "libphonenumber-js";
import countries2 from "@/utils/countries";
import { BsCheck2 } from "react-icons/bs";
import CustomSelect from "../CustomSelect/CustomSelect";
import "./PhoneNumberInput.scss";

export default function PhoneNumberInput({
  countryCode,
  onChangeCountryCode,
  phoneNumber,
  onChangePhoneNumber,
  isoCode,
  widthFull,
  width,
  login,
  countryCodeDropdown,
  type = 'DEFAULT',
  invalidNumber,
}) {
  const countries = countries2.map((a) => ({
    name: a.name,
    dialCode: a.dialCode,
    isoCode: a.isoCode
  }));
  const options = countries.map((a) => ({
    value: `${a.name} (${a.dialCode})`,
    id: a.isoCode,
    dialCode: a.dialCode
  }));

  const allowedCountryCodes = [
    { label: "India", value: "+91", flag: "https://cdn.kcak11.com/CountryFlags/countries/in.svg", initials: "IN" },
    { label: "United Arab Emirates", value: "+971", flag: "https://cdn.kcak11.com/CountryFlags/countries/ae.svg", initials: "UAE" },
    { label: "Australia", value: "+61", flag: "https://cdn.kcak11.com/CountryFlags/countries/au.svg", initials: "AU" },
    { label: "Saudi Arabia", value: "+966", flag: "https://cdn.kcak11.com/CountryFlags/countries/sa.svg", initials: "SA" },
    { label: "Singapore", value: "+65", flag: "https://cdn.kcak11.com/CountryFlags/countries/sg.svg", initials: "SG" },
    { label: "United States", value: "+1", flag: "https://cdn.kcak11.com/CountryFlags/countries/us.svg", initials: "US" },
    { label: "Spain", value: "+34", flag: "https://cdn.kcak11.com/CountryFlags/countries/us.svg", initials: "ES" }
  ];

  const [selectedCountry, setSelectedCountry] = useState(allowedCountryCodes[0]);
  const [isListVisible, setIsListVisible] = useState(false)
  const handleCountryCodeChange = (value) => {
    const country = countries2.find((item) => item.dialCode === value);
    setSelectedCountry({
      label: country.name,
      value: country.dialCode,
      flag: country.flag,
      initials: country.isoCode
    });
    setIsListVisible((item) => !item)
    if (onChangeCountryCode) {
      onChangeCountryCode({ dialCode: country.dialCode, isoCode: country.isoCode });
    }
  }


  return (
    <div className={`phoneInput__container ${invalidNumber ? 'phoneInput__invalidNumber' : ''}`}>
      {countryCodeDropdown ? (
        <div className="phoneInput__countryCode-container">
          <CustomSelect
            options={options}
            searchable
            variant={5}
            onChange={onChangeCountryCode}
            selectedValue={countryCode}
            type="country_code"

          />
        </div>
      ) : (
        <div className="phoneInput__countryCode-containersm">
          <div className={type === "ROUNDED" ? "selectCode_rounded" : "selectCode"} onClick={() => setIsListVisible((item) => !item)}>
            {/* <img src={selectedCountry.flag} alt="" className="flag" /> */}
            {selectedCountry.initials} &nbsp;
            {selectedCountry.value}
          </div>
          {isListVisible && <ul className="selectUl">
            {allowedCountryCodes.map(code => (
              <li
                key={code.value}
                value={code.value}
                className={`selectOptions ${selectedCountry.value === code.value ? 'blueFont' : ''}`}
                onClick={() => handleCountryCodeChange(code.value)}
              >
                {code.label}
                {selectedCountry.value === code.value && <BsCheck2 className="blueCheck" />}
              </li>
            ))}
          </ul>}
        </div>
      )}
      <div
        className="phoneInput__phoneInp-container"
        style={{
          ...(widthFull ? { width: "100%" } : {}),
          ...(width ? { minWidth: width } : {})
        }}
      >
        <input
          type="text"
          className={type === "ROUNDED" ? "roundedInput_for_Num" : "phoneInput__phoneInp"}
          placeholder="Enter your contact number"
          inputMode="numeric"
          pattern="[0-9]*"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              const lengthCheck = validatePhoneNumberLength(
                formatIncompletePhoneNumber(phoneNumber, isoCode),
                isoCode
              );
              if (!lengthCheck) login(e);
            }
          }}
          value={formatIncompletePhoneNumber(
            countryCode + phoneNumber
          ).substring(countryCode.length + 1)}
          onChange={(e) => {
            const lengthCheck = validatePhoneNumberLength(
              formatIncompletePhoneNumber(phoneNumber, isoCode),
              isoCode
            );
            if (
              lengthCheck !== "TOO_LONG" ||
              e.target.value.length < phoneNumber.length
            )
              onChangePhoneNumber(parseIncompletePhoneNumber(e.target.value));
          }}
        />
      </div>
    </div>
  );
}

PhoneNumberInput.propTypes = {
  countryCode: PropTypes.string,
  isoCode: PropTypes.string,
  onChangeCountryCode: PropTypes.func,
  phoneNumber: PropTypes.string,
  onChangePhoneNumber: PropTypes.func,
  login: PropTypes.func,
  widthFull: PropTypes.bool,
  width: PropTypes.string
};
PhoneNumberInput.defaultProps = {
  countryCode: "+91",
  isoCode: "IN",
  onChangeCountryCode: (a) => console.log(a),
  phoneNumber: "",
  onChangePhoneNumber: (a) => console.log(a),
  login: (a) => console.log(a),
  widthFull: false,
};
