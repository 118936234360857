/* eslint-disable no-unused-vars */
import { useQuery } from "react-query";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { DatePicker } from "react-rainbow-components";
import isUrl from "is-url";
import ToggleNavbar from "@/dashboards/Navbar/ToggleNavbar/ToggleNavbar";
import DashboardCounts from "@/dashboards/HirerDashboardV2/components/DashboardCounts/DashboardCounts";
import { isEmail } from "@/utils/validationFunctions";
import Navbar from "@/dashboards/Navbar";
import { updatePfp,updateUserDetails } from "@/redux/actions/userActions";
import LoaderLg from "@/components/LoaderLg"; 
import Breadcrumbs from "@/components/Breadcrumbs";
import CustomSelectWrapper from "@/components/CustomSelectV2/CustomSelect";
import { addUserDefinedValueToMasterDBApi } from "@/services/utilitiesService";
import { showToastMessage } from "@/components/ToastComponent";
import { updateProfilePicApi } from "@/services/userProfileService";
import { mainAxiosInstance as Axios } from "@/services/networkAdapters/axiosInstance";
import { CustomInputText, CustomFormSelectWrapper } from "@/components/CustomFormComponents";
import styles from "./EditHirerProfilePage.module.scss";

const date = new Date();
const maxDate = new Date(date.setFullYear(date.getFullYear() - 18));
let globalValidProgress;

const breadcrumbData = [
  { text: 'Dashboard', url: '/' },
  { text: 'My Profile' },
];

const getHirerProfile = async () => {
  const res = await Axios.get("profile/hirer");
  return res.data.data;
};

export default function EditHirerProfilePage() {
  const [name, setName] = useState("");
  const [locationData, setLocationData] = useState("");
  const [email, setEmail] = useState("");
  // const [candidatePreferenceSkills, setCandidatePreferenceSkills] = useState(
  //   []
  // );
  // const [requiredResourcesId, setRequiredResourcesId] = useState([]);

  const [image, setImage] = useState(null);
  const [hiringForSelf, setHiringForSelf] = useState(false);
  const [organisationData, setOrganisationData] = useState(null);
  const [industryData, setIndustryData] = useState(null);
  const [designationData, setDesignationData] = useState(null);
  const [website, setWebsite] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [annualTurnoverOptions, setAnnualTurnoverOptions] = useState([]);
  const [companySizeOptions, setCompanySizeOptions] = useState([]);
  const [description, setDescription] = useState("");
  const [companyGst, setCompanyGst] = useState(null);
  const [companySizeData, setCompanySizeData] = useState(null);
  const [annualTurnoverData, setAnnualTurnoverData] = useState(null);
  const [address, setAddress] = useState(null);
  const staticConfig = useSelector((state) => state.staticConfig);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const fileUploader = useRef(null);
  
  const chooseImage = () => {
    fileUploader.current.click();
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const attachImage = async (evt) => {
    const file = evt.target.files[0];
    if (file) {
      const fileSize = file.size / 1048576;
      if (fileSize > 5) {
        showToastMessage("error", "Image file should be smaller than 5Mb.");
        return;
      }
      fileUploader.current.value = null;
      const dataUrl = await toBase64(file);
      setImage({ img: file, imgLink: dataUrl });

      const data_ = new FormData();
      data_.append("file", file);
      const res = await updateProfilePicApi(data_);
      if (res.status === 200) {
        dispatch(updatePfp(dataUrl));
        showToastMessage("info", "Profile picture updated successfully.");
      } else console.log("something went wrong");
    }
  };

  const loadOptions = () => {
    setAnnualTurnoverOptions(
      staticConfig.annualTurnover.map((item) => ({
        label: item.value,
        value: item.id
      }))
    );
    setCompanySizeOptions(
      ["<10", "10 - 50", "50 - 200", "200 - 1000", "1000 - 5000", ">5000"].map(
        (a) => ({ label: a, value: a })
      )
    );
  };

  useEffect(() => {
    if (staticConfig.loaded) loadOptions();
  }, [staticConfig.loaded]);

  const { data, isLoading } = useQuery(
    "hirerProfile",
    getHirerProfile,
    {
      initialData: user.hiring?.data,
      enabled: !user.hiring?.data?.organisation_id,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!user.hiring?.data?.organisation_id) {
          const updatedData = {
            userSavedMode: "HIRING",
            location_city: data.user.location_city,
            isProfileVerified: data.user.isProfileVerified,
            referralCode: data.user.referralCode,
            gcoins: 0,
            profileStrength: data.user.profileStrength,
            company_gst: data.userHiringOrganisation.company_gst,
            organisation_id: data.userHiringOrganisation.organisation_id,
            organisation: data.userHiringOrganisation.organisation,
            designation: data.userHiringOrganisation.designation,
            designation_id: data.userHiringOrganisation.designation_id,
            industry_type: data.userHiringOrganisation.industry_type,
            industry_type_id: data.userHiringOrganisation.industry_type_id,
            annual_turnover: data.userHiringOrganisation.annual_turnover,
            annual_turnover_id: data.userHiringOrganisation.annual_turnover_id,
            website: data.userHiringOrganisation.website,
            address: data.userHiringOrganisation.address,
            company_size: data.userHiringOrganisation.company_size,
            linkedin_url: data.userHiringOrganisation.linkedin_url,
            description: data.userHiringOrganisation.description
          };
          dispatch(updateUserDetails( updatedData,"HIRING"));
        }
      }
    }
  );

  useEffect(() => {
    if (data?.user) {
      setName(data.user.name);
      setLocationData({
        label: data.user.location_city,
        value: data.user.location_city,
        latitude: null,
        longitude: null
      });
      setEmail(data.user.email_id);
    }

    if (data?.userHiringOrganisation) {
      setHiringForSelf(false);
      setOrganisationData(
        hiringForSelf
          ? null
          : {
            label: data.userHiringOrganisation.organisation,
            value: data.userHiringOrganisation.organisation_id
          }
      );
      setDesignationData(
        hiringForSelf
          ? null
          : {
            label: data.userHiringOrganisation.designation,
            value: data.userHiringOrganisation.designation_id
          }
      );
      setIndustryData(
        hiringForSelf
          ? null
          : {
            label: data.userHiringOrganisation.industry_type,
            value: data.userHiringOrganisation.industry_type_id
          }
      );
      setWebsite(hiringForSelf ? "" : data.userHiringOrganisation.website);
      setLinkedin(data.userHiringOrganisation.linkedin_url || "");
      setCompanyGst(data.userHiringOrganisation.company_gst);
      setDescription(
        hiringForSelf && data.userHiringOrganisation.description
          ? data.userHiringOrganisation.description
          : ""
      );
      setAddress(
        hiringForSelf || !data.userHiringOrganisation.address
          ? null
          : {
            label: data.userHiringOrganisation.address,
            value: data.userHiringOrganisation.address
          }
      );
      setCompanySizeData(
        hiringForSelf || !data.userHiringOrganisation.company_size
          ? null
          : {
            label: data.userHiringOrganisation.company_size,
            value: data.userHiringOrganisation.company_size
          }
      );
      setAnnualTurnoverData(
        hiringForSelf || !data.userHiringOrganisation.annual_turnover_id
          ? null
          : {
            label: data.userHiringOrganisation.annual_turnover,
            value: data.userHiringOrganisation.annual_turnover_id
          }
      );
    }
  }, [data]);

  const isLinkedinLink = (link) => link.includes("linkedin.com");

  // State to keep track of edit mode
  const [isEditable, setIsEditable] = useState(false);

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setIsEditable(!isEditable);
  };

  const saveData = () => {
    if (!name || name.length < 2 || name.length > 30) {
      showToastMessage(
        "error",
        "Please enter a valid name. Length should be between 2 and 30."
      );
      return;
    }
    if (!/^[a-zA-Z ]*$/.test(name)) {
      showToastMessage("error", "Only alphabets are allowed in name.");
      return;
    }
    if (!isEmail(email)) {
      showToastMessage("error", "Please enter a valid Email Id.");
      return;
    }
    if (!hiringForSelf) {
      if (!organisationData) {
        showToastMessage("Company name cannot be empty.", "error");
        return;
      }
      if (!designationData) {
        showToastMessage("Designation cannot be empty.", "error");
        return;
      }
      if (!industryData) {
        showToastMessage("Industry cannot be empty.", "error");
        return;
      }
      if (website) {
        const url =
          !website.includes("https://") && !website.includes("http://")
            ? `https://${website}`
            : website;
        if (!isUrl(url)) {
          showToastMessage("Website is invalid.", "error");
          return;
        }
      }
      if (linkedin && !isLinkedinLink(linkedin)) {
        showToastMessage("Linkedin url is invalid.", "error");
        return;
      }
      if (!address) {
        showToastMessage("Company Location cannot be empty.", "error");
        return;
      }
    } else if (!linkedin || !isLinkedinLink(linkedin)) {
      showToastMessage("Linkedin url is invalid.", "error");
      return;
    }
    const data2 = {
      basicDetails: {
        name,
        location: locationData?.value,
        email_id: email,
        latitude: locationData.latitude || null,
        longitude: locationData.longitude || null
      },
      userHiringOrganisation: {
        hiringForSelf,
        organisationId:
          organisationData && !organisationData.__isNew__
            ? organisationData.value
            : undefined,
        organisation:
          organisationData && organisationData.__isNew__
            ? organisationData.value
            : undefined,
        designationId:
          designationData && !designationData.__isNew__
            ? designationData.value
            : undefined,
        designation:
          designationData && designationData.__isNew__
            ? designationData.value
            : undefined,
        industryTypeId:
          industryData && !industryData.__isNew__
            ? industryData.value
            : undefined,
        industryType:
          industryData && industryData.__isNew__
            ? industryData.value
            : undefined,
        website,
        linkedin,
        description,
        companyGst,
        annualTurnoverId: !hiringForSelf ? annualTurnoverData?.value : null,
        address: !hiringForSelf ? address?.value : null,
        companySize: !hiringForSelf ? companySizeData?.value : null
      }
    };
    Axios.put("/profile/hirer", data2)
      .then((res) => {
        if (res.status === 200) {
          window.fbq("trackCustom", "Edit Hirer Profile", {
            userId: user.user_id
          });
          showToastMessage("info", "Profile edited successfully.");
          setIsEditable(false);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          showToastMessage("error", err.response.data.message);
          setIsEditable(false);
        }
      });
  };

  // sideNav banner
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  if (isLoading) return <LoaderLg />;

  return (
    <div className={styles.pageContainer}>
      <Navbar
        text="My Profile"
        additionalStyles={{
          backgroundColor: "#fff"
          // boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.08)"
        }}
        showNewUserModeSwitch
        toggleSidebar={toggleSidebar}
      />

      <div className={styles.container}>

        <ToggleNavbar isExpanded={isExpanded} />

        <div className={styles.left}>
          <Breadcrumbs items={breadcrumbData} />

          {/* {globalValidProgress !== 100 &&
            <div className={styles.topBanner}>
              <div className={styles.title}> Earn ₹100 when you complete your profile </div>
              <div className={styles.percentage}> {globalValidProgress} %</div>
              <div className={styles.bar}>
                <ProgressBar subtitle="" />
              </div>
              <div className={styles.completeProfile}>Complete profile</div>
              <div className={styles.LeftSvg}><LeftSvg /></div>
              <div className={styles.RightSvg}><RightSvg /></div>
            </div>
          } */}

          <div className={styles.basicDetails}>

            <div className={styles.mobileHeader}>

              <div className={styles.header}>
                <div className={styles.title}>Basic Information</div>
                <div className={`${styles.editBtn} ${isEditable ? styles.editBtnalt : ''}`}
                  onClick={() => setIsEditable(prevMode => {
                    if (prevMode) {
                      // If currently in edit mode, save changes
                      saveData();
                    }
                    // Toggle the edit mode state
                    return !prevMode;
                  })}>{isEditable ? 'Save Changes' : 'Edit Profile'}
                </div>
              </div>

              <div className={styles.pfpContainer}>
                <div style={{ position: 'relative' }}>

                  <img src={image ? image.imgLink : data?.user?.image_id} alt="" />
                  <div className={`${styles.camIcon} ${!isEditable ? styles.camIconDis : ''}`} onClick={chooseImage}>
                    <CameraIcon />
                  </div>
                </div>
                <div className={styles.userInfo}>
                  <div className={styles.name}>{data?.user?.name}</div>
                  <div className={styles.designation}>{data?.userHiringOrganisation?.designation}</div>
                </div>
              </div>

            </div>

            <div className={`${styles.basicDetailsform} ${!isEditable ? styles.nonInteractive : ''}`}>

              <div className={styles.formsection}>

                <CustomInputText
                  title="Your Name "
                  placeholder="Your full name"
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />

                <CustomFormSelectWrapper
                  title="Your Designation"
                  type="designation"
                  async
                  allowNewOptions
                  theme="OnboardingV2"
                  onChange={(e) => {
                    if (e.__isNew__) {
                      showToastMessage(
                        "info",
                        "Designation added successfully."
                      );
                    }
                    setDesignationData(e);
                  }}
                  value={designationData}
                  placeholder="Enter your Designation"
                />

              </div>

              <div className={styles.formsection}>

                <CustomInputText
                  title="Email adress"
                  placeholder="Your Email adress"
                  name="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

              </div>

            </div>

          </div>

          <div className={`${styles.companyDetailsform} ${!isEditable ? styles.nonInteractive : ''}`} >
            <div className={styles.header}>
              Company Information
            </div>

            <div className={styles.formsection}>

              <CustomFormSelectWrapper
                title="Company Name"
                type="organisation"
                async
                allowNewOptions
                theme="OnboardingV2"
                onChange={(e) => {
                  if (e.__isNew__) {
                    showToastMessage("info", "Company added successfully.");
                  }
                  setOrganisationData(e);
                }}
                value={organisationData}
                placeholder="Eg ABC Pvt Ltd"
              />

            </div>

            <div className={styles.formsection}>

              <CustomFormSelectWrapper
                title="Location"
                type="location"
                async
                allowNewOptions
                theme="OnboardingV2"
                onChange={setAddress}
                value={address}
                placeholder="Select your company’s location"
              />

              <CustomFormSelectWrapper
                title="Industry"
                type="industry_type"
                async
                // allowNewOptions
                theme="OnboardingV2"
                onChange={(e) => {
                  if (e.__isNew__) {
                    showToastMessage("info", "Industry added successfully.");
                  }
                  setIndustryData(e);
                }}
                value={industryData}
                placeholder="Select your company’s industry"
              />

            </div>

            <div className={styles.formsection}>

              <CustomInputText
                title="Website"
                placeholder="Enter  your company’s website link"
                name="Website"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />

              <CustomInputText
                title="Company’s Linkedin URL"
                placeholder="Enter your company’s LinkedIn URL"
                name="LinkedIn"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
              />

            </div>

          </div>

        </div>
      </div>{" "}

      <div className={styles.mobileBtnContainer}>
        <div className={`${styles.editBtn} ${isEditable ? styles.editBtnalt : ''}`}
          onClick={() => setIsEditable(prevMode => {
            if (prevMode) {
              // If currently in edit mode, save changes
              saveData();
            }
            // Toggle the edit mode state
            return !prevMode;
          })}>{isEditable ? 'Save Changes' : 'Edit Profile'}
        </div>
      </div>

      <input
        type="file"
        style={{
          position: "absolute",
          opacity: 0,
          zIndex: "-222",
          maxWidth: "0"
        }}
        id="file"
        ref={fileUploader}
        name=""
        accept=".png,.jpeg,.jpg"
        onChange={attachImage}
      />
    </div>
  );
}

export const ProgressBar = ({ showProgress, subtitle = "Earn ₹100 when you complete your profile" }) => {
  const getHirerProfile = async () => {
    const res = await Axios.get("profile/hirer");
    return res.data.data;
  };
  const { data, isLoading } = useQuery("hirerProfile", getHirerProfile, {
    // placeholderData: {},
    refetchOnWindowFocus: false
  });

   const validProgress = data?.user?.profileStrength;
  // console.log(validProgress)

  globalValidProgress = data?.user?.profileStrength;
  return (
    <>
      {validProgress !== 100 &&
        <div className={styles.progressBar}>
          {showProgress &&
            <div className={styles.percentage}>{validProgress}%</div>
          }
          <div className={styles.bar} style={{ width: '100%' }}>
            <div className={styles.fillBar} style={{ width: `${validProgress}%` }} />
          </div>
          <div className={styles.completeProfile}>{subtitle}</div>
        </div>
      }
    </>
  );
};

const CameraIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#389fff" />
    <path
      d="M23.4286 22.6666H8.57143C8.41988 22.6666 8.27453 22.6028 8.16737 22.4891C8.0602 22.3755 8 22.2213 8 22.0606V11.7576C8 11.5968 8.0602 11.4427 8.16737 11.329C8.27453 11.2153 8.41988 11.1515 8.57143 11.1515H12.2629L13.24 9.60604C13.2918 9.52267 13.3621 9.45415 13.4449 9.40657C13.5276 9.35899 13.6202 9.33383 13.7143 9.33331H18.2857C18.3798 9.33383 18.4724 9.35899 18.5551 9.40657C18.6379 9.45415 18.7082 9.52267 18.76 9.60604L19.7371 11.1515H23.4286C23.5801 11.1515 23.7255 11.2153 23.8326 11.329C23.9398 11.4427 24 11.5968 24 11.7576V22.0606C24 22.2213 23.9398 22.3755 23.8326 22.4891C23.7255 22.6028 23.5801 22.6666 23.4286 22.6666ZM9.14286 21.4545H22.8571V12.3636H19.4286C19.3345 12.3631 19.2419 12.3379 19.1592 12.2904C19.0764 12.2428 19.0061 12.1743 18.9543 12.0909L17.9771 10.5454H14.0229L13.0457 12.0909C12.9939 12.1743 12.9236 12.2428 12.8408 12.2904C12.7581 12.3379 12.6655 12.3631 12.5714 12.3636H9.14286V21.4545Z"
      fill="white"
    />
    <path
      d="M15.9989 20.2432C15.3208 20.2432 14.6579 20.0299 14.0941 19.6303C13.5302 19.2308 13.0908 18.6629 12.8313 17.9984C12.5718 17.3339 12.5039 16.6028 12.6362 15.8974C12.7685 15.192 13.095 14.5441 13.5745 14.0355C14.054 13.527 14.6649 13.1806 15.33 13.0403C15.9951 12.9 16.6845 12.972 17.3109 13.2473C17.9374 13.5225 18.4729 13.9886 18.8496 14.5866C19.2264 15.1846 19.4275 15.8876 19.4275 16.6068C19.4275 17.5712 19.0662 18.4962 18.4233 19.1781C17.7803 19.8601 16.9082 20.2432 15.9989 20.2432ZM15.9989 14.1826C15.5468 14.1826 15.1049 14.3248 14.729 14.5911C14.3531 14.8575 14.0602 15.2361 13.8872 15.6791C13.7142 16.1221 13.6689 16.6095 13.7571 17.0798C13.8453 17.55 14.063 17.982 14.3826 18.321C14.7023 18.6601 15.1096 18.8909 15.553 18.9845C15.9964 19.078 16.4559 19.03 16.8736 18.8465C17.2913 18.663 17.6482 18.3523 17.8994 17.9537C18.1505 17.555 18.2846 17.0863 18.2846 16.6068C18.2846 15.9639 18.0438 15.3473 17.6151 14.8926C17.1865 14.438 16.6051 14.1826 15.9989 14.1826Z"
      fill="white"
    />
  </svg>
);


const LeftSvg = () => (
  <svg width="72" height="78" viewBox="0 0 72 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="-0.5" cy="72.5" r="72.5" fill="#BDD6EF" fillOpacity="0.5" />
  </svg>

)
const RightSvg = () => (
  <svg width="84" height="41" viewBox="0 0 84 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="72.5" cy="-31.5" r="72.5" fill="#BDD6EF" fillOpacity="0.5" />
  </svg>

)