/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SupportModal from "@/components/SupportModal";
import { mainAxiosInstance } from "@/services/networkAdapters/axiosInstance";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
    LogoIcon,
    PricingIcon,
    JobPosting,
    AIInterviewer,
    GoodDex
} from "./SideNavIcon";

export default function SideNav({
  startNavbarSlideAnimate,
  setOpenDropdown,
  setStartNavbarSlideAnimate,
}) {
  const location = useLocation();

  const [hasActiveProducts, setHasActiveProducts] = useState(false);

  // support modal
  const [showSupportModal, setShowSupportModal] = useState(false);
  const handleClose = () => {
    setShowSupportModal(false);
  };

  
  const getActiveProducts = () => {
    mainAxiosInstance.get("/manage_products/getActiveProductsV2").then((res) => {
      const productData = res.data.data;
      const activeProductsExist = productData.some(product => product.remainingCount > 0);
      setHasActiveProducts(activeProductsExist);
    });
  };

  useEffect(() => {
    getActiveProducts();
  }, []);

  return (
    <div
      className={`dashboardNavbar__sideNav-container ${startNavbarSlideAnimate ? "dashboardNavbar__sideNav-container-hide" : ""
        }`}
      onTransitionEnd={() => {
        setTimeout(() => {
          setOpenDropdown(false);
          setStartNavbarSlideAnimate(false);
        }, [100]);
      }}
      style={{ paddingTop: '20px' }}
    >

      <div className="dashboardNavbar__sideNav-links">
        <div
            // exact
            // to="/about_us"
            // activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
        >
            <div className="dashboardNavbar__sideNav-links-link-text">
                Job Suite
            </div>
        </div>
        <div
            // exact
            // to="/about_us"
            // activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
        >
            <div className="dashboardNavbar__sideNav-links-link-text">
                GoodDex
            </div>
        </div>
        <div
            // exact
            // to="/about_us"
            // activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
        >
            <div className="dashboardNavbar__sideNav-links-link-text">
                Ai Interviewer
            </div>
        </div>
        <NavLink
            exact
            to="/about_us"
            activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
        >
            <div className="dashboardNavbar__sideNav-links-link-text">
                About Us
            </div>
        </NavLink>
        <NavLink
            exact
            to="/ourteam"
            activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
        >
            <div className="dashboardNavbar__sideNav-links-link-text">
                Our Team
            </div>
        </NavLink>
        <NavLink
            exact
            to="/careers"
            activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
        >
            <div className="dashboardNavbar__sideNav-links-link-text">
                Careers
            </div>
        </NavLink>
        <NavLink
          exact
          to="/pricing"
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-text">
            Pricing
          </div>
        </NavLink>
        <NavLink
          exact
          to="/blogs"
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-text">
            Blogs
          </div>
        </NavLink>
      </div>

      <div className="dashboardNavbar__sideNav-logo">
        <LogoIcon />
      </div>

      <SupportModal showSupportModal={showSupportModal} handleClose={handleClose} prependText="DASHBOARD" />
    </div>
  );
}

const ProductsIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.25 1.12158L0.5 4.5602L8.25 7.99882L16 4.5602L8.25 1.12158Z" stroke="#2A78C2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.5 11.4398L8.25 14.8784L16 11.4398" stroke="#2A78C2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.5 7.99872L8.25 11.4373L16 7.99872" stroke="#2A78C2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)
