import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { mainAxiosInstance } from '@/services/networkAdapters/axiosInstance';
import ReactModal from 'react-modal';


import countries2 from "@/utils/countries";
import { BsCheck2 } from "react-icons/bs";
import styles2 from "@/components/LoginModal/LoginModal.module.scss";
import CustomSelect from '@/components/CustomSelect';
import styles from './Form.module.scss'
import "@/components/PhoneNumberInput/PhoneNumberInput.scss";

const Form = ({ scrollToSection, countryCode, onChangeCountryCode, countryCodeDropdown, btnText = 'Book Demo', handleClose,isHirerContactPage }) => {

  const location = useLocation();

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
    companyName: "",
    offerId: "",
    type: "HOME_PAGE",
    path: location.pathname
  });

  const [formErrors, setFormErrors] = useState({});
  // country code dropdown

  const countries = countries2.map((a) => ({
    name: a.name,
    dialCode: a.dialCode,
    isoCode: a.isoCode
  }));
  const options = countries.map((a) => ({
    value: `${a.name} (${a.dialCode})`,
    id: a.isoCode,
    dialCode: a.dialCode
  }));

  const allowedCountryCodes = [
    { label: "India", value: "+91", flag: "https://cdn.kcak11.com/CountryFlags/countries/in.svg", initials: "IN" },
    { label: "United Arab Emirates", value: "+971", flag: "https://cdn.kcak11.com/CountryFlags/countries/ae.svg", initials: "UAE" },
    { label: "Australia", value: "+61", flag: "https://cdn.kcak11.com/CountryFlags/countries/au.svg", initials: "AU" },
    { label: "Saudi Arabia", value: "+966", flag: "https://cdn.kcak11.com/CountryFlags/countries/sa.svg", initials: "SA" },
    { label: "Singapore", value: "+65", flag: "https://cdn.kcak11.com/CountryFlags/countries/sg.svg", initials: "SG" },
    { label: "United States", value: "+1", flag: "https://cdn.kcak11.com/CountryFlags/countries/us.svg", initials: "US" }
  ];


  const [selectedCountry, setSelectedCountry] = useState(allowedCountryCodes[0]);
  const [isListVisible, setIsListVisible] = useState(false)
  const handleCountryCodeChange = (value) => {
    const country = countries2.find((item) => item.dialCode === value);
    setSelectedCountry({
      label: country.name,
      value: country.dialCode,
      flag: country.flag,
      initials: country.isoCode
    });
    setIsListVisible((item) => !item)

    if (onChangeCountryCode) {
      onChangeCountryCode({ dialCode: country.dialCode, isoCode: country.isoCode });
    }
  }


  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const modalRef = useRef(null); // Ref for any direct DOM manipulations or focus management

  // Function to open modal
  const showModal = () => setIsModalOpen(true);

  // Function to close modal
  const hideModal = () => {
    setIsModalOpen(false);
    handleClose()
  };

  // Example key press handler
  const onKeyPress = (event) => {
    if (event.key === 'Escape') {
      hideModal();
    }
    // Handle other key presses if needed
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the input name is phoneNumber, then clean the input to only have digits
    if (name === "phoneNumber") {
      const cleanedValue = value.replace(/\D/g, ''); // \D matches any character that's not a digit
      setFormValues(prevState => ({
        ...prevState,
        [name]: cleanedValue,
      }));
    } else {
      // For all other inputs, set the value as is
      setFormValues(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateForm = (values) => {
    const errors = {};

    // First Name validation
    if (!values.firstName.trim()) {
      errors.firstName = 'First name is required';
    }

    // Last Name validation
    if (!values.lastName.trim()) {
      errors.lastName = 'Last name is required';
    }

    // Phone Number validation
    if (formValues.phoneNumber.trim() === '') {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\+?\d{10,15}$/.test(formValues.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }

    // Email validation
    if (!values.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    // Company Name validation (if required)
    if (!isHirerContactPage&&!values.companyName && values.companyName.trim() === '') {
      errors.companyName = 'Company name is required';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation
    const errors = validateForm(formValues);
    setFormErrors(errors);

    let formValuesWithDialCode = null;
    // Check if there's a selected country and a phone number, and no validation errors
    if (selectedCountry && formValues.phoneNumber && Object.keys(errors).length === 0) {
      // Prepend the dial code to the phone number
      const phoneNumberWithDialCode = `${selectedCountry.value}${formValues.phoneNumber}`;
      // Create a copy of formValues to modify
      formValuesWithDialCode = {
        ...formValues,
        phoneNumber: phoneNumberWithDialCode // Replace the phoneNumber with the one including the dial code
      };
    } else {
      formValuesWithDialCode = { ...formValues };
    }

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0 && formValuesWithDialCode) {
      console.log('Submitting:', formValuesWithDialCode);

      mainAxiosInstance.post(`/contact-sales/save-form`, formValuesWithDialCode)
        .then(() => {
          showModal();
          // Clear form values
          setFormValues({
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            message: "",
            companyName: "",
            offerId: "",
            type: "HOME_PAGE",
            path: location.pathname
          });
        })
        .catch((err) => {
          // Handle error
          console.error(err);
        });
    }
  };


  return (
    <>

      <div className={styles.container}>
        <div className={`${styles.row} ${styles.nameMobileStyle}`}>
          <div className={styles.column}>
            <input
              type="text"
              name="firstName"
              placeholder='First Name'
              value={formValues.firstName}
              onChange={handleInputChange}
              className={`${styles.textField} ${formErrors.firstName ? styles.errorField : ''}`}

              style={{ margin: '6px 0px' }}
            />
            {formErrors.firstName && <p className={styles.errorMessage} style={{ position: 'relative', left: '6px' }}>{formErrors.firstName}</p>}

          </div>

          <div className={styles.column}>
            <input
              type="text"
              placeholder='Last Name'
              name="lastName"
              value={formValues.lastName}
              onChange={handleInputChange}
              className={`${styles.textField} ${formErrors.lastName ? styles.errorField : ''}`}
              style={{ margin: '6px 0px' }}

            />
            {formErrors.lastName && <p className={styles.errorMessage} style={{ position: 'relative', left: '6px' }}>{formErrors.lastName}</p>}
          </div>

        </div>

        <div className={styles.row}>
          {
            countryCodeDropdown ? (
              <div className="phoneInput__countryCode-container">
                <CustomSelect
                  options={options}
                  searchable
                  variant={5}
                  onChange={onChangeCountryCode}
                  selectedValue={countryCode}
                  type="country_code"

                />
              </div>
            ) : (
              <div className="phoneInput__countryCode-containersm">
                <div className={`${styles.selectCode} ${formErrors.phoneNumber ? styles.errorField : ''}`} onClick={() => setIsListVisible((item) => !item)} >
                  {/* <img src={selectedCountry.flag} alt="" className="flag" /> */}
                  {selectedCountry.initials} &nbsp;
                  {selectedCountry.value}
                </div>
                {isListVisible && <ul className="selectUl">
                  {allowedCountryCodes.map(code => (
                    <li
                      key={code.value}
                      value={code.value}
                      className={`selectOptions ${selectedCountry.value === code.value ? 'blueFont' : ''}`}
                      onClick={() => handleCountryCodeChange(code.value)}
                    >
                      {code.label}
                      {selectedCountry.value === code.value && <BsCheck2 className="blueCheck" />}
                    </li>
                  ))}
                </ul>}
              </div>
            )
          }
          <div className={styles.column}>

            <input
              type="text" // Changed from "number" to "text"
              name="phoneNumber"
              placeholder="Enter your contact number"
              value={formValues.phoneNumber}
              onChange={handleInputChange}
              className={`${styles.textField} ${formErrors.phoneNumber ? styles.errorField : ''}`}
              style={{ marginLeft: '0px', paddingLeft: '4px', borderRadius: '0px 6px 6px 0px' }}
            />
            {formErrors.phoneNumber && <p className={`${styles.errorMessage} ${styles.additionalStyle}`} >{formErrors.phoneNumber}</p>}

          </div>
        </div>

        <div className={`${styles.row} ${styles.column}`}>
          <input
            type="text"
            placeholder='Email'
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            className={`${styles.textField} ${formErrors.email ? styles.errorField : ''}`}
          />
          {formErrors.email && <p className={styles.errorMessage}>{formErrors.email}</p>}

        </div>
        <div className={`${styles.row} ${styles.column}`}>
          <input
            type="text"
            placeholder='Company Name'
            name="companyName"
            value={formValues.companyName}
            onChange={handleInputChange}
            className={`${styles.textField} ${formErrors.companyName ? styles.errorField : ''}`}
          />
          {formErrors.companyName && <p className={styles.errorMessage}>{formErrors.companyName}</p>}

        </div>
        <button type="submit" className={styles.btn} onClick={handleSubmit}>
          {btnText}
        </button>

      </div>

      <ReactModal isOpen={isModalOpen}
        overlayClassName={styles2.overlay}
        className={styles2.modal}
        onRequestClose={hideModal}
      >
        <div className={styles.modalcontainer} ref={modalRef} tabIndex="-1" onKeyDown={onKeyPress}>
          <div className={styles.modallogo}>
            <Ticklogo />
          </div>
          <div className={styles.modaltitle}>We have received your request</div>
          <div className={styles.modaltext}>
            Our team will get in touch with you shortly
          </div>
          <button className={styles.btn} style={{ width: '66%' }}
            onClick={() => {
              hideModal()
              handleClose()
              scrollToSection("heroref")
            }}>Start Hiring
          </button>
        </div>
      </ReactModal>
    </>

  );
};

export default Form;

const Ticklogo = () => (
  <svg width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M78.875 48.6257C76.125 62.3757 65.7575 75.3227 51.21 78.2157C44.115 79.6287 36.755 78.7672 30.178 75.7539C23.6011 72.7406 18.1425 67.7291 14.5794 61.433C11.0164 55.1369 9.53057 47.8771 10.3335 40.6874C11.1365 33.4978 14.1873 26.7447 19.0515 21.3897C29.0285 10.4007 45.875 7.37574 59.625 12.8757" stroke="#297BCA" strokeWidth="5.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M32.125 43.125L45.875 56.875L78.875 21.125" stroke="#297BCA" strokeWidth="5.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

