import React, { useState, useRef , useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { mainAxiosInstance } from '@/services/networkAdapters/axiosInstance'
import { CustomInputText, CustomTextarea } from '@/components/CustomFormComponents'

import SupportSvg from "@/assets/svg/SupportSVG.svg";
import userSvg from "@/assets/svg/user.svg";

// country code drop down
import countries2 from "@/utils/countries";
import { BsCheck2 } from "react-icons/bs";
import CustomSelect from '@/components/CustomSelect';
import Loader from "@/pages/InterviewPage/NewInterviewExperience/Loader/Loader";
import styles from "./SupportModal.module.scss";


const SupportModal = ({ showSupportModal, handleClose, countryCode, onChangeCountryCode, countryCodeDropdown, prependText = "", modalFor="" }) => {
  const user = useSelector((state) => state.user);
  console.log(user)
  const history = useHistory();
  const location = useLocation();
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    firstName: user?.name,
    lastName: "",
    phoneNumber: user?.mobile_number,
    email: user?.email_id,
    message: "",
    companyName: user?.organisation,
    offerId: "",
    type: prependText,
    path: location.pathname,
  });


  // success modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const showModal = () => setIsModalOpen(true);

  const hideModal = () => setIsModalOpen(false);

  const onKeyPress = (event) => {
    if (event.key === 'Escape') {
      hideModal();
    }
  };

  useEffect(() => {
    if(isModalOpen) {
      setTimeout(() => {
        hideModal();
      }, 3000);
    }
  }, [isModalOpen]);

  // country code dropdown
  const countries = countries2.map((a) => ({
    name: a.name,
    dialCode: a.dialCode,
    isoCode: a.isoCode
  }));
  const options = countries.map((a) => ({
    value: `${a.name} (${a.dialCode})`,
    id: a.isoCode,
    dialCode: a.dialCode
  }));

  const allowedCountryCodes = [
    { label: "India", value: "+91", flag: "https://cdn.kcak11.com/CountryFlags/countries/in.svg", initials: "IN" },
    { label: "United Arab Emirates", value: "+971", flag: "https://cdn.kcak11.com/CountryFlags/countries/ae.svg", initials: "UAE" },
    { label: "Australia", value: "+61", flag: "https://cdn.kcak11.com/CountryFlags/countries/au.svg", initials: "AU" },
    { label: "Saudi Arabia", value: "+966", flag: "https://cdn.kcak11.com/CountryFlags/countries/sa.svg", initials: "SA" },
    { label: "Singapore", value: "+65", flag: "https://cdn.kcak11.com/CountryFlags/countries/sg.svg", initials: "SG" },
    { label: "United States", value: "+1", flag: "https://cdn.kcak11.com/CountryFlags/countries/us.svg", initials: "US" }
  ];


  const [selectedCountry, setSelectedCountry] = useState(allowedCountryCodes[0]);
  const [isListVisible, setIsListVisible] = useState(false)
  const handleCountryCodeChange = (value) => {
    const country = countries2.find((item) => item.dialCode === value);
    setSelectedCountry({
      label: country.name,
      value: country.dialCode,
      flag: country.flag,
      initials: country.isoCode
    });
    setIsListVisible((item) => !item)

    if (onChangeCountryCode) {
      onChangeCountryCode({ dialCode: country.dialCode, isoCode: country.isoCode });
    }
  }


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.firstName.trim()) {
      errors.firstName = 'First name is required';
    }

    // if (!values.lastName.trim()) {
    //   errors.lastName = 'Last name is required';
    // }

    if (formValues.phoneNumber.trim() === '') {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\+?\d{10,15}$/.test(formValues.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }

    // if (!values.email.trim()) {
    //   errors.email = 'Email is required';
    // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
    //   errors.email = 'Invalid email address';
    // }

    // if (!values.companyName && values.companyName.trim() === '') {
    //   errors.companyName = 'Company name is required';
    // }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm(formValues);
    setFormErrors(errors);

    let formValuesWithDialCode = null;
    if (selectedCountry && formValues.phoneNumber && Object.keys(errors).length === 0) {
      const phoneNumberWithDialCode = `${selectedCountry.value}${formValues.phoneNumber}`;
      formValuesWithDialCode = {
        ...formValues,
        phoneNumber: phoneNumberWithDialCode,
        message: `${formValues.message}`
      };
    } else {
      formValuesWithDialCode = { ...formValues };
    }

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0 && formValuesWithDialCode) {
      console.log('Submitting:', formValuesWithDialCode);

      mainAxiosInstance.post(`/contact-sales/save-form`, formValuesWithDialCode)
        .then(() => {
          showModal();
          handleClose();
          // Clear form values
          setFormValues({
            ...formValues,
            message: '',
          });
        })
        .catch((err) => {
          // Handle error
          console.error(err);
        });
    }
  };

  return (
    <>
      <ReactModal
        className={styles.modalDialogue}
        overlayClassName={`${styles.modalOverlay} ${'postJob__completeBanner - overlay - 2'}`}
        // animation
        // centered
        isOpen={showSupportModal}
        onRequestClose={handleClose}
      >
        <div className={styles.close} onClick={handleClose}>
          <CloseIcon />
        </div>
        <div className={styles.header}>
          <div>
            <img src={SupportSvg} alt="Support SVG" />
          </div>
          <div className={styles.title}>{modalFor.toLowerCase() === "upgrade" ? "Contact Admin" : "Need Support"}</div>
          <div className={styles.subtitle}>{ modalFor.toLowerCase() === "upgrade" ? "Got a question or facing an issue? Connect with our dedicated support team to upgrade your plan and get the assistance you need." : "Got a question or facing an issue? Our dedicated support team is here to provide you with the assistance you need."}</div>
        </div>

        <form className={styles.container} onSubmit={handleSubmit}>
          { !user?.user_id && (
            <>
              <div style={{ display: 'flex', gap: '16px', width: '100%' }}  className={styles.userInfoDiv}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                  <img src={userSvg} alt="User SVG" />  
                  <CustomInputText
                    placeholder="First Name"
                    name="firstName"
                    value={formValues.firstName}
                    onChange={handleInputChange}
                    errorMessage={formErrors.firstName}
                  />
                  {/* <CustomInputText
                    placeholder="Last Name"
                    name="lastName"
                    value={formValues.lastName}
                    onChange={handleInputChange}
                    errorMessage={formErrors.lastName}
                  /> */}
                </div>
                <div style={{ position: 'relative' }}>

                  {countryCodeDropdown ? (
                    <div className={`"phoneInput__countryCode-container"`}>
                      <CustomSelect
                        options={options}
                        searchable
                        variant={5}
                        onChange={onChangeCountryCode}
                        selectedValue={countryCode}
                        type="country_code"

                      />
                    </div>
                  ) : (
                    <div className="phoneInput__countryCode-containersm">
                      <div className={`${styles.selectCode} ${formErrors.phoneNumber ? styles.errorField : ''}`} onClick={() => setIsListVisible((item) => !item)} >
                        {/* <img src={selectedCountry.flag} alt="" className="flag" /> */}
                        {selectedCountry.initials} &nbsp;
                        {selectedCountry.value}
                      </div>
                      {isListVisible && <ul className={`selectUl ${styles.list}`}>
                        {allowedCountryCodes.map(code => (
                          <li
                            key={code.value}
                            value={code.value}
                            className={`selectOptions ${selectedCountry.value === code.value ? 'blueFont' : ''}`}
                            onClick={() => handleCountryCodeChange(code.value)}
                          >
                            {code.label}
                            {selectedCountry.value === code.value && <BsCheck2 className="blueCheck" />}
                          </li>
                        ))}
                      </ul>}
                    </div>
                  )
                  }
                  <div className={styles.phoneInputContainer}>

                    <CustomInputText
                      placeholder="Enter your contant number"
                      name="phoneNumber"
                      value={formValues.phoneNumber}
                      onChange={handleInputChange}
                      errorMessage={formErrors.phoneNumber}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                <MailSvg />  
                <CustomInputText
                  placeholder="Enter your mail"
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  errorMessage={formErrors.firstName}
                />
                {/* <CustomInputText
                  placeholder="Last Name"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  errorMessage={formErrors.lastName}
                /> */}
              </div>
            </>
          )}

          {/* <CustomInputText
            placeholder="Email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            errorMessage={formErrors.email}
          /> */}

          {/* <CustomInputText
            placeholder="Company Name"
            name="companyName"
            value={formValues.companyName}
            onChange={handleInputChange}
            errorMessage={formErrors.companyName}
          /> */}


          <CustomTextarea
            placeholder="Enter your message here..."
            name="message"
            value={formValues.message}
            onChange={handleInputChange}
            errorMessage={formErrors.message}
            style={{ minHeight: '56px', height: '222px', maxHeight: '300px' }}
          />


          <button type="submit" className={styles.btn} onClick={handleSubmit}>
            Send your message
          </button>
        </form>

      </ReactModal>

      <ReactModal isOpen={isModalOpen}
        className={styles.modalDialogue}
        overlayClassName={`${styles.modalOverlay} ${'postJob__completeBanner - overlay - 2'}`}
        onRequestClose={hideModal}
      >
        <div className={styles.modalcontainer} ref={modalRef} tabIndex="-1" onKeyDown={onKeyPress}>
          <div className={styles.modallogo}>
            <Ticklogo />
          </div>
          <div className={styles.modaltitle}>We got your message</div>
          <div className={styles.modaltext}>
            Our team will get in touch with you shortly
          </div>
          <div className={styles.modalRedirect}>
            <div>Taking you back. Please wait.</div>
            <Loader />
          </div>
        </div>
      </ReactModal>
    </>

  )
}

export default SupportModal

const CloseIcon = () => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.75 7.25L7.25 21.75" stroke="#9DA3A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.25 7.25L21.75 21.75" stroke="#9DA3A8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

const Ticklogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="140" height="94" viewBox="0 0 140 94" fill="none">
    <circle cx="77.0718" cy="46.6074" r="46.6074" fill="#F5F5F5" fillOpacity="0.4"/>
    <ellipse cx="77.2981" cy="46.3806" rx="40.9236" ry="40.9236" fill="#1E9915"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M99.2874 30.7576C100.284 31.7548 100.284 33.3715 99.2874 34.3686L71.2219 62.4555C70.2256 63.4526 68.6101 63.4526 67.6137 62.4555L54.8567 49.6887C53.8603 48.6916 53.8603 47.0749 54.8567 46.0777C55.8531 45.0806 57.4685 45.0806 58.4649 46.0777L69.4178 57.039L95.6792 30.7576C96.6756 29.7605 98.2911 29.7605 99.2874 30.7576Z" fill="#FAFAFA"/>
    <path d="M4.70939 36.9024C4.61777 36.6236 4.31748 36.4719 4.03867 36.5635C3.75986 36.6551 3.60811 36.9554 3.69973 37.2342L4.69509 40.2632L1.66609 41.2586C1.38728 41.3502 1.23553 41.6505 1.32715 41.9293C1.41877 42.2081 1.71906 42.3599 1.99787 42.2682L5.02688 41.2729L6.02224 44.3019C6.11386 44.5807 6.41415 44.7324 6.69296 44.6408C6.97178 44.5492 7.12352 44.2489 7.0319 43.9701L6.03654 40.9411L9.06554 39.9457C9.34435 39.8541 9.4961 39.5538 9.40448 39.275C9.31286 38.9962 9.01257 38.8444 8.73376 38.9361L5.70476 39.9314L4.70939 36.9024Z" fill="#1E9915"/>
    <path d="M135.967 17.2128C136.089 16.97 135.992 16.6739 135.749 16.5514C135.506 16.4289 135.21 16.5265 135.088 16.7693L133.757 19.4076L131.119 18.077C130.876 17.9545 130.58 18.0521 130.457 18.295C130.335 18.5378 130.433 18.834 130.675 18.9564L133.314 20.287L131.983 22.9252C131.861 23.168 131.958 23.4642 132.201 23.5866C132.444 23.7091 132.74 23.6115 132.862 23.3687L134.193 20.7305L136.831 22.061C137.074 22.1835 137.37 22.0859 137.493 21.8431C137.615 21.6002 137.518 21.3041 137.275 21.1816L134.636 19.8511L135.967 17.2128Z" fill="#1E9915"/>
  </svg>
);

const MailSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M18.3333 2.5H1.66668C0.747461 2.5 0 3.24746 0 4.16668V15.8334C0 16.7525 0.747461 17.5 1.66668 17.5H18.3334C19.2525 17.5 20 16.7525 20 15.8333V4.16668C20 3.24746 19.2525 2.5 18.3333 2.5ZM1.66668 3.33332H18.3334C18.3948 3.33332 18.4489 3.3557 18.5065 3.36836C17.0636 4.68895 12.279 9.06598 10.6047 10.5745C10.4737 10.6925 10.2625 10.8333 10 10.8333C9.73758 10.8333 9.52641 10.6925 9.39496 10.5741C7.72078 9.06582 2.93598 4.68855 1.49328 3.36844C1.55102 3.35578 1.60523 3.33332 1.66668 3.33332ZM0.83332 15.8333V4.16668C0.83332 4.08504 0.85793 4.01098 0.879961 3.93637C1.98434 4.94715 5.32277 8.00121 7.48746 9.96969C5.3298 11.8231 1.99051 14.989 0.877344 16.0505C0.857695 15.9796 0.83332 15.9104 0.83332 15.8333ZM18.3333 16.6667H1.66668C1.60012 16.6667 1.54094 16.6435 1.47879 16.6286C2.62906 15.5321 5.98961 12.3479 8.10934 10.5345C8.35157 10.7544 8.59415 10.9739 8.83707 11.193C9.18051 11.5031 9.5825 11.6667 10 11.6667C10.4175 11.6667 10.8195 11.5031 11.1625 11.1934C11.4056 10.9742 11.6483 10.7545 11.8907 10.5345C14.0105 12.3477 17.3706 15.5316 18.5212 16.6286C18.4591 16.6435 18.4 16.6667 18.3333 16.6667ZM19.1667 15.8333C19.1667 15.9103 19.1423 15.9796 19.1227 16.0505C18.0091 14.9885 14.6702 11.8229 12.5126 9.96973C14.6773 8.00125 18.0153 4.94746 19.12 3.93629C19.1421 4.0109 19.1667 4.085 19.1667 4.16664V15.8333Z" fill="#6F6F6F"/>
  </svg>
)