import React, { useState } from 'react';
import styles from './styles.module.scss';
import PricingCard from './Components/PricingCard';
import Navbar from '../AutoRecruiterLandingPage/Navbar/Navbar';
import Carasoul from './Components/Carasoul/Carasoul';

import { partList } from './constants/constants';

const AiInterviewPricingPage = () => {
    const [activeTab, setActiveTab] = useState(0); // Initialize to first card
    const [currencyChecked, setCurrenyChecked] = useState(false);
    const [dateChecked, setdateChecked] = useState(false);
    
    const handleActiveIndexChange = (index) => {
        console.log('Active index changed to:', index);
        setActiveTab(index);
    }

    return (
        <>
            <Navbar hirerMode={1} />
            <div className={styles.PricingPageContainer}>
                <div className={styles.backIcon}><BackIcon /></div>
                <h1>AI Interview Pricing Page</h1>
                <h2>Jumpstart your journey with us!</h2>
                <div className={styles.mainContent}>
                    <div className={styles.toggleBars}>
                        <label htmlFor="currenyCheck" className={styles.switch} aria-label="Toggle Filter">
                            <input type="checkbox" id="currenyCheck" onChange={() => setCurrenyChecked(!currencyChecked)} checked={currencyChecked} />
                            <span>USD <DollarIcon color={currencyChecked ? "#8255C8" : "white"} /></span>
                            <span>INR <RuppeeIcon color={!currencyChecked ? "#8255C8" : "white"} /></span>
                        </label>
                        <label htmlFor="dateCheck" className={styles.switch} aria-label="Toggle Filter">
                            <input type="checkbox" id="dateCheck" onChange={() => setdateChecked(!dateChecked)} checked={dateChecked} />
                            <span>MONTHLY</span>
                            <span>
                                YEARLY
                                <div>
                                    <p>Save 10%</p>
                                </div>
                            </span>
                        </label>
                    </div>
                    <div className={styles.pricingCardContainer}>
                        <Carasoul onActiveIndexChange={handleActiveIndexChange}>
                            {partList.map((a, i) => (
                                <div>
                                    {i === 1 && (<span className={styles.Recommended}>RECOMMENDED</span>)}
                                    <PricingCard key={i} activeTab={i === activeTab} index={i} setActiveTab={setActiveTab} data={a} />
                                </div>
                            ))}
                        </Carasoul>
                        <div className={styles.carasoulViewIndex}>
                            {partList.map((_, index) => (
                                <div 
                                    key={index} 
                                    className={`${styles.indexDot} ${index === activeTab ? styles.active : ''}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AiInterviewPricingPage;

// BackIcon, DollarIcon, RuppeeIcon components remain unchanged
const BackIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="24" viewBox="0 0 36 24" fill="none">
        <path d="M36 10H7.66L14.82 2.82L12 0L0 12L12 24L14.82 21.18L7.66 14H36V10Z" fill="#8255C8"/>
    </svg>
);

const DollarIcon = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clipPath="url(#clip0_6729_31211)">
            <path d="M8.70813 7.00026V3.44513C9.46859 3.57354 10.1862 3.88556 10.7988 4.35411C10.9208 4.43786 11.0649 4.48355 11.2129 4.48539C11.6886 4.48539 12.0764 4.10261 12.0815 3.62692C12.0825 3.42289 12.0026 3.22677 11.8593 3.08154C10.9732 2.32281 9.86266 1.87575 8.69803 1.80896V0.617168C8.69803 0.27679 8.42231 0.00106862 8.08193 0.00106862C8.07487 6.86056e-05 8.06778 6.86056e-05 8.06071 6.86056e-05C7.7153 -0.00499394 7.43049 0.270759 7.42543 0.617168V1.76855C5.15297 1.93015 3.6077 3.33404 3.6077 5.20249C3.6077 7.49514 5.55697 8.13146 7.42543 8.63643V12.6764C6.43967 12.5441 5.5105 12.135 4.74896 11.4947C4.60556 11.3806 4.42777 11.317 4.24396 11.3129C3.77836 11.3452 3.41982 11.7351 3.42586 12.2017C3.42485 12.4057 3.50474 12.6019 3.64805 12.7471C4.69105 13.6864 6.03261 14.2266 7.43549 14.2722V15.3832C7.43549 15.3902 7.43649 15.3973 7.43649 15.4044C7.45265 15.7498 7.74655 16.0164 8.09196 15.9993C8.43234 15.9993 8.70806 15.7235 8.70806 15.3832V14.252C11.4653 14.0702 12.5763 12.3936 12.5763 10.616C12.5764 8.23246 10.5766 7.50527 8.70813 7.00026ZM7.43555 6.67707C6.33467 6.35388 5.47619 6.0206 5.47619 5.08131C5.47619 4.14202 6.25388 3.46532 7.43555 3.37445V6.67707ZM8.70813 12.6966V9.02025C9.84942 9.34344 10.7382 9.77773 10.7281 10.8382C10.7281 11.6058 10.2029 12.5148 8.70813 12.6966Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_6729_31211">
            <rect width="16" height="16" fill={color}/>
            </clipPath>
        </defs>
    </svg>
);

const RuppeeIcon = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M8.30213 15.3485C8.02266 15.3485 7.83634 15.2554 7.65003 15.0691L2.06059 9.47962C1.78112 9.20014 1.68796 8.82752 1.87428 8.45489C1.96743 8.08226 2.34006 7.89594 2.71269 7.89594H5.50741C6.25267 7.89594 6.99793 7.61647 7.46371 7.05753C8.02266 6.59174 8.30213 5.84648 8.30213 5.10122C8.30213 4.35597 8.02266 3.61071 7.46371 3.14492C6.99793 2.58598 6.25267 2.30651 5.50741 2.30651H2.71269C2.15375 2.30651 1.78112 1.93388 1.78112 1.37493C1.78112 0.815989 2.15375 0.443359 2.71269 0.443359H12.96C13.5189 0.443359 13.8916 0.815989 13.8916 1.37493C13.8916 1.93388 13.5189 2.30651 12.96 2.30651H9.2337C9.79264 3.14492 10.1653 4.07649 10.1653 5.10122C10.1653 6.31227 9.69949 7.52331 8.76791 8.36173C7.9295 9.2933 6.71845 9.75909 5.50741 9.75909H4.94847L8.95423 13.7649C9.32686 14.1375 9.32686 14.6964 8.95423 15.0691C8.76791 15.2554 8.5816 15.3485 8.30213 15.3485Z" fill={ color }/>
        <path d="M12.9601 6.03307H2.71282C2.15388 6.03307 1.78125 5.66044 1.78125 5.10149C1.78125 4.54255 2.15388 4.16992 2.71282 4.16992H12.9601C13.5191 4.16992 13.8917 4.54255 13.8917 5.10149C13.8917 5.66044 13.5191 6.03307 12.9601 6.03307Z" fill={ color }/>
    </svg>
);