import Growth from '../assets/svg/Growth';
import Starter from '../assets/svg/Starter';
import Pro from '../assets/svg/Pro';

export const partList = [
    { title: "Starter", price: "70", subDesc: "10 candidates per month", arr: [
        "$5 per additional invites",
        "Behavioral & Situational Assessment",
        "Pronunciation Assessment",
        "Interview Proctoring",
        "Interview transcripts and evaluations"
    ], img: Starter },
    { title: "Growth", price: "315", subDesc: "50 candidates per month", arr: [
        "$4 per additional invites",
        "Behavioral & Situational Assessment",
        "Pronunciation Assessment",
        "Interview Proctoring",
        "Interview transcripts and evaluations"
    ], img: Growth },
    { title: "Pro", price: "580", subDesc: "100 candidates per month", arr: [
        "$3 per additional invites",
        "Behavioral & Situational Assessment",
        "Pronunciation Assessment",
        "Interview Proctoring",
        "Interview transcripts and evaluations"
    ], img: Pro },
]
