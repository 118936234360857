import React, { useState, useEffect, useRef } from 'react';
import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min.css';
import stars from "@/assets/svg/starWhite.svg";
import CustomSelectWrapper from '@/components/CustomSelectV2/CustomSelect';
import { BtnBold, BtnItalic, Editor, EditorProvider, Toolbar, BtnBulletList, BtnNumberedList, BtnStrikeThrough, BtnUnderline } from 'react-simple-wysiwyg';
import { marked } from 'marked';
import TurndownService from 'turndown';
import styles from './CustomFormComponents.module.scss';


export const CustomInputText = ({ title, subTitle, placeholder, name, value, onChange, errorMessage, required, style }) => (
  <div className={`${styles.row} ${styles.column}`}>
    {title && (
      <div className={styles.title}>
        {title}
        {required && <span className={styles.requiredAsterisk}>*</span>}
      </div>
    )}
    {subTitle && (
      <div className={styles.subTitle}>
        {subTitle}
      </div>
    )}
    <input
      type="text"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      className={`${styles.textField} ${errorMessage ? styles.errorField : ''}`}
      style={{ ...style }}
    />
    {errorMessage && <p className={styles.errorMessage}>&nbsp;{errorMessage}</p>}
  </div>
);

export const CustomTextarea = ({ title, subTitle, placeholder, name, value, onChange, errorMessage, required, style, getJdFromJobData, fetchingDescription }) => {

  console.log(value);

  const overviewIndex = value.indexOf("**Overview:**");

  let formattedValue = value;

  // If "Overview:" is found, remove everything before it.
  if (overviewIndex > -1) {
    formattedValue = value.substring(overviewIndex);
  }

  // Replace the asterisks and dashes as before
  formattedValue = formattedValue.replace(/\*\*/g, '').replace(/---/g, '').replace(/^-/gm, '•');

  return (
    <div className={`${styles.row} ${styles.column}`}>
      <div className={styles.titleContainer} style={{ paddingBottom: '4px' }}>
        <div>
          {title && (
            <div className={styles.title}>
              {title}
              {required && <span className={styles.requiredAsterisk}>*</span>}
            </div>
          )}
          {subTitle && (
            <div className={styles.subTitle}>
              {subTitle}
            </div>
          )}
        </div>
        {getJdFromJobData && (
          <div>
            <div className={styles.getJdFromJobDatacontainer}>
              <div className={`${styles.button} ${fetchingDescription ? styles.gradientAnimated : ''}`} onClick={getJdFromJobData}>
                <img src={stars} alt='' />{fetchingDescription ? 'Generating JD...' : 'Generate JD'}
              </div>
            </div>
          </div>
        )}
      </div>
      <textarea
        placeholder={placeholder}
        name={name}
        value={formattedValue}
        onChange={onChange}
        className={`${styles.textField} ${errorMessage ? styles.errorField : ''}`}
        style={{ ...style }}
      />
      {errorMessage && <p className={styles.errorMessage}>&nbsp;{errorMessage}</p>}
    </div>
  );
}

const turndownService = new TurndownService();

export const CustomRichTextarea = ({
  title,
  subTitle,
  placeholder,
  name,
  value: incomingMarkdown = '',
  onChange,
  errorMessage,
  required,
  style,
  getJdFromJobData,
  fetchingDescription
}) => {
  const [markdownText, setMarkdownText] = useState(incomingMarkdown);
  const [htmlText, setHtmlText] = useState('');

  useEffect(() => {
    const overviewIndex = incomingMarkdown.indexOf('**Overview:**');
    let finalMarkdown = incomingMarkdown;
    if (overviewIndex > -1) {
      finalMarkdown = incomingMarkdown.substring(overviewIndex);
    }
    setMarkdownText(finalMarkdown);

    // Markdown --> HTML
    const generatedHTML = marked(finalMarkdown);
    setHtmlText(generatedHTML);
  }, [incomingMarkdown]);

  const handleEditorChange = (newHtml) => {
    // 1) Sync the Editor with the new HTML
    // console.log(newHtml);
    setHtmlText(newHtml.currentTarget.innerHTML);
    const markdown = turndownService.turndown(newHtml.currentTarget.innerHTML);

    // Optional: remove everything before '**Overview:**'
    const overviewIndex = markdown.indexOf('**Overview:**');
    const relevantMarkdown =
      overviewIndex > -1 ? markdown.substring(overviewIndex) : markdown;

    // Update local markdown state
    setMarkdownText(relevantMarkdown);

    // Notify parent
    onChange?.(relevantMarkdown);
    // debouncedConvertToMarkdown(newHtml.currentHtml.innerHTML);

    // // 2) Convert HTML --> Markdown
    // const markdownContent = turndownService.turndown(newHtml.currentTarget.innerHTML);

    // // 3) If needed, remove everything before "**Overview:**"
    // const overviewIndex = markdownContent.indexOf('**Overview:**');
    // const relevantMarkdown =
    //   overviewIndex > -1 ? markdownContent.substring(overviewIndex) : markdownContent;

    // setMarkdownText(relevantMarkdown);

    // // 4) Bubble up to parent
    // onChange?.(relevantMarkdown);
  };

  return (
    <div className={`${styles.row} ${styles.column} ${styles.richTextEditorContainer}`}>
      <div className={styles.titleContainer} style={{ paddingBottom: '4px' }}>
        <div>
          {title && (
            <div className={styles.title}>
              {title}
              {required && <span className={styles.requiredAsterisk}>*</span>}
            </div>
          )}
          {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        </div>

        {getJdFromJobData && (
          <div>
            <div className={styles.getJdFromJobDatacontainer}>
              <div
                className={`${styles.button} ${
                  fetchingDescription ? styles.gradientAnimated : ''
                }`}
                onClick={getJdFromJobData}
              >
                {/* <img src={stars} alt='' /> */}
                {fetchingDescription ? 'Generating JD...' : 'Generate JD'}
              </div>
            </div>
          </div>
        )}
      </div>

      <EditorProvider>
        <Editor
          value={htmlText}
          onChange={handleEditorChange}
          className={`${styles.textField} ${styles.richTextEditor} ${errorMessage ? styles.errorField : ''}`}
          style={{ ...style }}
          placeholder={placeholder}
          name={name}
        >
          <Toolbar>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            <BtnNumberedList />
            <BtnBulletList />
          </Toolbar>
        </Editor>
      </EditorProvider>

      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
};

export const CustomFormSelectWrapper = ({
  title,
  subTitle,
  placeholder,
  name,
  value,
  onChange,
  errorMessage,
  required,
  type,
  theme,
  async,
  allowNewOptions,
  options,
  className
}) => (
  <div className={`${styles.row} ${styles.column}`}>
    {title && (
      <div className={styles.title}>
        {title}
        {required && <span className={styles.requiredAsterisk}>*</span>}
      </div>
    )}
    {subTitle && (
      <div className={styles.subTitle}>
        {subTitle}
      </div>
    )}
    <CustomSelectWrapper
      type={type}
      theme={theme}
      async={async}
      allowNewOptions={allowNewOptions}
      onChange={onChange}
      value={value}
      className={className}
      placeholder={placeholder || "eg Talent Acquisition Manager"}
      options={options}
    />
    {errorMessage && <p className={styles.errorMessage}>&nbsp;{errorMessage}</p>}
  </div>
);

export const CustomSuggCardSelectWrapper = ({
  title,
  subTitle,
  errorMessage,
  placeholder,
  theme,
  initialSelectedSkills,
  setInitialSelectedSkills,
  onChange,
  allowNewOptions,
}) => {
  const removeSkill = (item) => {
    const selectedSkills_ = [...initialSelectedSkills];
    const index = selectedSkills_.findIndex((a) => a.value === item.value);
    if (index < 0) return;
    if (selectedSkills_[index].popular) {
      selectedSkills_[index] = { ...selectedSkills_[index], selected: false };
      setInitialSelectedSkills(selectedSkills_);
    } else setInitialSelectedSkills((a) => a.filter((b) => b.value !== item.value));
  };

  return (
    <div className={`${styles.row} ${styles.column}`}>
      {title && (
        <div className={styles.title}>{title}</div>
      )}
      {subTitle && (
        <div className={styles.subTitle}>
          {subTitle}
        </div>
      )}
      <CustomSelectWrapper
        theme={theme}
        type="skill"
        onChange={onChange}
        allowNewOptions={allowNewOptions}
        placeholder={placeholder || "eg. Java"}
      />

      {errorMessage && <p className={styles.errorMessage} style={{ height: '8px' }}>&nbsp;{errorMessage}</p>}

      <div className={styles.suggCards}>
        {initialSelectedSkills.map((item) => (
          item.selected && (
            <div
              key={item.value}
              className={`${styles.suggCard} ${item.selected ? styles.suggformCardActive : ""}`}
              onClick={() => removeSkill(item)}
            >
              {item.label} &nbsp; <CloseIcon />
            </div>
          )
        ))}
      </div>


    </div>
  );
};

export const CustomSkillsSelectWrapper = ({
  options,
  onChange,
  title,
  subTitle,
  errorMessage,
  placeholder,
  theme,
  suggSelectedSkills,
  initialSelectedSkills,
  setInitialSelectedSkills,
  allowNewOptions, // This will be ignored in the current setup but kept for future use if needed
}) => {
  const selectRef = useRef(null); // Reference to the select element

  useEffect(() => {
    // Initialize Choices on the select element
    const choices = new Choices(selectRef.current, {
      removeItemButton: true, // Enables the remove item button for each selected item
      maxItemCount: -1, // No limit on the number of items that can be selected. Set to a positive integer to limit.
      placeholderValue: placeholder, // Set the placeholder value if needed
      duplicateItemsAllowed: false, // Adjust based on your requirements
      items: initialSelectedSkills.map(skill => skill.value), // Pre-populate with initial selected skills if applicable
    });

    // Event listener for when the selection changes
    choices.passedElement.element.addEventListener('change', (event) => {
      onChange(event); // Call the provided onChange handler
      // Update initialSelectedSkills based on the new selection if necessary
    }, false);

    // Cleanup function to destroy Choices instance when component unmounts
    return () => {
      choices.destroy();
    };
  }, [onChange, placeholder, initialSelectedSkills]);

  return (
    <div className={`${styles.row} ${styles.column}`}>
      {title && <div className={styles.title}>{title}</div>}
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      <select ref={selectRef} className={theme} multiple>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

      {suggSelectedSkills &&
        <div className={styles.suggCards}>
          {suggSelectedSkills.map((item) => (
            item.selected && (
              <div
                key={item.value}
                className={`${styles.suggCard} ${item.selected ? styles.suggformCardActive : ""}`}
                onClick={() => removeSkill(item)}
              >
                {item.label}
              </div>
            )
          ))}
        </div>
      }

    </div>
  );
};

const ErrorIcon = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4347_1822)">
      <path d="M7.66667 4.08337C7.98883 4.08337 8.25 4.34454 8.25 4.66671V7.00004C8.25 7.32221 7.98883 7.58337 7.66667 7.58337C7.3445 7.58337 7.08333 7.32221 7.08333 7.00004V4.66671C7.08333 4.34454 7.3445 4.08337 7.66667 4.08337Z" fill="#F65466" />
      <path d="M7.66667 8.75004C7.3445 8.75004 7.08333 9.01121 7.08333 9.33337C7.08333 9.65554 7.3445 9.91671 7.66667 9.91671H7.6725C7.99467 9.91671 8.25583 9.65554 8.25583 9.33337C8.25583 9.01121 7.99467 8.75004 7.6725 8.75004H7.66667Z" fill="#F65466" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.83919 0.754228C4.94858 0.644832 5.09696 0.583374 5.25167 0.583374H10.0817C10.2364 0.583374 10.3847 0.644832 10.4941 0.754228L13.9125 4.17256C14.0219 4.28196 14.0833 4.43033 14.0833 4.58504V9.41504C14.0833 9.56975 14.0219 9.71812 13.9125 9.82752L10.4941 13.2459C10.3847 13.3553 10.2364 13.4167 10.0817 13.4167H5.25167C5.09696 13.4167 4.94858 13.3553 4.83919 13.2459L1.42085 9.82752C1.31146 9.71812 1.25 9.56975 1.25 9.41504V4.58504C1.25 4.43033 1.31146 4.28196 1.42085 4.17256L4.83919 0.754228ZM5.49329 1.75004L2.41667 4.82667V9.17342L5.49329 12.25H9.84004L12.9167 9.17342V4.82667L9.84004 1.75004H5.49329Z" fill="#F65466" />
    </g>
    <defs>
      <clipPath id="clip0_4347_1822">
        <rect width="14" height="14" fill="white" transform="translate(0.666016)" />
      </clipPath>
    </defs>
  </svg>

)

const CloseIcon = () => (
  <svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3L3 9M3 3L9 9" stroke="#297BCA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)