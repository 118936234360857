import React, { useEffect, useRef, useState } from "react";

import gif from "@/assets/svg/wave-audio.svg"
import styles from "./VideoPreview.module.scss";

export default function VideoPreview({
  localStream,
  isCameraEnabled,
  userIsSpeaking,
  isSessionStart,
  saveUserImage,
  saveUserVideo,
  userName,
  videoRef
}) {
  const [intervalId, setIntervalId] = useState(null);
  const [time, setTime] = useState(0);

  const formatTime = () => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    return [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0'),
    ].join(':');
  };

  useEffect(() => {
    if (isSessionStart) {
      const id = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [isSessionStart]);

      
  const capturePhoto = async () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const photoData = canvas.toDataURL("image/jpeg");
    const blob = await (await fetch(photoData)).blob();
    saveUserImage(blob);
  };

  const captureVideoFootage = (mediaStream) => {
    if (mediaStream) {
      const mediaRecorder = new MediaRecorder(mediaStream, {
        mimeType: "video/webm" // mp4 is not supported by MediaRecorder
      });
      const videoChunks = [];
      mediaRecorder.ondataavailable = (event) => {
        videoChunks.push(event.data);
      };
  
      mediaRecorder.start(1000);
      setTimeout(() => {
        mediaRecorder.stop();
        const videoBlob = new Blob(videoChunks, { type: "video/webm" });
        saveUserVideo(videoBlob);
      }, 1000 * 20);
    }
  };

  const interval = useRef(null);
  const interval2 = useRef(null);

  useEffect(() => {
    if(isSessionStart) {
      videoRef.current.addEventListener("playing", () => {
        if (isSessionStart) {
          capturePhoto();
          interval.current = setInterval(() => {
            capturePhoto();
          }, 60 * 1000 * 2);
          captureVideoFootage(videoRef.current.srcObject);
          interval2.current = setInterval(() => {
            captureVideoFootage(videoRef.current.srcObject);
          }, 60 * 1000 * 4);
        }
      });
    }
  }, [isSessionStart]);

  useEffect(() => {
    if (videoRef.current) {
      if (localStream && isCameraEnabled) {
        videoRef.current.srcObject = localStream;
      } else {
        videoRef.current.srcObject = null;
      }
    }
  }, [localStream, isCameraEnabled]);  

  return (
    <div
      className={`${styles.previewContainer} ${
        userIsSpeaking ? styles.glowBorder : ""
      }`}
    > 
      <div className={styles.recContainer}>
        <RecIcon />
        <div className={styles.recText}>
          {isSessionStart !== true ? 'This interview will be recorded for internal review' : `REC ${formatTime()}`}
        </div>
      </div>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className={styles.previewVideo}
      />
      <div className={styles.userName}>{userName} {userIsSpeaking && <img src={gif} alt='' className={styles.gif}/>}</div>
    </div>
  );
}

const RecIcon = () => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.5" cy="7" r="4.5" fill="#D9D9D9" stroke="#F2001A" strokeWidth="4" />
  </svg>
)